import Vue from "vue";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";

export default Vue.extend({
  mixins: [filtersMixin, adminDatesHandlerMixin],
  render(h) {
    return (
      <div style="padding: 4px; width: 200px;">
        {this.params.data.utc_last_communicated_at_status ? (
          <el-tag size="mini" type="success" effect="dark">
            {this.ftm__formatEmpty(
              this.adm__filterToFormatDate(
                this.params.data.utc_last_communicated_at
              )
            )}
          </el-tag>
        ) : (
          // <template>
          this.ftm__formatEmpty(
            this.adm__filterToFormatDate(
              this.params.data.utc_last_communicated_at
            )
          )
          // </template>
        )}
      </div>
    );
  }
});
