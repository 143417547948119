<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgDetails.vue
Description:this file contains the  singleDeviceSettingsTable,generateForm that generates schema with default values
-->
<template>
  <el-container direction="verical">
    <!-- <el-header>
      <el-container>
        <el-table :data="[user]" size="mini" :show-header="false">
          <el-table-column>
            <template slot-scope="scope">{{`${scope.row.first_name} ${scope.row.last_name}`}}</template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">{{`${scope.row.phone}`}}</template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">{{`${(scope.row.country||{name:''}).name}`}}</template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <er-button size="mini">Make Active</er-button>
            </template>
          </el-table-column>
        </el-table>
      </el-container>
    </el-header>-->
    <el-main>
      <el-container>
        <el-card shadow="never">
          <template slot="header">
            <h4>PondGuard Settings</h4>
          </template>
          <generate-form
            v-loading="saveSettingsLoading"
            :elFormOptions="elFormOptions"
            :schema="pgFields"
            :model="modelData"
            ref="generatedForm"
            :disabled="isDisabled"
            @model-change="handleModelChange"
          >
            <template slot="footer">
              <er-button
                btnType="save"
                :showLabel="true"
                :showIcon="true"
                size="mini"
                :disabled="isDisabled"
                @click="saveSettingsInDb"
              ></er-button>
            </template>
          </generate-form>
        </el-card>
      </el-container>
      <el-container>
        <el-card shadow="never">
          <template slot="header">
            <h4>Latest Sensor Settings</h4>
          </template>
          <el-row>
            <single-device-settings-table
              :key="device._id"
              :settings-details="pondGuard.sensor_values || {}"
              :keys="pondGuardSensorKeys"
            ></single-device-settings-table>
          </el-row>
        </el-card>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import PondGuard from "@/model/pondguard";
import User from "@/model/user";
import singleDeviceSettingsTable from "@/components/superadmin/shared/singleDeviceSettingsTable";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import generateForm from "@/components/superadmin/shared/generateForm";
export default {
  props: {
    userDetails: {
      default: () => ({})
    },
    deviceDetails: { default: () => ({}) },
    pondGuard: { default: () => ({}) },
    isDisabled: { default: true }
  },
  mixins: [errorHandlerMixin],
  components: {
    singleDeviceSettingsTable,
    generateForm
  },
  data: function() {
    return {
      modelData: {},
      saveSettingsLoading: false
    };
  },
  watch: {
    deviceDetails: function(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue) {
      this.initComponent();
      }
    }
  },
  computed: {
    elFormOptions: function() {
      return {
        disabled: false,
        hideRequiredAsterisk: true,
        labelPosition: "top",
        layout: "grid"
      };
    },
    device: function() {
      return this.deviceDetails || new PondGuard();
    },
    user: function() {
      if (this.userDetails) {
        return this.userDetails;
      } else {
        return new User();
      }
    },
    pgFields: function() {
      return [
        {
          type: "input_number",
          inputType: "number",
          label: "Data Interval (secs)",
          model: "data_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Sampling Interval (secs)",
          model: "sampling_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "DO Calibration Value",
          model: "do_calibration_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph4 Calibration Value",
          model: "ph4_calibration_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph7 Calibration Value",
          model: "ph7_calibration_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph9 Calibration Value",
          model: "ph9_calibration_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph4 Temp Value (\u00b0C)",
          model: "ph4_temperature_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Diagnose Interval (mins)",
          model: "diagnose_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Data Reset Interval (secs)",
          model: "data_reset_interval",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "ph Sensor Control",
          model: "ph_sensor_control",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph Slope",
          model: "ph_slope",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Tempature Sensor Control",
          model: "temperature_sensor_control",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Do Sensor Control",
          model: "do_sensor_control",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph7 Temp Value (\u00b0C)",
          model: "ph7_temperature_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Self Cleaning Threshold Voltage",
          model: "self_cleaning_threshold_voltage",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph9 Temp Value (\u00b0C)",
          model: "ph9_temperature_value",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Temperature Correction Factor",
          model: "temperature_correction_factor",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "DO Correction Factor",
          model: "do_correction_factor",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph Correction Factor",
          model: "ph_correction_factor",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "LCD Sleep Mode",
          model: "lcd_sleep_mode",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "LCD Sleep Interval (secs)",
          model: "lcd_sleep_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "ph ON Interval (secs)",
          model: "ph_on_interval",
          validationRules: "required"
        },

        {
          type: "input_number",
          inputType: "number",
          label: "Self Cleaning Interval (secs)",
          model: "self_cleaning_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Self Cleaning ON Time (secs)",
          model: "self_clean_on_time",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Salinity (ppt)",
          props: { precision: 0, min: 1, disabled: this.modelData.salinity_sensor_enable },
          model: "salinity",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Pressure (kpa)",
          model: "pressure",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Pond Depth (mm)",
          model: "pond_depth",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Distance from water surface (ft)",
          model: "distance_from_water_surface",
          validationRules: "required"
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Software Version",
          model: "software_version",
          validationRules: ""
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Hardware Version",
          model: "hardware_version",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Low Do",
          model: "lower_limit",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Critical Do",
          model: "critical_lower_limit",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Minimum Temperature",
          model: "min_temperature",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Maximum Temperature",
          model: "max_temperature",
          validationRules: "required"
        },
        {
          type: "input",
          inputType: "text",
          label: "Version",
          model: "version",
          validationRules: ""
        },
        {
          type: "input",
          inputType: "text",
          label: "Mechanical Version",
          model: "mech_version",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 5, max: 60 },
          label: "Cleaning On Time (secs)",
          model: "cleaning_on_time",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0, max: 3000 },
          label: "Height From Ground (mm)",
          model: "device_height_from_ground",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0, max: 2000 },
          label: "Height From Water (mm)",
          model: "device_height_from_water",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 2 },
          label: "Auto Calibration Interval (days)",
          model: "auto_calib_interval_days",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 1, max: 24 },
          label: "Auto Cleans per day",
          model: "auto_cleans_per_day",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          props: { "true-label": 1, "false-label": 0 },
          label: "Auto Cleaning Enable",
          model: "auto_cleaning_enable",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          props: { "true-label": 1, "false-label": 0 },
          label: "Auto Calibration Enable",
          model: "auto_calibration_enable",
          validationRules: ""
        },
        {
          type: "input",
          props: { type: "textarea" },
          inputType: "text",
          label: "Group Settings",
          model: "group_settings",
          validationRules: { validFormat: { format: "JSON" } }
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Salinity Sensor",
          model: "salinity_sensor_enable",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "DO Saturation",
          model: "do_saturation_sensor_enable",
          validationRules: ""
        },
      ];
    },
    pgSettingsObj: function() {
      return {
        data_interval: 0,
        sampling_interval: 1,
        software_version: "",
        hardware_version: "",
        do_calibration_value: 0,
        ph4_calibration_value: 0.5445,
        ph7_calibration_value: 1.2441,
        ph9_calibration_value: 1.7479,
        ph4_temperature_value: 29.46,
        ph7_temperature_value: 29.31,
        ph9_temperature_value: 29.17,
        diagnose_interval: 21600,
        data_reset_interval: 3600,
        ph_sensor_control: false,
        ph_slope: 59.1,
        temperature_sensor_control: true,
        self_cleaning_threshold_voltage: 3.6,
        critical_do_alert: true,
        cleaning_on_time: 15,
        auto_cleaning_enable: 1,
        auto_cleans_per_day: 1,
        auto_calibration_enable: 1,
        auto_calib_interval_days: 3,
        device_height_from_ground: 1100,
        device_height_from_water: 400,
        low_do_alert: true,
        max_temperature: 30,
        min_temperature: 25,
        lower_limit: 2.5,
        critical_lower_limit: 1.5,
        do_sensor_control: true,
        temperature_correction_factor: "",
        do_correction_factor: 0,
        ph_correction_factor: 0,
        lcd_sleep_mode: true,
        lcd_sleep_interval: 180,
        ph_on_interval: 0,
        self_cleaning_interval: 0,
        self_clean_on_time: 0,
        salinity: 0,
        pressure: 101,
        pond_depth: 0,
        distance_from_water_surface: 0,
        low_ph: 6.5, // new keys
        high_ph: 14,
        version: "",
        country: "",
        mech_version: "M001",
        group_settings: "{}",
        do_saturation_sensor_enable: false,
        salinity_sensor_enable: false,
      };
    },
    pondGuardSensorKeys() {
      return {
        date: "string",
        temperature: "number",
        dissolved_oxygen: "number",
        ph: "number",
        battery_voltage: "number",
        solar_voltage: "number",
        load_current: "number",
        charging_current: "number",
        latitude: "string",
        longitude: "string",
        UTC_date: "string"
      };
    }
  },
  mounted() {
    // this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    initComponent() {
      console.log((this.deviceDetails));
      this.modelData = this.$lodash.cloneDeep({
        ...this.pgSettingsObj,
        ...(this.deviceDetails || { settings: {} }).settings,
        ...(this.deviceDetails || { settings: {} })?.do_alerts_config[0],
        ...{
          min_temperature: (this.deviceDetails || { settings: {} })
            .temperature_alerts_config[0].lower_limit,

          max_temperature: (this.deviceDetails || { settings: {} })
            .temperature_alerts_config[0].upper_limit
        }
      });
      this.$commonUtils.convertFieldValues(
        this.modelData,
        ["diagnose_interval"],
        "secs",
        "mins"
      );
      console.log("this.modelData", this.modelData);
    },
    handleModelChange(value, path) {
      this.modelData[path] = value;
      this.modelData = Object.assign({}, this.modelData);
    },
    async saveSettingsInDb() {
      const response = await this.$refs.generatedForm.$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
      const payload = { ...this.modelData };
      this.$commonUtils.convertFieldValues(
        payload,
        ["diagnose_interval"],
        "mins",
        "secs"
      );
      try {
        this.saveSettingsLoading = true;
        const response = await this.saveSingleDeviceSettingsData({
          device_code: this.deviceDetails.code,
          payload,
          settingsType: "POND_GUARD"
        });
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
        await this.$emit("refresh", this.device._id);
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
        this.ehm__errorMessages(err, true);
      }
      this.saveSettingsLoading = false;
    }
  }
};
</script>

<style lang="scss"></style>
