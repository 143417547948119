<template>
  <er-dialog
    width="35%"
    :visible="dialogVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    @open="handleOpenDialog"
    :title="$t('Comn_add_shrimptalk')"
    custom-class="shrimpTalk_dialog"
    :close-on-click-modal="false"
    @close="handleCloseDialog"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form size="small" :inline="true">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <el-form-item
            label-width="130px"
            :label="formStrings.selectedSTs.label"
            :error="errors[0]"
          >
            <er-select
              class="select-devices"
              :showSelectAll="true"
              v-model="selectedSTs"
              value-key="_id"
              collapse-tags
              multiple
              filterable
              :placeholder="formStrings.selectedSTs.placeholder"
              remote
              :loading="listLoading"
              :remote-method="fetchDevicesToAssign"
            >
              <el-option
                v-for="item in unassignedSTs"
                :key="item._id"
                :label="`${item.code} (ST${item.v1_code})`"
                :value="item"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <el-form-item
            label-width="130px"
            :label="formStrings.selectedSubscription.label"
            :error="errors[0]"
          >
            <er-select
              class="select-subscription"
              :showSelectAll="false"
              v-model="selectedSubscription"
              value-key="_id"
              collapse-tags
              filterable
              :filter-method="filterSubscriptions"
              :placeholder="formStrings.selectedSubscription.placeholder"
              :loading="listLoading"
            >
              <el-option
                v-for="item in subscriptions"
                :key="item._id"
                :label="`${item.title}`"
                :value="item"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
      </el-form>
    </ValidationObserver>

    <template slot="footer">
      <er-button
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitUserDetails"
      ></er-button>
      <er-button
        btnType="cancel"
        :showLabel="true"
        :loading="loading"
        @click="handleCloseDialog"
      ></er-button>
    </template>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [errorHandlerMixin, filtersMixin],
  props: ["dialogVisible"],
  data: function () {
    return {
      loading: false,
      unassignedSTs: [],
      subscriptions: [],
      selectedSubscription: [],
      selectedSTs: [],
      listLoading: false,
      stSearchQuery: {},
      userSearchQuery: {}
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerAssignSts: "getDealerAssignSts",
      getDealerUsers: "getDealerUsers",
      getDealerUnassignSts: "getDealerUnassignSts",
      getDealerSubscriptions: "getDealerSubscriptions"
    }),
    formStrings() {
      return {
        selectedSubscription: {
          label: this.capitalize(this.$t("dealer.select_subscriptions")),
          placeholder: this.$t("Comn_select")
        },
        selectedSTs: {
          label: this.$t("select_shrimp_talks"),
          placeholder: this.$t("Comn_select")
        }
      };
    }
  },
  async mounted() {
    await this.handleOpenDialog();
  },
  methods: {
    ...mapActions("dealer", {
      fetchAllUnassignedDealerDevices: "fetchAllUnassignedDealerDevices",
      fetchDealerRelatedUsers: "fetchDealerRelatedUsers",
      fetchAllSubscriptions: "fetchAllSubscriptions"
    }),
    filterSubscriptions(searchString) {
      return this.subscriptions.filter((x) => x.title.includes(searchString));
    },
    async fetchDevicesToAssign(queryString) {
      try {
        if (queryString === "") return;
        if (queryString && queryString.toUpperCase().startsWith("ST")) {
          this.stSearchQuery.device_code = queryString.substr(2).trim();
        }
        this.listLoading = true;
        this.stSearchQuery.device_code = queryString;
        await this.fetchAllUnassignedDealerDevices({
          infinite_scroll: false,
          device_type: "shrimp_talk",
          params: this.stSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.listLoading = false;
        this.unassignedSTs = this.getDealerUnassignSts;
      }
    },
    async handleOpenDialog(event) {
      this.unassignedSTs = [];
      this.subscriptions = [];
      this.initValidationStrings();
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
      await this.fetchSubscriptions();
      this.selectedSubscription = this.getDealerSubscriptions[0];
      this.selectedSTs = [];
    },
    initValidationStrings() {
      extend("required", {
        ...required,
        message: this.$t("error_msg")
      });
    },
    async submitUserDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      this.loading = true;
      const payload = {
        user_id: this.selectedSubscription._id,
        shrimp_talk_ids: this.selectedSTs.map((x) => x._id)
      };
      try {
        await this.addSubscriptionToDevices(payload);
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
        this.$emit("refresh", true);
      }
    },
    async fetchSubscriptions() {
      try {
        this.listLoading = true;
        await this.fetchAllSubscriptions({
          query: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.listLoading = false;
        this.subscriptions = this.getDealerSubscriptions;
      }
    },
    handleCloseDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style lang="scss" scoped>
.shrimpTalk_dialog {
  border-radius: 10px;
}
</style>
