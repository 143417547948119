<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgOxygenGraph
Description:This file contains the pondguard oxygen graphs built using highcharts
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Salinity(ppt)"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { salinityGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: salinityGraphConfig,
      oxygenData: []
    };
  },
  computed: {
    getLocaleObj: function () {
      return { locale: this.$i18n.locale };
    }
  },

  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgSalinityValues");
    this.acm__initParameterName("salinity");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Salinity", "line");
    },
  }
};
</script>

<style lang="scss">
// .highcharts-container {
//   width: 1008px !important;
//   height: 400px !important;
// }
.vue-grid-item {
   width: 98% !important;
}
</style>
