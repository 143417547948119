<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayDetails.vue
Description:This file contains the schema with the form with default values
-->
<template>
  <div class="gateway-details">
    <el-scrollbar
      class="gateway-device-networks-scroll"
      viewClass="gateway-device-networks-scroll-view"
      wrapClass="gateway-device-networks-scroll-wrap"
    >
      <el-card shadow="never">
        <template slot="header">
          <h3>Gateway Settings</h3>
        </template>
        <el-row>
          <el-col :span="12">
            <generate-form
              v-loading="saveSettingsLoading"
              element-loading-background="white"
              :elFormOptions="elFormOptions"
              :schema="gatewayFields"
              :model="modelData"
              ref="generatedForm"
              :disabled="isDisabled"
              @model-change="handleModelChange"
            >
              <template slot="footer">
                <er-button
                  btnType="save"
                  :showLabel="true"
                  :showIcon="true"
                  :disabled="isDisabled"
                  @click="saveSettingsInDb"
                ></er-button>
              </template>
            </generate-form>
          </el-col>
          <el-col :span="12">
            <small-table
              :tableData="wifi_table_data"
              search-on-property="wifi_network"
              :columns="wifi_table_columns"
              :addPadingToSearch="true"
              tableName="Available Networks"
            ></small-table>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="never">
        <template slot="header">
          <h3>Latest Sensor Settings</h3>
        </template>
        <el-row>
          <el-col>
            <single-device-settings-table
              :key="gateway._id"
              :settings-details="gateway.sensor_values || {}"
              :keys="gatewaySensorKeys"
            ></single-device-settings-table>
          </el-col>
        </el-row>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
// import deviceCustomerDetailsTable from "./deviceCustomerDetailsTable";
import singleDeviceSettingsTable from "@/components/superadmin/shared/singleDeviceSettingsTable";
import Gateway from "@/model/gateway";
import smallTable from "@/components/superadmin/shared/smallTable";
import generateForm from "@/components/superadmin/shared/generateForm";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    customerDetails: {
      default: () => ({})
    },
    deviceDetails: { default: () => ({}) },
    isDisabled: { default: true }
    },
  mixins: [errorHandlerMixin],
  components: {
    // deviceCustomerDetailsTable,
    singleDeviceSettingsTable,
    smallTable,
    generateForm
  },
  data: function () {
    return {
      modelData: {},
      saveSettingsLoading: false
    };
  },
  watch: {
    deviceDetails: function (newValue, oldValue) {
      this.initComponent();
    }
  },
  computed: {
    ...mapGetters("auth", {
      getArrCountries: "getArrCountries"
    }),
    elFormOptions: function () {
      return {
        disabled: false,
        hideRequiredAsterisk: true,
        labelPosition: "top",
        layout: "grid"
        // inline: true
      };
    },
    gateway: function () {
      return this.deviceDetails || new Gateway();
    },
    gatewayFields: function () {
      return [
        {
          type: "input_number",
          inputType: "number",
          label: "Settings Interval (mins)",
          props: { disabled: false, min: 1 },
          model: "settings_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Device Stats Interval (mins)",
          props: { disabled: false, min: 1 },
          model: "device_stats_interval",
          validationRules: "required"
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Lora Channel",
          model: "lora_channel",
          validationRules: "required"
        },
        // {
        //   type: "input",
        //   inputType: "text",
        //   label: "Lora Channel",
        //   model: "lora_channel",
        //   validationRules: "required"
        // },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Latitude",
          model: "latitude",
          validationRules: ""
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Longitude",
          model: "longitude",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Reboot",
          model: "reboot",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Restart Service",
          model: "restart_service",
          validationRules: "required"
        },
        {
          type: "select",
          props: {
            options: this.getArrCountries,
            valueKey: "code",
            labelKey: "name",
            valueType: "object",
            filterable: true,
            disabled: true
          },
          inputType: "text",
          label: "Country",
          model: "country"
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "MAC Address",
          model: "mac_address",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "OS Version",
          model: "os_version",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Software Version",
          model: "sw_version",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Hardware Version",
          model: "hw_version",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "IP Interface",
          model: "ip_interface",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Mac Id",
          model: "mac_id",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "IP Address",
          model: "ip_address",
          validationRules: ""
        },
        {
          type: "input",
          props: { type: "textarea" },
          inputType: "text",
          label: "Group Settings",
          model: "group_settings",
          validationRules: { validFormat: { format: "JSON" } }
        }
      ];
    },
    initGateway() {
      return {
        settings_interval: 15 * 60,
        lora_channel: "916000000",
        device_stats_interval: 15 * 60,
        country: "unknown",
        mac_address: "",
        os_version: "",
        software_version: "",
        hardware_version: "",
        ip_interface: "",
        ip_address: "0.0.0.0",
        latitude: "0",
        longitude: "0",
        mac_id: "",
        group_settings: "{}"
      };
    },
    gatewaySensorKeys() {
      return {
        date: "string",
        battery_voltage: "number",
        wifi_signal_RSSI: "number",
        wifi_connection: "string",
        wifi_connection_BSSID: "string",
        utc_date: "string"
      };
    },
    getUserId() {
      return this.customerDetails._id;
    },
    wifi_table_data: function () {
      const sensorDetails = this.deviceDetails.sensor_values || {};
      const settings = this.deviceDetails.settings || {};

      const wifi_networks = settings.wifi_networks || [];
      const wifi_password = settings.wifi_passwords;
      const wifi_security = settings.wifi_security;
      const wifi_signal = Array.isArray(sensorDetails.wifi_signal_RSSI)
        ? sensorDetails.wifi_signal_RSSI
        : sensorDetails.wifi_signal_RSSI
        ? [sensorDetails.wifi_signal_RSSI]
        : [];

      const tableData = [];
      for (let i = 0; i < wifi_networks.length; i++) {
        tableData.push({
          wifi_network: wifi_networks[i],
          wifi_password: wifi_password[i],
          wifi_security: wifi_security[i],
          wifi_signal: wifi_signal[i] || "--"
        });
      }

      return tableData;
    },
    wifi_table_columns: function () {
      return [
        {
          prop: "wifi_network",
          label: "Wifi Network",
          required: true
        },
        {
          prop: "wifi_password",
          label: "Wifi Password",
          required: true
        },
        {
          prop: "wifi_security",
          label: "Wifi Security",
          required: true
        },
        {
          prop: "wifi_signal",
          label: "Wifi Signal",
          required: true
        }
      ];
    },
    fullName() {
      return function (user) {
        return `${user.first_name} ${user.last_name}`;
      };
    }
  },
  mounted() {
    this.initComponent();
  },
  created() {
    console.log(this.customerDetails);
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    initComponent() {
      this.modelData = this.$lodash.cloneDeep({
        ...this.initGateway,
        ...(this.gateway || { settings: {} }).settings
      });
      this.$commonUtils.convertFieldValues(
        this.modelData,
        ["settings_interval", "device_stats_interval"],
        "secs",
        "mins"
      );
    },
    handleModelChange(value, path) {
      this.modelData[path] = value;
      this.modelData = Object.assign({}, this.modelData);
    },
    async saveSettingsInDb() {
      const response =
        await this.$refs.generatedForm.$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
      const payload = { ...this.modelData };
      this.$commonUtils.convertFieldValues(
        payload,
        ["settings_interval", "device_stats_interval"],
        "mins",
        "secs"
      );
      try {
        this.saveSettingsLoading = true;
        const response = await this.saveSingleDeviceSettingsData({
          device_code: this.gateway.code,
          payload,
          settingsType: "GATEWAY"
        });
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
        await this.$emit("refresh", this.gateway._id);
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
        this.ehm__errorMessages(err, true);
      }
      this.saveSettingsLoading = false;
    }
  }
};
</script>

<style lang="scss">
.gateway-details {
  div.cell {
    text-transform: capitalize;
  }
  .table--600 {
    width: 600px;
  }

  .multiple-customer-details-small {
    .el-table__body-wrapper .el-table_1_column_2 div.cell {
      text-transform: lowercase !important;
    }
  }

  .sc-table,
  .table--600 {
    .el-table__header-wrapper th {
      color: #333;
      font-weight: 500;
    }
  }
}
</style>
