/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: adminChartsMixin.js
Description: This Mixin is useful for managing the common functionality related to the charts such as init x, y axis, data useful for plotting chart, date picker related functionality in superadmin site
*/
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";

const adminChartsMixin = {
  props: ["timeZoneStr", "deviceDetails"],
  mixins: [adminDatesHandlerMixin],
  data: function() {
    return {
      acm__updateChart: 0,
      acm__storeDataGetterPath: "",
      acm__parameterName: ""
    };
  },
  watch: {
    acm__getStoreData: function(newData, oldData) {
      this.acm__updateChart++;
      this.acm__initComponent();
    }
  },
  computed: {
    acm__getStoreData() {
      if (this.acm__storeDataGetterPath) {
        return this.$store.getters[this.acm__storeDataGetterPath];
      }
      return [];
    }
  },
  methods: {
    acm__initChartSeries(seriesName, chartType) {
      this.chartOptions.series = [
        {
          name: seriesName,
          data: this.acm__getStoreData,
          type: chartType,
          visible: true
        }
      ];
    },
    acm__handleTimeQueryChange(changedTimeValue) {
      const today = this.adm__getTodayInUserTZ(this.timeZoneStr);
      const to_date = this.adm__dateUtilsLib.formatDate(
        today,
        this.adm__dateUtilsLib.isoFormatString
      );
      const from_date = this.adm__dateUtilsLib.formatDate(
        this.adm__dateUtilsLib.subtract(today, {
          [changedTimeValue.units]: changedTimeValue.num
        }),
        this.adm__dateUtilsLib.isoFormatString
      );
      this.$emit(
        "changedDateQuery",
        { to_date, from_date },
        this.acm__parameterName
      );
    },
    acm__handleDateQueryChange(changedDateValue) {
      const from_date = this.adm__dateUtilsLib.formatUTCISOToUserUTCISO(
        new Date(changedDateValue[0]),
        "UTC"
      );
      const to_date = this.adm__dateUtilsLib.formatUTCISOToUserUTCISO(
        new Date(changedDateValue[1]),
        "UTC"
      );
      this.$emit(
        "changedDateQuery",
        { to_date, from_date },
        this.acm__parameterName
      );
    },
    acm__handleDownloadQuery(changedDateValue) {
      const from_date = changedDateValue[0];
      const to_date = changedDateValue[1];
      this.$emit("downloadZIPQuery", { to_date, from_date });
    },
    acm__initParameterName(acm__parameterName) {
      this.acm__parameterName = acm__parameterName;
    },
    acm__initStoreDataGetterPath(path) {
      this.acm__storeDataGetterPath = path;
    },
    trim_nameSpace(name) {
      return name
        .split(/\s+/)
        .join(" ")
        .trim();
    }
  }
};

export default adminChartsMixin;
