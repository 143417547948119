<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dialogForm.vue
Description:This file contains the form where details of the devices will be filled and saved by the admin
-->
<template>
  <er-dialog
    width="30%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :title="title"
    class="dialog-form"
    custom-class="dialog-form-modal"
    element-loading-background="white"
    @beforeClose="handleCloseDialog"
    @open="initComponent"
  >
    <slot class="body-title"></slot>
    <generateForm
      :schema="schema"
      :model="modelData"
      ref="generatedForm"
      @model-change="handleModelChange"
    ></generateForm>
    <ValidationObserver ref="dialogListForm">
      <el-form :inline="false">
        <ul class="list-item-names">
          <ValidationProvider
            v-for="i in arrListNames.length"
            :key="i"
            :name="`v1_code_${i}`"
            :rules="rules(i, 'v1_code_')"
            v-slot="{ errors }"
            tag="li"
          >
            <el-form-item
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
              :error="errors[0] || backendValidations[i - 1]"
              :label="`Device Code ${i}`"
            >
              <div class="input-container">
                <el-input
                  class="device_input"
                  type="text"
                  size="mini"
                  @focus="clearBackendError"
                  v-model="arrListNames[i - 1]"
                ></el-input>
              </div>
              <div class="button-container">
                <er-button
                  v-if="i === arrListNames.length"
                  btnType="add"
                  size="mini"
                  :showIcon="true"
                  @click="handleAddNewListItem"
                ></er-button>
                <er-button
                  v-else
                  btnType="delete"
                  :showIcon="true"
                  size="mini"
                  @click="handleDeleteNewListItem(i - 1)"
                ></er-button>
              </div>
            </el-form-item>
          </ValidationProvider>
        </ul>
      </el-form>
    </ValidationObserver>
    <div v-if="shouldErrorHighlightShow" class="errorwithouthighlight">
      <!-- v-bind:class="{ errorCss: errorhighlight }" -->
      <div class="test-devices-range">
        <span class="material-icons-outlined"> info </span>
        <span>{{ $t("Test_devices") }}: {{ getDeviceIdRange[0] }}</span>
      </div>
      <div class="production-devices-range">
        <span class="material-icons-outlined"> info </span>
        <span>{{ $t("Production_devices") }}: {{ getDeviceIdRange[1] }}</span>
      </div>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="end" align="middle">
        <er-button
          btnType="save"
          size="mini"
          :loading="loading"
          :showLabel="true"
          @click="handleSave"
        ></er-button>
      </el-row>
    </template>
  </er-dialog>
</template>

<script>
import generateForm from "@/components/superadmin/shared/generateForm";
import { extend, validate } from "vee-validate";
import { min, max, numeric, is_not, required } from "vee-validate/dist/rules";

let deviceTypeProp = "";
function checkDeviceIdRange(deviceCode, deviceType) {
  let deviceCodeInRage = false;
  switch (deviceType) {
    case "POND_GUARD":
      deviceCodeInRage = !!(
        (deviceCode >= 0 && deviceCode <= 10000) ||
        (deviceCode >= 50001 && deviceCode <= 60000) ||
        (deviceCode >= 220001 && deviceCode <= 270000)
      );
      break;
    case "POND_MOTHER":
      deviceCodeInRage = !!(
        (deviceCode >= 0 && deviceCode <= 50000) ||
        (deviceCode >= 120001 && deviceCode <= 220000)
      );
      break;
    case "GATEWAY":
      deviceCodeInRage = !!(
        (deviceCode >= 0 && deviceCode <= 10000) ||
        (deviceCode >= 70001 && deviceCode <= 80000) ||
        (deviceCode >= 310001 && deviceCode <= 330000)
      );
      break;
    case "SHRIMP_TALK":
      deviceCodeInRage = !!(
        (deviceCode >= 0 && deviceCode <= 10000) ||
        (deviceCode >= 80001 && deviceCode <= 100000) ||
        (deviceCode >= 330001 && deviceCode <= 380000)
      );
      break;
    case "SHRIMP_SNAP":
      deviceCodeInRage = !!(deviceCode >= 100001 && deviceCode <= 120000);
      break;
  }
  return deviceCodeInRage;
}

extend("min", min);
extend("max", max);
extend("required", required);
extend("numeric", numeric);

extend("digits_between", {
  async validate(value, { min, max }) {
    const res = await validate(value, `numeric|min:${min}|max:${max}`);
    if (res.valid && checkDeviceIdRange(value, deviceTypeProp)) {
      return true;
    }
    return false;
  },
  params: ["min", "max"],
  message: "Invalid Device ID Range"
});
// The {_field_} must be between {min} and {max} digits
extend("max_length", {
  params: ["length"],
  validate: (value, { length }) => {
    return ("" + value).length > length;
  },
  message: "{_field_} should be {length} characters"
});

extend("min_length", {
  params: ["length"],
  validate: (value, { length }) => {
    return ("" + value).length < length;
  },
  message: "{_field_} should be minimum {length} characters"
});
extend("no_match", {
  validate: (value, arrayOfValues) => {
    return !arrayOfValues.includes(value);
  },
  message: "This V1 Code Already Taken",
  hasTarget: true
});
extend("is_not", {
  ...is_not,
  message: "V1 Code Should Be a Valid Value"
});
// extend("required", {
//   message: "Invalid Device ID Range"
// });

export default {
  components: { generateForm },
  props: {
    schema: {
      default: () => ({})
    },
    model: {
      default: () => ({})
    },
    showDialog: {
      default: false
    },
    loading: {
      default: false
    },
    title: {
      default: "Add"
    },
    backendValidations: {
      default: () => ({})
    },
    v1CodeLength: {
      type: Number,
      default: 6
    },
    v1CodeMinLength: {
      type: Number,
      default: 5
    },
    deviceType: {
      type: String,
      default: ""
    },
    shouldErrorHighlightShow: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      modelData: {},
      defaultCode: "0".repeat(this.v1CodeLength),
      arrListNames: []
    };
  },
  computed: {
    buttondisable: function() {
      if (this.arrListNames.filter((x) => x === this.defaultCode).length) {
        return true;
      }
      return false;
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog(value);
      }
    },
    getDeviceIdRange() {
      const arrayRange = {
        POND_MOTHER: ["1 - 10000", "10001 - 50000, 120001 - 220000"],
        POND_GUARD: ["1 - 10000", "50001 - 60000, 220001 - 270000"],
        GATEWAY: ["1 - 10000", "70001 - 80000, 310001 - 330000"],
        SHRIMP_TALK: ["1 - 10000", "80001 - 100000, 330001 - 380000"],
        SHRIMP_SNAP: ["1 - 10000", "100001 - 120000"]
      };
      return arrayRange[this.deviceType];
    },
    rules: function() {
      const defaultRules = {
        required: true,
        digits_between: {
          min: 5,
          max: 6
        },
        is_not: this.defaultCode
      };
      return (i, fieldName) => {
        if (this.arrListNames.length > 0) {
          const noMatch = this.getValidationFields(
            i,
            this.arrListNames.length,
            fieldName
          );
          defaultRules.no_match = noMatch;
        }

        return defaultRules;
      };
    }
  },
  created() {
    deviceTypeProp = this.deviceType;
  },
  methods: {
    initComponent() {
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
        this.$refs.generatedForm.$refs.generatedFormValidator.reset();
      });
      this.arrListNames = [this.defaultCode];
      this.modelData = Object.assign(this.modelData, this.model);
    },
    getValidationFields(currNum, length, fieldName) {
      return [...Array(length - 1)].map(
        (x, index) => `@${fieldName + (((index + currNum) % length) + 1)}`
      );
    },
    clearBackendError() {
      if (Object.keys(this.backendValidations).length > 0) {
        this.$emit("clear-validations", "ALL");
      }
    },
    handleCloseDialog(event) {
      this.$emit("close-dialog", event);
    },
    handleValueChange($event, index) {
      this.arrListNames[index] = $event;
    },
    handleModelChange(value, path) {
      this.modelData[path] = value;
      this.modelData = Object.assign({}, this.modelData);
    },
    handleAddNewListItem() {
      this.arrListNames.push(this.defaultCode);
    },
    handleDeleteNewListItem(indexPosition) {
      this.arrListNames.splice(indexPosition, 1);
      this.$emit("clear-validations", "SINGLE", indexPosition);
    },
    async handleSave() {
      let response = await this.$refs.dialogListForm.validate();
      if (!response) {
        return;
      }
      response = await this.$refs.generatedForm.$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      this.$emit("save-details", this.modelData, this.arrListNames);
    }
  }
};
</script>

<style lang="scss">
.dialog-form {
  // .el-form-item__error {
  //   margin-left: 33px;
  // }
  .list-item-names {
    list-style: none;
    .el-form-item__content {
      display: flex;
    }
    // li .el-form-item__content {
    //   line-height: unset;
    //   display: grid;
    //   grid-template-columns: 5fr 1fr;
    //   grid-template-rows: 1fr;
    //   grid-column-gap: 5px;
    //   grid-row-gap: 0px;
    //   .el-input {
    //     grid-area: 1 / 1 / 2 / 2;
    //   }
    //   .el-button.el-button--mini {
    //     padding: 3px 10px;
    //     grid-area: 1 / 2 / 2 / 3;
    //   }
    // }
    .device_input {
      width: 182px !important;
      @include responsiveProperty(width, 127px, 162px, 211px);
      // @include responsiveProperty(margin-left, 36px, 12px, 31px);
    }
  }

  .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    color: #606266;
  }
  .el-form-item {
    margin-bottom: 12px;
  }
  .errorwithouthighlight {
    display: flex;
    // word-break: break-word !important;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    margin-top: 35px;
    // @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
    // .material-icons-outlined {
    //   margin-top: -1px !important;
    //   margin-right: 4px;
    //   word-break: break-word !important;
    // }
    .test-devices-range {
      display: flex;
      word-break: break-word !important;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      margin-left: 6%;
      margin-bottom: 6px;
      @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
      .material-icons-outlined {
        margin-top: -1px !important;
        margin-right: 4px;
        word-break: break-word !important;
      }
    }
    .production-devices-range {
      display: flex;
      word-break: break-word !important;
      // flex-direction: column;
      // align-items: center;
      margin-left: 6%;
      @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
      .material-icons-outlined {
        margin-top: -1px !important;
        margin-right: 4px;
        word-break: break-word !important;
      }
    }
  }
}
.dialog-form-modal {
  border-radius: 10px;
}
</style>
