/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for charts in the ponds page
*/
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { baseChartConfig as baseGraphConfig } from "@/config/baseChartConfig";

const legend = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled
  };
};
const exporting = function(enabled) {
  return {
    enabled: enabled
  };
};
const title = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled,
    text: undefined
  };
};
const tooltip = {
  default: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  hourlyToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
    // xDateFormat: CHART_DATE_FORMATS.dmyt
  }
};

const lithiumBatteryGraphConfig = {
  ...baseGraphConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
export { lithiumBatteryGraphConfig };
