<template>
  <splitpanes class="default-theme layout-split-panes">
    <template v-for="(pane, index) in panes">
      <pane v-bind="pane" :key="index">
        <slot :name="`pane-${pane.id}`"></slot>
      </pane>
    </template>
  </splitpanes>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
export default {
  props: {
    panes: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  components: {
    Splitpanes,
    Pane
  }
};
</script>

<style lang="scss" scoped>
.layout-split-panes {
  &.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
  }
}
</style>
