<template>
  <layout-toolbar>
    <er-list-filter
      :data="userTypes"
      value-key="key"
      search-on-key="label"
      :defaultCheckedKeys="getUAMListArrayIds"
      @check-change="handleChangeSelectedUserType"
      :allow-save="true"
    >
      <template v-slot:label="{ item }">{{ item.label }}</template>
    </er-list-filter>
    <er-input
      inputType="search"
      v-model="userSearch"
      size="mini"
      :debounce="300"
      suffix-icon="el-icon-search"
      class="input-search-pond"
      :placeholder="'Search'"
      :clearable="true"
      @clear="handleClear"
      @input="handleChangeSearch"
    ></er-input>
    <div class="filler"></div>
    <er-button
      btnType="circle_add"
      :showLabel="true"
      :showIcon="true"
      size="mini"
      @click="handleClick('', 'adminUserCreate')"
    >
      <slot>
        <p>
            Add Users
        </p>
      </slot>
    </er-button>
  </layout-toolbar>
</template>

<script>
import { UAM_UI_STATES } from "@/constants/uiStates";
import { ADMIN_USER_TYPES, ADMIN_FILTER_TYPES } from "@/constants/filterTypes";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  data() {
    return {
      userSearch: "",
      UAM_UI_STATES,
    //   debounce: null
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserProfile: "getUserProfile",
      getFilteredSuperUserList: "getFilteredSuperUserList"
    }),
    getUAMListArrayIds() {
      const storedUserFilterOptions = JSON.parse(localStorage.getItem(`SuperUser_userFilterOptions`));
      return storedUserFilterOptions?.length > 0 ? storedUserFilterOptions : this.userTypes.map((type) => type.key);
    },
    userTypes() {
      return ADMIN_USER_TYPES;
    }
  },
  methods: {
    ...mapActions("superadmin", {
      changeFilters: "changeFilters",
      changeAdminUserSidePanelState: "changeAdminUserSidePanelState"
    }),
    async handleChangeSelectedUserType(selectedUserTypes) {
      this.changeFilters({
        filter_type: ADMIN_FILTER_TYPES.USER_TYPE,
        data: selectedUserTypes
      });
    },
    handleChangeSearch() {
      this.$emit("user_search", this.userSearch);
      this.changeFilters({
        filter_type: ADMIN_FILTER_TYPES.SEARCH_STRING
        // data: this.userSearch,
      });
    },
    handleClear() {
      this.changeFilters({
        filter_type: ADMIN_FILTER_TYPES.SEARCH_STRING,
        data: this.userSearch
      });
    },
    handleClick() {
      try {
        // this.$gblUAMIsHighPriorityUsersRestricted();
        this.changeAdminUserSidePanelState({
          ui_state: "Create"
        });
      } catch (err) {
        this.ehm__errorMessages(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
