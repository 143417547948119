<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: settings.vue
Description:This file contains the tab components.userProfile,units,userAccessManagement,pondTab,devices,pondMotherSettings,scheduleSettings,gateway,shrimpTalkSettings,pondGuardSettings,feedBlowerSettings,locationManagement,aquasim
-->
<template>
  <div class="admin-help-section-main-container">
    <div class="header-main-container">
      <div
        class="header-item-container"
        v-show="computedComponentName === 'releaseNotes' && shouldSearchShow"
      >
        <div class="header-item-container__input-box">
          <el-input
            v-show="search"
            clearable
            class="search-feature-input"
            :placeholder="`Search`"
            :debounce="500"
            v-model="searchStr"
            @change="handleChange"
            @blur="handleBlur"
            @clear="handleClear"
          ></el-input>
        </div>
      </div>
      <div class="header-item-container__view">
        <div
          class="header-item-container__input-box"
          v-show="computedComponentName === 'releaseNotes'"
        >
          <el-select
            v-model="selectedViewForNotes"
            placeholder="Select"
            @change="handleChangeViewForNotes"
            class="device-view-dropdown"
          >
            <el-option
              v-for="item in viewContainerForNotes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="header-item-container__input-box"
          v-show="computedComponentName === 'releaseLogs'"
        >
          <el-select
            v-model="selectedViewForLogs"
            placeholder="Select"
            @change="handleChangeViewForLogs"
          >
            <el-option
              v-for="item in viewContainerForLogs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <layout-pondlogs class="help-section-tab-component">
      <template slot="layout-pondlogs-scrollable-main">
        <er-card>
          <er-top-route-nav
            type="tabs"
            :items="computedTabItems"
          ></er-top-route-nav>
          <!-- </simplebar> -->
          <component
            :is="computedComponentName"
            @change-tab="handleChangeTabTo"
            :tabData="componentList[computedComponentName]"
          ></component>
        </er-card>
      </template>
    </layout-pondlogs>
  </div>
</template>

<script>
import releaseNotes from "@/components/superadmin/adminHelpSection/adminReleaseNotes";
import releaseLogs from "@/components/superadmin/adminHelpSection/adminReleaseLogs";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import filtersMixin from "@/mixins/filtersMixin";
import eventBus from "@/components/shared/eventBus";
import productManuals from "@/components/superadmin/adminHelpSection/adminProductManuals";
// import faqs from "@/components/superadmin/adminHelpSection/adminFaqs";
import abbreviations from "@/components/superadmin/adminHelpSection/adminAbbreviations";
const tabComponents = [
  { name: "releaseNotes", label: "Release Notes" },
  { name: "releaseLogs", label: "Release Logs" },
  { name: "productManuals", label: "Product Manuals" },
  // { name: "faqs", label: "FAQ's" },
  { name: "abbreviations", label: "Abbreviations" }
];
export default {
  mixins: [featureToggleMixin, redirectsMixin, filtersMixin],
  components: {
    releaseNotes,
    releaseLogs,
    productManuals,
    // faqs,
    abbreviations
  },
  data: function() {
    return {
      componentName: "releaseNotes",
      componentList: {
        releaseNotes: {
          name: "details",
          label: "Release Notes"
        },
        releaseLogs: {
          name: "logs",
          label: "Release Logs"
        }
      },
      search: true,
      searchStr: "",
      searchStrKey: "feature_title",
      shouldSearchShow: false,
      viewContainerForNotes: [
        {
          value: "web",
          label: "Web"
        },
        {
          value: "android",
          label: "Android"
        },
        {
          value: "ios",
          label: "iOS"
        }
      ],
      viewContainerForLogs: [
        {
          value: "web",
          label: "Web"
        },
        {
          value: "android",
          label: "Android"
        },
        {
          value: "ios",
          label: "iOS"
        }
      ],
      selectedViewForNotes: "web",
      selectedViewForLogs: "web"
    };
  },
  created() {
    if (typeof this.$route.query.tab === "undefined") {
      this.$router.replace({ path: "help", query: { tab: "releaseNotes" } });
    }
  },
  mounted() {
    this.shouldSearchShow = true;
  },
  computed: {
    computedComponentName: {
      get() {
        return this.$route.query.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    },
    computedTabItems: function() {
      return tabComponents;
    }
  },
  watch: {
    computedComponentName(val) {
      if (val === "releaseNotes") {
        this.selectedViewForLogs = "web";
      } else {
        this.selectedViewForNotes = "web";
      }
    }
  },
  methods: {
    handleChangeTabTo: function(tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    },
    handleChangeViewForNotes(newVal) {
      this.selectedView = newVal;
      eventBus.$emit("viewChangedForNotes", {
        selectedView: this.selectedView.toLocaleUpperCase()
      });
    },
    handleChangeViewForLogs(newVal) {
      this.selectedView = newVal;
      eventBus.$emit("viewChangedForLogs", {
        selectedView: this.selectedView.toLocaleUpperCase()
      });
    },
    handleBlur(event) {
      if (this.searchStr === "") {
        eventBus.$emit("cleared", {});
      }
    },
    handleChange(string) {
      this.loading = true;
      try {
        const query = {};
        if (string === "") {
          query.page = 1;
          query.limit = 30;
          if (query[this.searchStrKey]) {
            delete query[this.searchStrKey];
          }
          eventBus.$emit("cleared", { query, infinite_scroll: false });
        } else {
          query[this.searchStrKey] = string?.trim();
        }
        eventBus.$emit("loadData", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleClear() {
      this.loading = true;
      try {
        const query = {};
        query.page = 1;
        query.limit = 30;
        // query.get_all = true;
        eventBus.$emit("cleared", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.admin-help-section-main-container {
  position: relative;
  .header-main-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 18px;
    right: -58px;
  }
  .header-item-container {
    z-index: 10;

    &__input-box {
      width: 300px;

      el-select {
        width: 200px !important;
      }
      .search-feature-input {
        input {
          height: 28px !important;
          line-height: 28px !important;
        }
        .el-input__suffix {
          margin-bottom: 5px;
        }
      }
    }
  }
  .header-item-container__view {
    z-index: 10;
    margin-left: 10px;
    .el-select {
      width: 200px !important;
    }
    &__input-box {
      .el-select {
        width: 200px !important;
      }
      .el-input__inner {
        height: 28px !important;
        line-height: 28px;
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
      }
    }
    .device-view-dropdown {
      .el-select-dropdown__item {
        font-size: 11px;
        // @include responsiveProperty(
        //   font-size,
        //   $app_font_size_small,
        //   $app_font_size_1,
        //   $app_font_size_2
        // );
        display: flex;
        align-items: center;
      }
      .el-select-dropdown {
        min-width: 120px !important;
      }
      min-width: 120px !important;
    }
  }
  .help-section-tab-component {
    $default-font-size: 13px;
    $font-color: #404243;
    .el-menu--horizontal.type-tab {
      display: flex;
      overflow: auto;
      margin-bottom: 0px;
      border-radius: 2px;
      > .el-menu-item {
        height: 35px;
        line-height: 35px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
    }
    .is-disabled {
      .el-input__inner,
      .el-range-input {
        color: $font-color;
      }
    }
    .settings-tab {
      box-sizing: border-box;
      /* height: 100%; */
    }
    .el-input__inner {
      text-align: center;
    }
    .er-tabs {
      .el-tabs__item {
        text-align: center;
        // padding-left: 10px;
        // padding: 0px 0px 0px 0px;
        font-size: 13px;
      }
      .el-tabs__content {
        background-color: #fbfbfb;
        padding: 0px;
      }
    }
    .el-tabs__item {
      padding: 0px 16px;
      height: 40px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 40px;
      display: inline-block;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      color: #303133;
      position: relative;
    }
    .el-card__body {
      padding: 0px;
    }
    .er-card,
    .el-card {
      border-radius: 7px 7px 14px 14px !important;
    }
  }
  .el-input__suffix {
    text-align: center;
  }
  .el-select {
    width: 200px;
  }
  .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
    font-size: 13px;
  }
  .el-input__icon {
    line-height: 28px !important;
  }
}
.el-select-dropdown__item.selected {
  font-weight: 700;
  color: #0a2463 !important;
}
.el-select-dropdown {
  // min-width: 120px !important;
  .el-select-dropdown__item {
    font-size: 13px;
    height: 28px !important;
    line-height: 28px !important;
  }
}
</style>
