<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * fieldrietary and confidential * 2021 -->
<!--
File Name: shrimpTalkTable.vue
Description: This file contains UI components of ShrimpTalk table used in settings page
-->
<template>
  <div class="shrimp-talk-table" v-loading="loading">
    <div class="item-container is-filters-set-relative">
      <div class="table-filters-right">
        <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcel"
        ></er-download-drop-down>
        <er-column-selector
          :columns="computedColumns"
          keyToStoreDefaults="shrimptalk_filters"
          keyToDecideDefaultCol="isVisible"
          :allow-check="true"
          :title="'Column Visibility'"
          @change-selection="handleChangeColumnSelection"
        ></er-column-selector>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridShrimpTalkFilters"
      style="width: 100%; height: 85vh; top: 10; bottom: 0"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :rowData="deviceData"
      :frameworkComponents="frameworkComponents"
      :pagination="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import XLSX from "xlsx";
import { mapActions } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import agGridStringCompareFilter from "@/components/superadmin/shared/agGridStringCompareFilter";
import agGridLastCommCellRenderer from "@/components/superadmin/shared/agGridLastCommCellRenderer";
export default {
  mixins: [adminDatesHandlerMixin, errorHandlerMixin, filtersMixin],
  data() {
    return {
      columnDefs: null,
      rowData: [],
      loading: false,
      overlayLoadingTemplate: null,
      frameworkComponents: null,
      gridApi: undefined,
      gridColumnApi: undefined,
      gridOptions: {},
      stIdString: "ST"
    };
  },
  components: {
    AgGridVue
  },
  computed: {
    computedColumns: function() {
      const columns = this.$lodash.cloneDeep(
        this.columnDefs.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    getFeedingModelVal: function () {
      return (val) => {
        if (val === 0) {
            return 'Energy'
        } else if (val === 1) {
           return 'Signature'
        } else {
          return '--'
        }
      }
    },
    deviceData: function() {
      return this.$lodash.cloneDeep(this.rowData).map((x) => {
        ["v1_parsing", "v2_parsing"].forEach((key) => {
          if (x.settings && x.settings[key]) {
            x.settings[key] = 1;
          } else {
            if (!x.settings) {
              x.settings = {};
            }
            x.settings[key] = 0;
          }
        });
        x.customer_name = x.user_details ? x.user_details.full_name : "--";
        x.v1_code = this.stIdString + x.v1_code;
        x.settings.feeding_model = this.getFeedingModelVal(x.settings.feeding_model);

        return x;
      });
    }
  },

  async created() {
    await this.initComponent();
  },
  async beforeMount() {
    this.columnDefs = [
      {
        headerName: "ShrimpTalk Id",
        isVisible: true,
        colId: "v1_code",
        field: "v1_code",
        sortable: true,
        filter: true
      },
      {
        headerName: "Hex Id",
        isVisible: true,
        colId: "code",
        field: "code",
        label: "Hex Id",
        filter: true
      },
      // {
      //   headerName: "ShrimpTalk Code",
      //   isVisible: true,
      //   colId: "code",
      //   field: "code",
      //   sortable: true,
      //   filter: true
      // },
      {
        headerName: "Customer Name",
        isVisible: true,
        colId: "full_name",
        field: "customer_name",
        sortable: true,
        filter: true
      },
      {
        colId: "dealer_details.full_name",
        field: "dealer_details.full_name",
        isVisible: true,
        label: "Dealer Name",
        headerName: "Dealer Name",
        required: true,
        minWidth: 200
      },
      {
        headerName: "Location",
        isVisible: true,
        colId: "location_details.name",
        field: "location_details.name",
        label: "Location",
        required: true,
        minWidth: 120,
        filter: true
      },
      {
        headerName: "Alias",
        isVisible: true,
        colId: "alias",
        field: "title",
        sortable: true,
        filter: true
      },
      {
        headerName: "Customer Timezone",
        isVisible: true,
        colId: "user_details.timezone.name",
        field: "user_details.timezone.name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Pond Name",
        isVisible: true,
        colId: "pond_details.title",
        field: "pond_details.title",
        sortable: true,
        filter: true
      },

      {
        headerName: "Software Version",
        isVisible: true,
        colId: "software_version",
        field: "settings.software_version",
        sortable: true,
        filter: "agGridStringCompareFilter"
      },
      {
        headerName: "Last Communication",
        isVisible: true,
        colId: "utc_last_communicated_at",
        field: "utc_last_communicated_at",
        sortable: true,
        width: 230,
        cellRenderer: "agGridLastCommCellRenderer",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (inputDate, cellValue) => {
            const cellDate = this.adm__dateUtilsLib.parse(
              cellValue,
              this.adm__dateUtilsLib.isoFormatString,
              new Date()
            );
            const formattedInputDate = inputDate;
            if (
              this.adm__dateUtilsLib.isBefore(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return -1;
            } else if (
              this.adm__dateUtilsLib.isAfter(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return 1;
            }
            return 0;
          },
          buttons: ["apply", "clear"]
        }
      },
      {
        headerName: "IP Address",
        isVisible: true,
        colId: "ip_address",
        field: "settings.ip_address",
        label: "IP Address",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "PondMothers",
        label: "PondMothers",
        isVisible: true,
        colId: "pond_mothers",
        field: "pond_mothers",
        required: true,
        minWidth: 200,
        filter: true,
        valueFormatter: this.handleFeederParsing
      },
      // {
      //   headerName: "PM Gateway codes",
      //   isVisible: true,
      //   colId: "feeder1_gateway_id",
      //   field: "settings.feeder1_gateway_id",
      //   label: "PM Gateway codes",
      //   required: true,
      //   minWidth: 200,
      //   filter: true
      // },
      {
        headerName: "PM Gateway codes",
        field: "settings",
        isVisible: true,
        colId: "settings",
        sortable: false,
        filter: true,
        valueFormatter: this.handleGatewayParsing
      },
      {
        headerName: "Pond",
        isVisible: true,
        colId: "pond_details.title",
        field: "pond_details.title",
        label: "Pond",
        required: true,
        minWidth: 120,
        filter: true
      },
      {
        headerName: "V1 Id",
        isVisible: true,
        colId: "v1_id",
        field: "v1_id",
        sortable: true,
        filter: true
      },
      {
        headerName: "V2 Id",
        isVisible: true,
        colId: "_id",
        field: "_id",
        sortable: true,
        filter: true
      },
      {
        headerName: "Feed Limit",
        isVisible: true,
        colId: "feed_limit",
        field: "settings.feed_limit",
        sortable: true,
        filter: true
      },
      {
        headerName: "Feeding Model",
        isVisible: true,
        colId: "feeding_model",
        field: "settings.feeding_model",
        sortable: true,
        filter: true
      },
      {
        headerName: "Settings Interval",
        isVisible: true,
        colId: "settings_interval",
        field: "settings.settings_interval",
        label: "Settings Interval",
        required: false,
        minWidth: 100,
        filter: true
      },

      {
        headerName: "Reset Index",
        isVisible: true,
        colId: "reset_index",
        field: "settings.reset_index",
        label: "Reset Index",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Start Index",
        isVisible: true,
        colId: "start_index",
        field: "settings.start_index",
        label: "Start Index",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Service Restart",
        isVisible: true,
        colId: "service_restart",
        field: "settings.service_restart",
        label: "Service Restart",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Lora Channel",
        isVisible: true,
        colId: "lora_channel",
        field: "settings.lora_channel",
        label: "Lora Channel",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Wifi Networks",
        isVisible: true,
        colId: "wifi_networks",
        field: "settings.wifi_networks",
        label: "Wifi Networks",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Signal Strength",
        isVisible: true,
        colId: "wifi_signal_RSSI",
        field: "sensor_values.wifi_signal_RSSI",
        label: "Signal Strength",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Wifi Passwords",
        isVisible: true,
        colId: "wifi_passwords",
        field: "settings.wifi_passwords",
        label: "Wifi Passwords",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Wifi Security",
        isVisible: true,
        colId: "wifi_security",
        field: "settings.wifi_security",
        label: "Wifi Security",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "MAC Address",
        isVisible: true,
        colId: "mac_address",
        field: "settings.mac_address",
        label: "MAC Address",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "OS Version",
        isVisible: true,
        colId: "os_version",
        field: "settings.os_version",
        label: "OS Version",
        required: false,
        minWidth: 100,
        filter: true
      },

      {
        headerName: "Hardware Version",
        isVisible: true,
        colId: "hardware_version",
        field: "settings.hardware_version",
        sortable: true,
        filter: true
      },
      {
        headerName: "PM Gateway IPs",
        isVisible: true,
        colId: "feeder1_gateway_ip",
        field: "settings.feeder1_gateway_ip",
        label: "PM Gateway IPs",
        required: false,
        minWidth: 200,
        filter: true
      },
      {
        headerName: "PM Gateway Channels",
        isVisible: true,
        colId: "feeder1_gateway_channel",
        field: "settings.feeder1_gateway_channel",
        label: "PM Gateway Channels",
        required: true,
        minWidth: 200,
        filter: true
      },
      {
        headerName: "Pi Reboot",
        isVisible: true,
        colId: "pi_reboot",
        field: "settings.pi_reboot",
        label: "Pi Reboot",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "V1 Parsing Enabled",
        isVisible: true,
        colId: "v1_parsing",
        field: "settings.v1_parsing",
        sortable: true,
        filter: true,
        valueFormatter: this.handle_V1_Parsing
      },
      {
        headerName: "V2 Parsing Enabled",
        isVisible: true,
        colId: "v2_parsing",
        field: "settings.v2_parsing",
        sortable: true,
        filter: true,
        valueFormatter: this.handle_V2_Parsing
      },
      {
        headerName: "PondGuard Enabled",
        isVisible: true,
        colId: "pg_enable",
        field: "settings.pg_enable",
        label: "PondGuard Enabled",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Device Timezone",
        isVisible: true,
        colId: "timezone",
        field: "settings.timezone",
        label: "Device Timezone",
        required: true,
        minWidth: 150,
        filter: true
      },
      {
        headerName: "Module Name",
        isVisible: true,
        colId: "module_name",
        field: "settings.module_name",
        label: "Module Name",
        required: true,
        minWidth: 150,
        filter: true
      },
      {
        headerName: "Module Size",
        isVisible: true,
        colId: "module_size",
        field: "settings.module_size",
        label: "Module Size",
        required: true,
        minWidth: 150,
        filter: true
      },
      {
        headerName: "Installed date",
        isVisible: true,
        colId: "on_boarded_at",
        field: "on_boarded_at",
        label: "Installed date",
        required: false,
        sortable: true,
        type: "date",
        minWidth: 150,
        filter: true
      }
    ];
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.frameworkComponents = {
      agGridStringCompareFilter: agGridStringCompareFilter,
      agGridLastCommCellRenderer: agGridLastCommCellRenderer
    };
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails"
    }),
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnDefs.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridShrimpTalkFilters &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    },
    handle_V2_Parsing(params) {
      return params.data.settings.v2_parsing;
    },
    handle_V1_Parsing(params) {
      return params.data.settings.v1_parsing;
    },
    handleFeederParsing(params) {
      return (params.data.pond_mothers || []).map((x) => x.code).join(",");
    },
    handleGatewayParsing(params) {
      return ["feeder1_gateway_id", "feeder2_gateway_id"]
        .map((x) => params.data.settings[x])
        .filter((x) => x)
        .join(",");
    },
    async initComponent() {
      try {
        this.loading = true;
        const response = await this.fetchAllDeviceDetails({
          device_type: "shrimp_talk",
          params: { get_all: true }
        });
        this.rowData = response.shrimp_talks;
        console.log(this.rowData);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    getFormattedExcelDataVal(data, field) {
      let retVal = this.$lodash.get(data, field);
      if (
        (field === "settings.wifi_networks" ||
          field === "settings.wifi_passwords" ||
          field === "settings.wifi_security") &&
        retVal !== undefined
      ) {
        retVal = retVal.join();
      } else if (field === "pond_mothers") {
        retVal.map((val, i) => {
          i === 0 ? (retVal = val.code) : (retVal += "," + val.code);
        });
      } else if (field === "settings.feeder1_gateway_id") {
        retVal = ["feeder1_gateway_id", "feeder2_gateway_id"]
          .map((x) => data.settings[x])
          .filter((x) => x)
          .join(",");
      } else if (field === "utc_last_communicated_at") {
        //  || field === 'on_boarded_at'
        retVal = this.ftm__formatEmpty(this.adm__filterToFormatDate(retVal));
      }
      return retVal;
    },
    handleDownloadExcel() {
      // const columns = this.gridColumnApi.columnDefs.map(
      //   column => [column.field, column.headerName]
      const columns = this.gridColumnApi.gridColumns.map((column) => [
        column.colDef.field,
        column.colDef.headerName
      ]);
      const xlData = [];
      this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        xlData.push(
          columns.reduce((acc, [field, headerName]) => {
            if (
              headerName === "Wifi Networks" ||
              headerName === "Wifi Passwords" ||
              headerName === "Wifi Security"
            ) {
              const data = this.$lodash.get(rowNode.data, field)?.toString();
              acc[headerName] = data;
            } else if (headerName === "PondMothers") {
              const data = this.$lodash
                .get(rowNode.data, field)
                .map((item) => item.code)
                .toString();
              acc[headerName] = data;
            } else if (headerName === "PM Gateway codes") {
              const data = this.$lodash.get(rowNode.data, field);
              // const data = this.$lodash.get(rowNode.data, field).map((item) => {
              //   return item.feeder1_gateway_id + item.feeder2_gateway_id ? ',' + item.feeder2_gateway_id : '';
              // });
              const { feeder1_gateway_id, feeder2_gateway_id } = data;
              const finalData = [feeder1_gateway_id, feeder2_gateway_id]
                .filter(Boolean)
                .join(",");
              // const finalData =
              //   feeder1_gateway_id + feeder2_gateway_id
              //     ? (feeder1_gateway_id && feeder2_gateway_id) ? "," : '' + feeder2_gateway_id
              //     : "";
              acc[headerName] = finalData;
            } else if (field === "utc_last_communicated_at") {
              const data = this.$lodash.get(rowNode.data, field);
              const IstTimeZone = "Asia/Kolkata";
              if (data) {
                acc[headerName] = this.ftm__formatEmpty(
                  this.adm__formatByTZOnUTCISOWithLocale(
                    data,
                    "dd-MMM-yy HH:mm",
                    IstTimeZone
                  )
                );
              }
              //  acc[headerName] = this.ftm__formatEmpty(this.adm__filterToFormatDate(data));
            } else {
              acc[headerName] = this.$lodash.get(rowNode.data, field);
            }
            return acc;
          }, {})
        );
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, "ShrimptalkTable.xlsx", {
        type: "binary",
        cellDates: true
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi.columnModel
      ? this.gridOptions.columnApi.columnModel
      : this.gridOptions.columnApi.columnController;
  }
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}
.ag-theme-alpine {
  height: 80vh !important;
}
</style>
