<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Doser Hall Status"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { doserHallStatusGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard,
  },
  data: function () {
    return {
      chartOptions: doserHallStatusGraphConfig,
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPMDoserHallStatusData");
    this.acm__initParameterName("doser_hall_status");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Doser Hall Status", "line");
    },
  },
};
</script>

<style lang="scss"></style>
