<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: ShrimpTalkTab.vue
Description: This file has the table component that displays the shrimptalks alloted to the dealer and show additional information about which user uses  the device, time it is selled.
-->
<template>
  <div class="dealer-shrimptalk-tab" element-loading-background="white">
    <div class="page_loading" v-loading="loading" v-if="loading"></div>
    <component
      :is="childComponents[selectedComponent]"
      :dialogVisible="dialogVisible"
      @refresh="handleCloseDialog"
      @close-dialog="handleCloseDialog"
    ></component>
    <layout-dealer-card
      v-show="!loading"
      search-property="device_code"
      :queryParams="queryParams"
      @add-item-click="handleAddUserClick"
      @loadData="handleLoadData"
    >
      <template v-slot:heading>
        <div class="main_header">
          <img src="@/assets/dealer/devices.svg" atl="down arrow" />
          <p style="margin-left: 10px">
            {{ $t("Comn_shrimptalk") }}
          </p>
        </div>
      </template>
      <defaultTable
        slot="table"
        :tableData="tableData"
        :columns="columnsObject"
        :total="total"
        class="add_PondMother"
        search-property="device_code"
        :addPadingToSearch="true"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
      >
        <template v-slot:email_header="{ header }">
          <span>{{ $t(header.label) }}</span>
        </template>
        <template v-slot:actions_header="{ header }">
          <span>{{ $t(header.label) }}</span>
        </template>

        <template v-slot:status_header="{ header }">
          <span>{{ $t(header.label) }}</span>
        </template>

        <template v-slot:code_header="{ header }">
          <span>{{ $t(header.label) }}</span>
        </template>

        <template v-slot:added_date_header="{ header }">
          <span>{{ $t(header.label) }}</span>
        </template>
        <template v-slot:first_assigned_date_header="{ header }">
          <el-tooltip
            :content="$t(header.sub_label)"
            placement="top"
            effect="light"
          >
            <p>{{ $t(header.label) }}</p>
          </el-tooltip>
        </template>
        <template v-slot:last_assigned_date_header="{ header }">
          <el-tooltip
            :content="$t(header.sub_label)"
            placement="top"
            effect="light"
          >
            <p>{{ $t(header.label) }}</p>
          </el-tooltip>
        </template>
        <template v-slot:first_user_assigned_date="{ row }">
          {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.first_user_assigned_date)) }}
        </template>
        <template v-slot:last_user_assigned_date="{ row }">
          {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.last_user_assigned_date)) }}
        </template>
        <!-- <template v-slot:updated_at="{ row }">
          {{
            ftm__digitPrecision(adm__filterToFormatDate(row.data.updated_at))
          }}
        </template> -->
        <template v-slot:status="{ row }">
          {{ getStatus(row.data, "SHRIMP_TALK") }}
        </template>
        <template v-slot:code="{ row }">
          {{ row.data.code }}
        </template>
        <template v-slot:email="{ row }">
          {{
            row.data.user_details
              ? row.data.user_details.first_name
              : getDealerUsername.first_name
          }}

          {{
            row.data.user_details
              ? row.data.user_details.last_name
              : getDealerUsername.last_name
          }}
          <br />
          {{
            row.data.user_details
              ? row.data.user_details.email
              : getDealerUsername.email
          }}
        </template>
        <template v-slot:added_date="{ row }">
          {{
            ftm__digitPrecision(adm__filterToFormatDate(row.data.added_date))
          }}
        </template>
        <template v-slot:actions="{ row }">
          <el-button-group>
            <er-button
              size="mini"
              :disabled="!Boolean(row.data.user_details)"
              @click="handleDeviceDelete(row.data, 'SHRIMP_TALK')"
              :title="$t('dealer.unassign_from_user')"
            >
              <span class="material-icons-round icon__prefix"> delete </span>
            </er-button>
          </el-button-group>
        </template>
      </defaultTable>
    </layout-dealer-card>
  </div>
</template>
<script>
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "../defaultTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import addStToUserDialog from "./addStToUserDialog";
import manageSTSubscription from "./manageSTSubscription";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import dealerDevicesMixin from "../dealerDevicesMixin";
import LayoutDealerCard from "../layoutDealerCard.vue";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    notificationMixin,
    filtersMixin,
    adminDatesHandlerMixin,
    dealerDevicesMixin("CONTAINER")
  ],
  components: {
    addStToUserDialog,
    manageSTSubscription,
    defaultTable,
    LayoutDealerCard
  },
  props: ["dealer_id"],
  data: function () {
    return {
      tableData: [],
      unassignedSTs: [],
      unassignedUsers: [],
      userSearchQuery: {},
      selectedUsers: [],
      total: 0,
      selectedSTs: [],
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      selectedComponent: undefined,
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerAssignSts: "getDealerAssignSts",
      getDealerUsers: "getDealerUsers",
      getDealerUnassignSts: "getDealerUnassignSts"
    }),
    getDealerUsername() {
      return this.$store.getters["dealer/getDealerProfile"];
    },
    columnsObject: function () {
      return [
        {
          prop: "code",
          name: "code",
          label: "Comn_Device_ID",
          required: true,
          sortable: true
        },

        {
          prop: "email",
          name: "email",
          label: "DL_assigned_customer",
          required: true,
          sortable: false
        },
        {
          prop: "first_user_assigned_date",
          name: "first_assigned_date",
          label: "DL_first_assigned_date",
          sub_label: "DL_first_assigned_date_content",
          required: true,
          sortable: false
        },
        {
          prop: "last_user_assigned_date",
          name: "last_assigned_date",
          label: "DL_last_assigned_date",
          sub_label: "DL_last_assigned_date_content",
          required: true,
          sortable: false
        },
        // {
        //   prop: "updated_at",
        //   name: "added_date",
        //   label: "DL_assigned_date",
        //   required: true,
        //   sortable: false
        // },
        {
          name: "status",
          prop: "status",
          label: "device_status",
          required: true,
          sortable: false
        },
        {
          label: "Comn_actions",
          prop: "actions",
          name: "actions",
          required: true
        }
      ];
    },
    childComponents() {
      return {
        addStToUser: "addStToUserDialog",
        stSubscription: "manageSTSubscription"
      };
    }
  },
  methods: {
    ...mapActions("dealer", {
      fetchDealerAssignedDevices: "fetchDealerAssignedDevices",
      addShrimpTalkToUser: "addShrimpTalkToUser",
      deleteShrimpTalkFromUser: "deleteShrimpTalkFromUser"
    }),
    async handleLoadData(query) {
      try {
        if (
          query.device_code &&
          query.device_code.toUpperCase().startsWith("ST")
        ) {
          query.device_code = query.device_code.substr(2).trim();
        }
        this.queryParams = query;
        this.loading = true;
        const response = await this.fetchDealerAssignedDevices({
          params: query,
          infinite_scroll: false,
          device_type: "shrimp_talk"
        });

        this.tableData = this.getDealerAssignSts;
        this.total = response.total;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleShrimpTalkDelete(input) {
      await this.nofm__deleteConfirmation(input._id, "table");
      const payload = {
        shrimp_talk_ids: [input._id]
      };
      try {
        this.loading = true;
        await this.deleteShrimpTalkFromUser(payload);
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddUserClick() {
      this.dialogVisible = true;
      this.selectedComponent = "addStToUser";
    },
    async handleCloseDialog(isFetchData) {
      this.dialogVisible = false;
      if (isFetchData) {
        await this.handleLoadData(this.queryParams);
      }
    }
  }
};
</script>

<style lang="scss">
.dealer-shrimptalk-tab {
  height: 100%;
  .page_loading {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .main_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
}
.shrimpTalk_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 260px, 300px, 350px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 160px, 210px);
  }
}
</style>
