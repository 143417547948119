<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stListIndividualDetailsSA.vue
Description:this file contains the list of shrimptalks basic details of individual shrimptalks and  pond mother code and stDetails and Latest Sensor Values
-->
<template>
  <layout
    v-loading="loading"
    element-loading-background="white"
    class="st-individual-details-sa"
  >
    &nbsp;
    <template slot="deviceListMenu">
      <item-list-side-bar
        :item-list="stList"
        :init-item-id="st_id"
        item-type="shrimptalk"
        :showProperty="['code', 'v1_code']"
        :property-seperator="(v1, v2) => `${v1} (ST${v2})`"
        :dataFetchMethod="loadStData"
        search-str-query-param="device_code"
        @loadData="loadStData"
        @cleared="loadStData"
        @itemClicked="handleStSelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </template>
    <portal to="outsideMainScroll">
      <basic-customer-details
        :device-details="deviceDetails"
        :user-details="customerDetails"
        :showCustomerDetails="true"
        @change-edit="handleEditChange"
      >
        <template slot="deviceCode.value">
          {{ `ST${deviceDetails.v1_code}` }}
        </template>
        <template slot="moreActions">
          <i
            class="material-icons-round"
            :title="syncStatus.message"
            :style="`color:${syncStatus.color};`"
          >
            sync
          </i>
          <er-button
            v-if="userDevicePermissionCheck"
            btn-type="save"
            size="mini"
            @click="handleRemoveCustomer"
            :disabled="!customerDetails"
          >
            <span class="material-icons-round"> person_remove </span>
          </er-button>
        </template>
      </basic-customer-details>
    </portal>
    <el-scrollbar
      class="st-details st-details-scroll"
      viewClass="st-details-scroll-view"
      wrapClass="st-details-scroll-wrap"
    >
      <el-card>
        <template slot="header">
          <h4>PondMother Details</h4>
        </template>
        <el-table
          size="mini"
          class="table--600"
          :data="deviceDetails.pond_mothers"
        >
          <el-table-column prop="code" label="Code"></el-table-column>
          <el-table-column prop="is_master" label="Master">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_master" label="1" disabled
                >Master</el-checkbox
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <st-details
        :device-details="deviceDetails"
        :customer-details="customerDetails"
        :isDisabled="isDisabled"
        @refresh="handleStSelected"
      ></st-details>
    </el-scrollbar>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import stDetails from "@/components/superadmin/shrimp-talk/stDetails";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  props: ["st_id"],
  components: {
    layout,
    basicCustomerDetails,
    itemListSideBar,
    stDetails
  },
  data: function() {
    return {
      data: { st_code: "" },
      loading: false,
      shrimp_talk_data: {},
      totalItemsCount: 100,
      isDisabled: true
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getShrimpTalks: "getShrimpTalks",
      getStsObj: "getStsObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj"
    }),
    syncStatus() {
      return this.deviceDetails.settings_changed
        ? { message: "Settings not updated to device", color: "#E31A1C" }
        : { message: "Settings updated to device", color: "#33A02C" };
    },
    deviceDetails() {
      // return this.getStsObj[this.st_id];
      return this.shrimp_talk_data;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: ""
        };
      }
    },
    stList() {
      return this.getShrimpTalks;
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userDevicePermissionCheck() {
      return this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER");
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      deleteDevicesToUser: "deleteDevicesToUser"
    }),
    handleEditChange(changedEditStatus) {
      this.isDisabled = changedEditStatus;
    },
    async handleRemoveCustomer() {
      try {
        await this.$confirm(
          "Your are going to unassign the user from the device, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [this.st_id],
          device_type: "SHRIMP_TALK"
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        await this.initComponent();
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        await this.fetchAllDeviceDetails({
          device_type: "shrimp_talk",
          infinite_scroll: true,
          params: { page: 1, limit: 19, order_by: "code", order_type: "asc" }
        });
        // await this.fetchAllUsers({ get_all: true });
        await this.callStoreOnStData(this.st_id);
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadStData({ query, infinite_scroll = false }) {
      if (
        query.device_code &&
        query.device_code.toUpperCase().startsWith("ST")
      ) {
        query.device_code = query.device_code.substr(2).trim();
      }
      const response = await this.fetchAllDeviceDetails({
        device_type: "shrimp_talk",
        infinite_scroll: infinite_scroll,
        params: query
      });

      this.totalItemsCount = response.total;
    },
    async callStoreOnStData(st_id) {
      try {
        this.shrimp_talk_data = await this.fetchADeviceData({
          device_type: "shrimp_talk",
          device_id: st_id
        });
        console.log(this.shrimp_talk_data);
        // this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleStSelected(st_id) {
      try {
        this.loading = true;
        this.$router.replace(st_id);
        await this.callStoreOnStData(st_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.st-individual-details-sa {
  .st-details {
    padding-left: 10px;
    &.st-details-scroll {
      position: relative;
      height: 81vh;
    }
    .st-details-scroll-view {
      padding-right: 10px;
    }
    .st-details-scroll-wrap {
      height: 100%;
      overflow-x: auto;
    }
    .el-card__header h4 {
      font-size: 14px;
    }
  }
}
</style>
