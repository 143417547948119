<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondMotherLogs.vue
Description:This file contains  ItemListSideBar(list of pondmothers), Basic pond mother details , pmFeedGraphs (pmSuperAdminHourlyFeedGraph,pmSuperAdminSlotwiseFeedGraphs )
-->
<template>
  <el-container class="pond-mother-logs">
    <el-aside>
      <portal-target name="outsideAsideScroll"></portal-target>
      <item-list-side-bar
        :item-list="pmList"
        :init-item-id="deviceId"
        item-type="pondmotherlogs"
        class="side-list-menu"
        showProperty="code"
        :search="true"
        :dataFetchMethod="loadPmData"
        searchStrQueryParam="device_code"
        @loadData="loadPmData"
        @cleared="loadPmData"
        @itemClicked="handlePmSelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </el-aside>
    <el-main v-loading="loading" element-loading-background="white">
      <div class="sub-main">
        <basic-customer-details
          :device-details="deviceDetails"
          :user-details="customerDetails"
          :showCustomerDetails="false"
          :allowAddCustomer="false"
          :allowCustomerLogin="true"
        >
          <template slot="moreDetails">
            <center>
              <el-row class="details__heading">Total Feed</el-row>
              <el-row class="details__heading__value">{{
                ftm__formatEmpty(
                  ftm__digitPrecision(getTotalFeedAndConsumedFeed.total_feed)
                ) +
                  " " +
                  getFeedUnit
              }}</el-row>
            </center>
            <center>
              <el-row class="details__heading">Consumed Total</el-row>
              <el-row class="details__heading__value">{{
                ftm__formatEmpty(
                  ftm__digitPrecision(getTotalFeedAndConsumedFeed.consumed_feed)
                ) +
                  " " +
                  getFeedUnit
              }}</el-row>
            </center>
          </template> </basic-customer-details
        >&nbsp;
        <pm-feed-graphs
          @changedDate="handleDateRangeChange"
          @downloadZIP="handleDownloadZIP"
          :customerDetails="customerDetails"
          :pmId="pm_id"
          :loading="loading"
        ></pm-feed-graphs>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import pmFeedGraphs from "./pmFeedGraphs";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@/components/shared/eventBus";
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin, filtersMixin],
  props: ["pm_id"],
  components: {
    itemListSideBar,
    pmFeedGraphs,
    basicCustomerDetails
  },
  data: function() {
    return {
      data: { gateway_code: "" },
      loading: false,
      pond_mother_data: "",
      customer_data: "",
      totalItemsCount: 100,
      emptyStr: "--",
      dateRange: ["", ""],
      refresh: false
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondMothers: "getPondMothers",
      getPmsObj: "getPmsObj",
      getTotalFeedAndConsumedFeed: "getTotalFeedAndConsumedFeed"
    }),
    getFeedUnit() {
      return this.getTotalFeedAndConsumedFeed.total_feed > 0
        ? this.$t("Comn_kg")
        : "";
    },
    deviceDetails() {
      return this.pond_mother_data;
    },
    deviceTimeZoneStr() {
      return (this.customerDetails || { timezone: { name: "UTC" } }).timezone
        .name;
    },
    deviceId() {
      return (this.deviceDetails || { _id: "No Data" })._id;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
          timezone: {
            name: "UTC",
            offset: 0
          }
        };
      }
    },
    pmList() {
      return this.getPondMothers;
    }
  },
  async mounted() {
    await this.initDateRangeWithMomentObj();
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      fetchAPMHourlyFeedData: "fetchAPMHourlyFeedData",
      fetchAPMSlotWiseFeedData: "fetchAPMSlotWiseFeedData",
      clearPMSensorData: "clearPMSensorData",
      clearPMFeedData: "clearPMFeedData",

      fetchPMFeedLogsGraphData: "fetchPMFeedLogsGraphData",
      fetchPMFeedLogsDownloadCSV: "fetchPMFeedLogsDownloadCSV",
      setPmId: "setPmId"
    }),
    initDateRangeWithMomentObj(dateArr) {
      this.$set(
        this.dateRange,
        0,
        this.adm__dateUtilsLib.formatDate(new Date(), "yyyy-MM-dd")
      );
      this.$set(
        this.dateRange,
        1,
        this.adm__dateUtilsLib.formatDate(new Date(), "yyyy-MM-dd")
      );
    },
    async initComponent() {
      try {
        this.loading = true;
        const pmResponse = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          infinite_scroll: true,
          params: { page: 1, limit: 20 }
        });
        this.totalItemsCount = pmResponse.total;
        let pm_id = (this.pmList[0] || { _id: "No PM" })._id;
        this.setPmId(pm_id);
        if (this.$route.query.pm_id) {
          pm_id = this.$route.query.pm_id;
        } else {
          this.$router.replace(
            `pondmothers?tab=${this.$route.query.tab}&pm_id=${pm_id}`
          );
        }

        this.clearPMSensorData();

        await this.callStoreOnPmData(pm_id);
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadPmData({ query, infinite_scroll = false }) {
      this.loading = true;
      const response = await this.fetchAllDeviceDetails({
        device_type: "pond_mother",
        infinite_scroll: infinite_scroll,
        params: query
      });
      this.loading = false;
      this.totalItemsCount = response.total;
    },
    async callStoreOnPmData(pond_mother_id) {
      try {
        this.loading = true;
        this.pond_mother_data = await this.fetchADeviceData({
          device_type: "pond_mother",
          device_id: pond_mother_id
        });
        const startOfToday = this.adm__dateUtilsLib.startOfDay(
          this.adm__getTodayInUserTZ(this.deviceTimeZoneStr)
        );
        await this.callStoreOnLatestHourlyFeedData({
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            this.adm__dateUtilsLib.isoFormatString
          ),
          to_date: this.adm__dateUtilsLib.formatDate(
            startOfToday,
            this.adm__dateUtilsLib.isoFormatString
          )
        });

        await this.callStoreOnLatestSlotFeedData({
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            this.adm__dateUtilsLib.isoFormatString
          ),
          to_date: this.adm__dateUtilsLib.formatDate(
            startOfToday,
            this.adm__dateUtilsLib.isoFormatString
          )
        });
        await this.callStoreOnFeedLogsData({
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            "yyyy-MM-dd"
          ),
          to_date: this.adm__dateUtilsLib.formatDate(startOfToday, "yyyy-MM-dd")
        });
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnLatestHourlyFeedData(changedDateValue) {
      const params = {};
      params.from_date = changedDateValue.from_date;
      params.to_date = changedDateValue.to_date;
      if (this.pond_mother_data._id) {
        this.loading = true;
        await this.fetchAPMHourlyFeedData({
          pm_code: this.pond_mother_data.code,
          pm_id: this.pond_mother_data._id,
          params,
          graphParameter: "hourly_feed"
        });
        this.loading = false;
      } else {
        this.refresh = true;
        this.clearPMFeedData();
        this.refresh = false;
      }
    },
    async callStoreOnLatestSlotFeedData(changedDateValue) {
      const params = {};
      params.from_date = changedDateValue.from_date;
      params.to_date = changedDateValue.to_date;
      if (this.pond_mother_data._id) {
        this.loading = true;
        await this.fetchAPMSlotWiseFeedData({
          pm_code: this.pond_mother_data.code,
          pm_id: this.pond_mother_data._id,
          params,
          graphParameter: "slot_wise_feed"
        });
        this.loading = false;
      } else {
        this.refresh = true;
        this.clearPMFeedData();
        this.refresh = false;
      }
    },
    async handlePmSelected(pm_id) {
      try {
        this.loading = true;
        // this.$router.replace(pm_id);
        this.setPmId(pm_id);

        this.$router.replace(
          `pondmothers?tab=${this.$route.query.tab}&pm_id=${pm_id}`
        );
        eventBus.$emit("pm-item-clicked", {});
        await this.callStoreOnPmData(pm_id);
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleDateRangeChange(changedDateValue, parameter) {
      if (parameter === "feed_logs") {
        await this.callStoreOnFeedLogsData(changedDateValue, parameter);
      } else if (parameter === "hourly_feed") {
        this.loading = true;
        await this.callStoreOnLatestHourlyFeedData(changedDateValue);
      } else {
        await this.callStoreOnLatestSlotFeedData(changedDateValue);
        this.loading = false;
      }
    },

    async callStoreOnFeedLogsData(changedDateValue, parameter) {
      const params = {};
      params.from_date = changedDateValue.from_date;
      params.to_date = changedDateValue.to_date;
      if (this.pond_mother_data._id) {
        this.loading = true;
        await this.fetchPMFeedLogsGraphData({
          pm_code: this.pond_mother_data.code,
          pm_id: this.pond_mother_data._id,
          params,
          graphParameter: parameter
        });
        this.loading = false;
      }
    },
    async handleDownloadZIP(changedDateValue, parameter) {
      if (parameter === "feed_logs") {
        await this.callStoreOnFeedLogsDownloadZIP(changedDateValue);
      }
    },
    async callStoreOnFeedLogsDownloadZIP(changedDateValue, parameter) {
      try {
        const params = {};
        params.from_date = changedDateValue.from_date;
        params.to_date = changedDateValue.to_date;
        if (this.pond_mother_data._id) {
          this.loading = true;
          const response = await this.fetchPMFeedLogsDownloadCSV({
            pm_code: this.pond_mother_data.code,
            pm_id: this.pond_mother_data._id,
            params,
            responseType: "blob"
          });
          if (
            !response.data ||
            !response.data.type ||
            response.data.type !== "application/zip"
          ) {
            this.$message.warning("No Data Found in Selected Dates.");
          } else {
            let fileName = "";
            if (
              this.pond_mother_data.code &&
              params.from_date &&
              params.to_date
            ) {
              fileName =
                this.pond_mother_data.code +
                "_" +
                params.from_date +
                "_" +
                params.to_date +
                ".zip";
            } else {
              fileName = "FeedLogs.zip";
            }
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.nofm__notifySuccess({
              msgLangkey: {
                title: "Comn_download_success_msg",
                count: 0
              }
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
// .el-main {
//   padding: 4px 0px 10px 0px !important;
// }
.pond-mother-logs {
  // margin-top: 54px;
  .item-list-menu {
    height: 82vh;
  }
  .pond-mother-logs-aside-scroll {
    height: 100%;
  }
  .pond-mother-logs-aside-scroll-view {
    padding-right: 10px;
  }

  .pond-mother-logs-aside-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .el-main {
    padding: 5px 20px 20px 20px !important;
    overflow: hidden !important;
  }
  .sub-main {
    margin: 0 -9px;
  }
}
</style>
