<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stDetails
Description:this file contains the Schema that generate the form with default Values
-->
<template>
  <div class="shrimpsnap-details">
    <tab-layout
      :componentList="componentList"
      :showComponent="selectedTab"
      :tab-click-handler="(tab) => tab"
      @selectedComponent="handleSelectedComponent"
      v-loading="saveSettingsLoading"
    >
      <template slot="device_settings">
        <br />
        <el-col :span="18">
          <generate-form
            element-loading-background="white"
            :elFormOptions="elFormOptions"
            :schema="ssDeviceSettingsField"
            :model="ssDeviceSettingsModel"
            ref="deviceSettingsForm"
            order-fields-by="DEFAULT"
            :disabled="isDisabled"
            @model-change="
              (value, path) => handleModelChange(value, path, 'DEVICE')
            "
          >
            <template slot="footer">
              <er-button
                btnType="save"
                :showLabel="true"
                :showIcon="true"
                size="mini"
                :disabled="isDisabled"
                @click="saveSettingsInDb('DEVICE')"
              ></er-button>
            </template>
          </generate-form>
        </el-col>
      </template>
      <template slot="image_settings">
        <br />
        <generate-form
          element-loading-background="white"
          :elFormOptions="elFormOptions"
          :schema="ssImageSettingsField"
          :model="ssImageSettingsModel"
          ref="imageSettingsForm"
          order-fields-by="DEFAULT"
          :disabled="isDisabled"
          @model-change="
            (value, path) => handleModelChange(value, path, 'IMAGE')
          "
        >
          <template slot="footer">
            <er-button
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              size="mini"
              :disabled="isDisabled"
              @click="saveSettingsInDb('IMAGE')"
            ></er-button>
          </template>
        </generate-form>
      </template>
      <template slot="logs">
        <br />
        <small-table
          :tableData="wifi_table_data"
          :columns="wifi_table_columns"
          :addPadingToSearch="true"
          tableName="Available Networks"
          search-on-property="wifi_network"
          :height="300"
        ></small-table>
        <shrimp-snap-voltage-graph></shrimp-snap-voltage-graph>
        <shrimp-snap-error-codes-graph></shrimp-snap-error-codes-graph>
      </template>
    </tab-layout>
  </div>
</template>

<script>
import ShrimpSnap from "@/model/shrimpsnap";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import generateForm from "@/components/superadmin/shared/generateForm";
import smallTable from "@/components/superadmin/shared/smallTable";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import shrimpSnapVoltageGraph from "@/components/superadmin/shrimp-snap/shrimpSnapVoltageGraph";
import shrimpSnapErrorCodesGraph from "@/components/superadmin/shrimp-snap/shrimpSnapErrorCodes";
export default {
  props: {
    customerDetails: {
      default: () => ({})
    },
    deviceDetails: { default: () => ({}) },
    isDisabled: { default: true }
  },
  mixins: [errorHandlerMixin],
  components: {
    tabLayout,
    smallTable,
    generateForm,
    shrimpSnapVoltageGraph,
    shrimpSnapErrorCodesGraph
  },
  data: function () {
    return {
      ssDeviceSettingsPayload: {},
      ssImageSettingsPayload: {},
      ssDeviceSettingsModel: new ShrimpSnap().settings,
      ssImageSettingsModel: new ShrimpSnap().image_settings,
      defaultShrimpSnap: new ShrimpSnap(),
      saveSettingsLoading: false,
      selectedTab: "device_settings",
      componentList: [
        {
          name: "device_settings",
          label: "Device Settings"
        },
        {
          name: "image_settings",
          label: "Image Settings"
        },
        {
          name: "logs",
          label: "Logs"
        }
      ]
    };
  },
  watch: {
    deviceDetails: function (newValue, oldValue) {
      this.initComponent();
    }
  },
  computed: {
    elFormOptions: function () {
      return {
        disabled: false,
        hideRequiredAsterisk: true,
        labelPosition: "top",
        layout: "grid"
        // inline: true
      };
    },
    device: function () {
      return this.deviceDetails || new ShrimpSnap();
    },
    ssDeviceSettingsField() {
      return [
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "ID",
          model: "v1_code",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "IP Address",
          model: "ip_address",
          validationRules: ""
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "External Address",
          model: "external_ip_address",
          validationRules: ""
        },

        {
          type: "timePicker",
          inputType: "time",
          label: "Start Time",
          model: "start_time",
          id: "start_time",
          props: { valueFormat: "HH:mm", format: "HH:mm" },
          validationRules: "required|timeLessThan:@end_time,HH:mm"
        },
        {
          type: "timePicker",
          inputType: "time",
          label: "Stop Time",
          model: "end_time",
          id: "end_time",
          props: { valueFormat: "HH:mm", format: "HH:mm" },
          validationRules: "required|timeGreaterThan:@start_time,HH:mm"
        },

        {
          type: "input_number",
          inputType: "number",
          label: "Settings Interval (secs)",
          model: "settings_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Data Clean Interval (days)",
          model: "data_clean_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Sampling Frequency (secs)",
          model: "sampling_frequency",
          validationRules: "required"
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Silent" },
              { value: 1, label: "Noise" }
            ]
          },
          inputType: "number",
          label: "Motor Mode",
          model: "motor_mode",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Video Duration (secs)",
          model: "video_duration",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Image Count",
          model: "image_count",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Tray Depth",
          model: "tray_depth_top",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Restart",
          model: "shrimp_snap_restart",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Reboot Pi",
          model: "pi_reboot",
          validationRules: ""
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Manual Clean",
          model: "manual_clean",
          validationRules: ""
        }
      ];
    },
    ssImageSettingsField() {
      return [
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "ID",
          model: "v1_code",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Resolution(MP)",
          model: "resolution",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 100, max: 800, step: 100 },
          label: "ISO",
          model: "iso",
          validationRules: "required"
        },
        {
          type: "select",
          props: {
            options: [
              {
                value: 0,
                label: "Off"
              },
              {
                value: 1,
                label: "Auto"
              },
              {
                value: 2,
                label: "Sun"
              },
              {
                value: 3,
                label: "Cloud"
              },
              {
                value: 4,
                label: "Shade"
              },
              {
                value: 5,
                label: "Tungsten"
              },
              {
                value: 6,
                label: "Fluorescent"
              },
              {
                value: 7,
                label: "Incandescent"
              },
              {
                value: 8,
                label: "Flash"
              },
              {
                value: 9,
                label: "Horizon"
              },
              {
                value: 10,
                label: "Greyworld"
              }
            ]
          },
          inputType: "number",
          label: "White Balance",
          model: "white_balance",
          validationRules: ""
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Fixed" },
              { value: 1, label: "Automatic" }
            ]
          },
          inputType: "number",
          label: "Focus",
          model: "focus",
          validationRules: ""
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Fish Eye" },
              { value: 1, label: "Wide" },
              { value: 2, label: "Standard" },
              { value: 3, label: "Telephoto" }
            ]
          },
          inputType: "number",
          label: "Lens Type",
          model: "lens_type",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 100 },
          label: "Shutter Speed",
          model: "shutter_speed",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 0, max: 100 },
          label: "Compression (%)",
          model: "compression",
          validationRules: "required"
        },
        {
          type: "select",
          props: {
            options: [
              { value: "jpg", label: "JPG" },
              { value: "png", label: "PNG" }
            ]
          },
          label: "File Extention",
          model: "file_extension",
          validationRules: ""
        }
      ];
    },
    wifi_table_data: function () {
      // const sensorDetails = this.deviceDetails.sensor_values || {};
      const settings = this.deviceDetails.settings || {};

      const wifi_networks = settings.wifi_networks || [];
      const wifi_password = settings.wifi_passwords;
      const wifi_security = settings.wifi_security;
      const wifi_signal = Array.isArray(settings.signal_strength)
        ? settings.signal_strength
        : [];

      const tableData = [];
      for (let i = 0; i < wifi_networks.length; i++) {
        tableData.push({
          wifi_network: wifi_networks[i],
          wifi_password: wifi_password[i],
          wifi_security: wifi_security[i],
          wifi_signal: wifi_signal[i] || "--"
        });
      }

      return tableData;
    },
    wifi_table_columns: function () {
      return [
        {
          prop: "wifi_network",
          label: "Wifi Network",
          required: true
        },
        {
          prop: "wifi_password",
          label: "Wifi Password",
          required: true
        },
        {
          prop: "wifi_security",
          label: "Wifi Security",
          required: true
        },
        {
          prop: "wifi_signal",
          label: "Wifi Signal",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    initComponent() {
      this.ssDeviceSettingsModel = this.$lodash.cloneDeep({
        v1_code: `SS${(this.device || { v1_code: "NO CODE" }).v1_code}`,
        ...this.defaultShrimpSnap.settings,
        ...(this.device || { settings: {} }).settings
      });
      this.ssImageSettingsModel = this.$lodash.cloneDeep({
        v1_code: `SS${(this.device || { v1_code: "NO CODE" }).v1_code}`,
        ...this.defaultShrimpSnap.image_settings,
        ...(this.device || { image_settings: {} }).image_settings
      });
    },
    handleModelChange(value, path, settingsType) {
      const settingsMap = {
        DEVICE: {
          model: this.ssDeviceSettingsModel,
          payload: this.ssDeviceSettingsPayload
        },
        IMAGE: {
          model: this.ssImageSettingsModel,
          payload: this.ssImageSettingsPayload
        }
      };
      settingsMap[settingsType].model[path] = value;
      settingsMap[settingsType].payload = Object.assign(
        settingsMap[settingsType].payload,
        settingsMap[settingsType].model
      );
    },
    handleSelectedComponent(component) {
      this.selectedTab = component;
    },
    async saveSettingsInDb(settingsType) {
      const settingsMap = {
        DEVICE: {
          form: "deviceSettingsForm",
          payload: this.ssDeviceSettingsPayload,
          type: "SHRIMP_SNAP_DEVICE"
        },
        IMAGE: {
          form: "imageSettingsForm",
          payload: this.ssImageSettingsPayload,
          type: "SHRIMP_SNAP_IMAGE"
        }
      };
      const response = await this.$refs[
        settingsMap[settingsType].form
      ].$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
      this.saveSettingsLoading = true;
      try {
        const response = await this.saveSingleDeviceSettingsData({
          device_code: this.deviceDetails.code,
          payload: settingsMap[settingsType].payload,
          settingsType: settingsMap[settingsType].type
        });
        await this.$emit("refresh", this.device._id);
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
        this.ehm__errorMessages(err, true);
      }
      this.saveSettingsLoading = false;
    }
  }
};
</script>

<style lang="scss">
.shrimpsnap-details {
  margin-top: 10px;
  .el-card {
    padding-bottom: 10px;
  }
  div.cell {
    text-transform: capitalize;
  }
  .el-form {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    .form-cell {
      width: 200px;
    }
  }
}
</style>
