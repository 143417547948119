<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkTableSA.vue
Description:This file contains the details of the shrimptalk devices
-->
<template>
  <layout :no-aside="true" class="st-table-sa">
    <!-- <tab-layout
      :componentList="componentList"
      :showComponent="urlParams.tab"
      :tab-click-handler="handleTabClick"
      @selectedComponent="handleSelectedComponent"
    >
      <template slot="shrimpTalkLog">
        <shrimpTalkLog></shrimpTalkLog>
      </template>
      <template slot="shrimpTalkIntake">
        <shrimpTalkIntake></shrimpTalkIntake>
      </template>
      <template slot="shrimpTalkFeed">
        <shrimpTalkFeed></shrimpTalkFeed>
      </template>
      <template slot="shrimpTalkTable">
        <shrimpTalkTable></shrimpTalkTable>
      </template>
    </tab-layout> -->
    <er-top-route-nav type="tabs" :items="computedTabItems"></er-top-route-nav>
    <!-- </simplebar> -->
    <component
      :is="computedComponentName"
      @change-tab="handleChangeTabTo"
      :tabData="componentList[computedComponentName]"
    ></component>
  </layout>
</template>

<script>
import hashSum from "hash-sum";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import shrimpTalkLog from "@/components/superadmin/shrimp-talk/shrimpTalkLog";
import shrimpTalkDetails from "@/components/superadmin/shrimp-talk/shrimpTalkDetailsTab";
import shrimpTalkIntake from "@/components/superadmin/shrimp-talk/shrimpTalkIntake";
import shrimpTalkFeed from "@/components/superadmin/shrimp-talk/shrimpTalkFeed";
import shrimpTalkFilter from "@/components/superadmin/shrimp-talk/shrimpTalkTable";
import notificationMixin from "@/mixins/notificationMixin";
import { mapActions } from "vuex";
const tabComponents = [
  { name: "shrimpTalkDetails", label: "ShrimpTalk Details" },
  { name: "shrimpTalkLog", label: "ShrimpTalk Data" },
  { name: "shrimpTalkIntake", label: "ShrimpTalk Intake" },
  { name: "shrimpTalkFeed", label: "ShrimpTalk Feed" },
  { name: "shrimpTalkFilter", label: "ShrimpTalk Filter" }
];
export default {
  components: {
    layout,
    shrimpTalkLog,
    shrimpTalkIntake,
    shrimpTalkFeed,
    shrimpTalkFilter,
    tabLayout,
    shrimpTalkDetails
  },
  mixins: [
    errorHandlerMixin,
    adminDatesHandlerMixin,
    notificationMixin,
    filtersMixin
  ],
  provide() {
    return {
      shrimpTalkView: this
    };
  },
  data: function () {
    return {
      queryParams: {},
      componentName: "shrimpTalkDetails",
      urlParams: {
        tab: "shrimpTalkDetails",
        ...this.$route.query
      },
      componentList: {
        shrimpTalkDetails: {
          name: "shrimpTalkDetails",
          label: "ShrimpTalk Details"
        },
        shrimpTalkLog: {
          name: "shrimpTalkLog",
          label: "ShrimpTalk Log"
        },
        shrimpTalkIntake: {
          name: "shrimpTalkIntake",
          label: "ShrimpTalk Intake"
        },
        shrimpTalkFeed: {
          name: "shrimpTalkFeed",
          label: "ShrimpTalk Feed"
        },
        shrimpTalkTable: {
          name: "shrimpTalkFilter",
          label: "ShrimpTalk Filter"
        }
      }
    };
  },
  watch: {
    urlParams: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (hashSum(this.$route.query) === hashSum(newValue)) return;
        this.$router.push({ path: "/admin/shrimptalks", query: newValue });
      }
    },
    routeQueryParams: function(newValue, oldValue) {
      this.urlParams = Object.assign({}, this.urlParams, newValue)
    }
  },
  computed: {
    computedTabItems: function () {
      return tabComponents;
    },
    routeQueryParams: function() {
      return this.$route.query
    },
    computedComponentName: {
      get() {
        return this.urlParams.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey"
    }),
    handleChangeTabTo: function (tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    }
  }
};
</script>

<style lang="scss">
.st-table-sa {
  .el-tag.el-tag--light .el-link--inner {
    color: #409eff !important;
  }
}
</style>
