<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmFeedGraphs
Description:this file contains the graphs (pmSuperAdminHourlyFeedGraph,pmSuperAdminSlotwiseFeedGraphs )
-->
<template>
  <el-scrollbar
    class="pm-feed-graphs pm-feed-graphs-scroll"
    viewClass="pm-feed-graphs-scroll-view"
    wrapClass="pm-feed-graphs-scroll-wrap"
  >
    <el-tabs v-model="activeLogsTab">
      <el-tab-pane label="Feed" name="feed">
        <pm-super-admin-slot-wise-feed-graph
          @changedDateQuery="handleDateChangeQuery($event, 'slot_wise_feed')"
        ></pm-super-admin-slot-wise-feed-graph>
        <pm-super-admin-hourly-feed-graph
          @changedDateQuery="handleDateChangeQuery($event, 'hourly_feed')"
        ></pm-super-admin-hourly-feed-graph>
      </el-tab-pane>
      <el-tab-pane label="Sensor Data" name="feed-logs">
        <pm-super-admin-card-header
          @changedDateQuery="handleDateChangeQuery($event, 'feed_logs')"
          :dateQuery="true"
          :timeQuery="false"
          :downloadOption="true"
          v-if="!refreshDates"
          @downloadZIPQuery="handleDownloadZIPQuery($event, 'feed_logs')"
        ></pm-super-admin-card-header>
        <pm-super-admin-battery-voltage-graph></pm-super-admin-battery-voltage-graph>
        <pm-super-admin-dispenser-current-graph></pm-super-admin-dispenser-current-graph>
        <pm-super-admin-dispenser-impedance-graph></pm-super-admin-dispenser-impedance-graph>

        <pm-super-admin-standard-deviation-graph></pm-super-admin-standard-deviation-graph>

        <pm-super-admin-error-code-graph></pm-super-admin-error-code-graph>

        <pm-super-admin-motor-status-graph></pm-super-admin-motor-status-graph>
        <pm-super-admin-hopper-empty-height-graph></pm-super-admin-hopper-empty-height-graph>

        <pm-super-admin-feed-level-sensor-status-graph></pm-super-admin-feed-level-sensor-status-graph>
        <pm-super-admin-doser-current-graph></pm-super-admin-doser-current-graph>
        <pm-super-admin-doser-hall-status-graph></pm-super-admin-doser-hall-status-graph>
      </el-tab-pane>
      <el-tab-pane label="PM ST Logs" name="pm-st-logs">
        <pm-st-logs
          v-loading="loading"
          :pmId="pmId"
          :customer-details="customerDetails"
        ></pm-st-logs>
      </el-tab-pane>
    </el-tabs>
    <el-footer></el-footer>
  </el-scrollbar>
</template>

<script>
import pmSuperAdminHourlyFeedGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminHourlyFeedGraph";
import pmSuperAdminSlotWiseFeedGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminSlotWiseFeedGraph";

import pmSuperAdminCardHeader from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminCardHeader";
import pmSuperAdminBatteryVoltageGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminBatteryVoltageGraph";
import PmSuperAdminDispenserCurrentGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminDispenserCurrentGraph.vue";

import PmSuperAdminStandardDeviationGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminStandardDeviationGraph.vue";

import PmSuperAdminErrorCodeGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminErrorCodeGraph.vue";
import PmSuperAdminMotorStatusGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminMotorStatusGraph.vue";
import PmSuperAdminDispenserImpedanceGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminDispenserImpedanceGraph.vue";
import pmStLogs from "./pmStLogs.vue";

import pmSuperAdminHopperEmptyHeightGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminHopperEmptyHeightGraph.vue";

import pmSuperAdminFeedLevelSensorStatusGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminFeedLevelSensorStatusGraph.vue";

import pmSuperAdminDoserCurrentGraph from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminDoserCurrentGraph.vue";
import PmSuperAdminDoserHallStatusGraph from "./feed-logs/pmSuperAdminDoserHallStatusGraph.vue";

export default {
  props: {
    customerDetails: Object,
    pmId: String,
    loading: Boolean,
  },
  components: {
    pmSuperAdminSlotWiseFeedGraph,
    pmSuperAdminHourlyFeedGraph,
    pmSuperAdminCardHeader,
    pmSuperAdminBatteryVoltageGraph,
    PmSuperAdminDispenserCurrentGraph,
    PmSuperAdminErrorCodeGraph,
    PmSuperAdminMotorStatusGraph,
    PmSuperAdminDispenserImpedanceGraph,
    PmSuperAdminStandardDeviationGraph,
    pmStLogs,
    pmSuperAdminHopperEmptyHeightGraph,
    pmSuperAdminFeedLevelSensorStatusGraph,
    pmSuperAdminDoserCurrentGraph,
    PmSuperAdminDoserHallStatusGraph,
  },
  data() {
    return {
      activeLogsTab: "feed",
      refreshDates: false
    };
  },
  computed: {
    computedComponentName: {
      get() {
        return this.$route.query.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      },
    },
  },
  methods: {
    handleDateChangeQuery(changedDate, parameter) {
      this.$emit("changedDate", changedDate, parameter);
    },
    handleDownloadZIPQuery(changedDate, parameter) {
      this.$emit("downloadZIP", changedDate, parameter);
    },
    async refreshDatePicker() {
      this.refreshDates = true;
      setTimeout(() => {
        this.refreshDates = false;
      });
    },
  },
  watch: {
    "$route.query.pm_id": {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.refreshDatePicker();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.pm-feed-graphs {
  &.pm-feed-graphs-scroll {
    position: relative;
    height: 81vh;
  }
  .pm-feed-graphs-scroll-view {
    padding-right: 10px;
    // display: grid;
    // grid-template-columns: repeat(2, 34em);
  }
  .pm-feed-graphs-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
}
</style>
