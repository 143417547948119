<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: itemListSideBar.vue
Description:This file contains the list of the customers,devices depending on usuage
-->
<template>
  <div
    class="item-list-side-bar"
    v-loading="loading"
    element-loading-background="white"
  >
    <!-- <portal to="outsideAsideScroll"> -->
    <el-input
      v-show="search"
      clearable
      class="search-code-input"
      :placeholder="`Search ${searchInputPlaceHolder(itemType)}`"
      :debounce="500"
      v-model="searchStr"
      @change="handleChange"
      @clear.stop="handleClear"
    ></el-input>
    <!-- </portal> -->
    <el-menu
      :default-active="initItemId"
      class="item-list-menu"
      :collapse-transition="false"
      @select="handleMenuClick"
      :collapse="toggleMenu"
    >
      <!-- v-infinite-scroll="loadItemMethod"
      :infinite-scroll-immediate="false"-->
      <el-scrollbar
        class="item-menu-aside-scroll"
        viewClass="item-menu-aside-scroll-view"
        wrapClass="item-menu-aside-scroll-wrap"
      >
        <el-menu-item
          v-for="(item, index) in itemList"
          class="truncate"
          :key="item._id + index"
          :index="item._id"
          >{{ showPropertyValue(item) }}</el-menu-item
        >
        <el-menu-item v-if="itemList.length === 0" disabled>
          No Data Found
        </el-menu-item>
        <!-- :route="`${device._id}`" -->
        <el-menu-item v-if="!has_more" disabled>
          <strong>End</strong>
        </el-menu-item>
        <Observer @intersect="loadItemMethod" />
      </el-scrollbar>
    </el-menu>
  </div>
</template>

<script>
import Observer from "./Observer";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [errorHandlerMixin],
  components: {
    Observer
  },
  props: {
    itemList: {
      default: () => []
    },
    itemType: {
      default: ""
    },
    initItemId: {
      default: ""
    },
    showProperty: {
      default: ""
    },
    propertySeperator: {
      default: () => (value1, value2) => `${value1} ${value2}`
    },
    totalItemsCount: {
      default: 0
    },
    dataFetchMethod: {
      default: function() {
        return function() {};
      }
    },
    search: {
      default: true
    },
    searchStrQueryParam: {
      default: "user_name"
    }
  },
  data: function() {
    return {
      toggleMenu: false,
      itemId: "",
      page: 2,
      searchStr: "",
      loading: false,
      has_more: true,
      types: {
        pondmother: "PondMother",
        pondmothers: "PondMother",
        pondguards: "PondGuard",
        shrimptalk: "ShrimpTalk",
        gateways: "Gateway",
        customers: "Customer",
        clients: "client",
        dealers: "Dealer",
        accountmanagers: "Account Manager",
        users: "User",
        pondmotherlogs: "pondMothers"
      }
    };
  },
  computed: {
    showPropertyValue() {
      const tempProperty = this.showProperty;
      return (item) => {
        if (Array.isArray(tempProperty)) {
          const wordArr = tempProperty
            .map((property) => {
              return (item[property] || " ").toLowerCase();
            })
            .filter((x) => x !== "");
          if (
            this.$commonUtils.getType(this.propertySeperator) === "function"
          ) {
            return this.propertySeperator(...wordArr);
          }
          return wordArr.join(this.propertySeperator);
        } else {
          return item[tempProperty];
        }
      };
    },
    searchInputPlaceHolder() {
      return (type) => {
        return this.types[type] || this.itemType;
      };
    }
  },
  methods: {
    handleMenuClick(index, indexPath) {
      this.itemId = index;
      this.$emit("itemClicked", index);
    },
    async loadItemMethod() {
      this.loading = true;
      try {
        const totalTemp = this.page * 20;
        // this.page = pageTemp;
        if (totalTemp - this.totalItemsCount < 20) {
          await this.dataFetchMethod({
            query: { page: this.page, limit: 20 },
            infinite_scroll: true
          });
          this.page++;
        } else {
          this.has_more = false;
        }
      } catch (err) {
        console.log("not fetched");
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleChange(string) {
      this.loading = true;
      try {
        // this.dataFetchMethod({ device_code: this.searchStr });
        const query = {};
        if (string === "") {
          query.page = 1;
          query.limit = 30;
          // query.get_all = true;
          if (query[this.searchStrQueryParam]) {
            delete query[this.searchStrQueryParam];
          }
        } else {
          query[this.searchStrQueryParam] = string;
        }
        await this.$emit("loadData", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleClear() {
      this.loading = true;
      try {
        const query = {};
        query.page = 1;
        query.limit = 30;
        // query.get_all = true;
        await this.$emit("cleared", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.search-code-input {
  // position: fixed;
  // top: 45px; // header height value
  // left: 100px; //aside menu margin value
  // z-index: 1;
  width: 190px; // width of the aside menu including padding
  padding: 5px 0px;
  background: white;
  .el-input__inner {
    text-transform: uppercase;
  }
  .el-input__inner::-webkit-input-placeholder {
    text-transform: initial;
  }
  .el-input__inner:-moz-placeholder {
    text-transform: initial;
  }
  .el-input__inner::-moz-placeholder {
    text-transform: initial;
  }
  .el-input__inner:-ms-input-placeholder {
    text-transform: initial;
  }

  .el-input__inner {
    font-size: 12px;
  }
}
.item-list-side-bar {
  .item-list-menu {
    // margin-top: 45px; // height of the input box
    height: 86vh;
    position: relative;
    text-transform: capitalize;
    .item-menu-aside-scroll {
      height: 100%;
    }
    .item-menu-aside-scroll-view {
      padding-right: 10px;
      padding-bottom: 50px;
    }
    .item-menu-aside-scroll-wrap {
      height: 100%;
      overflow-x: auto;
    }
    .el-menu-item {
      height: 35px;
      line-height: 35px;
      font-size: 12px;
    }
    .el-menu-item.is-active {
      background-color: #ecf5ff;
      border-right: 3px solid #2558b7;
    }
  }
  .el-menu-item {
    font-size: 12px;
  }
}
</style>
