<template>
  <div class="dealer-subscriptions-tab" v-loading="loading">
    <layout-dealer-card
      toolbar-layout="search"
      search-property="device_code"
      @loadData="handleSearch"
      v-show="!loading"
    >
      <template v-slot:heading>
        <span class="material-icons-round" style="color: #33a02c">
          card_membership
        </span>
        <p style="margin-left: 10px">
          {{ ftm__capitalize($t("dealer.subscriptions")) }}
        </p>
      </template>
      <template slot="table">
        <er-data-tables
          :tableData="filterTableData"
          :columns="columnsObject"
          :elTableProps="{ height: '100%' }"
          uniqueId="subscription-tab"
          class="subscriptions"
          :actionColumn="false"
          :placeHolderForSearch="$t('dealer.search_device')"
          :addPadingToSearch="true"
          type="card-auto-height-scrollable"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:plan_name_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
          <template v-slot:plan_type_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
          <template v-slot:duration_years_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
          <template v-slot:price_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
          <template v-slot:discount_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
          <template v-slot:final_price_header="{ header }">
            <span>{{ capitalize($t(header.label)) }}</span>
          </template>
        </er-data-tables>
      </template>
    </layout-dealer-card>
  </div>
</template>

<script>
import layoutDealerCard from "@/components/dealer/layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [errorHandlerMixin, filtersMixin],
  components: {
    layoutDealerCard
  },
  data: function () {
    return {
      loading: false,
      tableData: [],
      searchString: ""
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerSubscriptions: "getDealerSubscriptions"
    }),
    columnsObject() {
      return {
        plan_name: {
          prop: "title",
          name: "plan_name",
          label: "dealer.plan_name",
          required: true,
          sortable: true
        },
        plan_type: {
          prop: "type",
          name: "plan_type",
          label: "dealer.plan_type",
          required: true,
          sortable: false
        },
        duration_years: {
          prop: "duration_years",
          name: "duration_years",
          label: "dealer.no_of_years",
          required: true,
          sortable: false
        },
        price: {
          name: "price",
          prop: "price",
          label: "dealer.price",
          required: true,
          sortable: false
        },
        discount: {
          prop: "discount",
          name: "discount",
          label: "dealer.discount",
          required: true
        },
        final_price: {
          prop: "final_price",
          name: "final_price",
          label: "dealer.final_price",
          required: true
        }
      };
    },
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    }
  },
  async mounted() {
    await this.handleLoadData();
  },
  methods: {
    ...mapActions("dealer", {
      fetchAllSubscriptions: "fetchAllSubscriptions"
    }),
    async handleLoadData() {
      try {
        this.loading = true;
        await this.fetchAllSubscriptions();
        this.tableData = this.getDealerSubscriptions;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleSearch(queryObj) {
      this.searchString = queryObj.device_code;
    }
  }
};
</script>

<style lang="scss" scoped>
.dealer-subscriptions-tab {
  height: 100%;
}
</style>
