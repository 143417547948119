<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: Profile.vue
Description: This file has the form that display the dealer profile and allows for editing the dealer details
-->
<template>
  <div>
    <el-form size="mini">
      <el-form-item
        :label="$t('Usrs_first_name')"
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-input
          :value="this.getDealerProfile ? this.getDealerProfile.first_name : ''"
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Usrs_last_name')"
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-input
          :value="this.getDealerProfile ? this.getDealerProfile.last_name : ''"
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Comn_email')"
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-input
          :value="this.getDealerProfile ? this.getDealerProfile.email : ''"
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Comn_country')"
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-input
          :value="
            this.getDealerProfile ? this.getDealerProfile.country.name : ''
          "
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Comn_mobile_num')"
        style="display: flex; align-items: center; justify-content: center"
      >
        <el-input
          :value="this.getDealerProfile ? this.getDealerProfile.phone : ''"
          disabled
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function() {
    return {
      centerDialogVisible: true
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerProfile: "getDealerProfile"
    })
  }
};
</script>

<style></style>
