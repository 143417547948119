import { baseChartConfig } from "@/config/baseChartConfig";

export const shrimpSnapVoltage = {
  ...baseChartConfig,
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      title: {
        text: "ShrimpTalk Voltage (V)",
        style: {
          color: "#000000"
        }
      },
      opposite: false
    }
  ]
};
export const shrimpSnapError = {
  ...baseChartConfig,
  yAxis: [
    {
      // Tertiary yAxis
      title: {
        text: "Error Codes",
        style: {
          color: "#000000"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      opposite: false
    }
  ]
};

export default {
  shrimpSnapVoltage,
  shrimpSnapError
};
