<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgOxygenGraph
Description:This file contains the pondguard oxygen graphs built using highcharts
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Dissolved Oxygen"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { disolveOxygenGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: disolveOxygenGraphConfig,
      oxygenData: []
    };
  },
  computed: {
    getLocaleObj: function () {
      return { locale: this.$i18n.locale };
    }
  },

  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgDisolveOxygenValues");
    this.acm__initParameterName("disolve_oxygen");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Disolve Oxygen", "line");
    },
  }
};
</script>

<style lang="scss"></style>
