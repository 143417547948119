import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default function(component) {
  return {
    mixins: [errorHandlerMixin],
    computed: {
      ...mapGetters("dealer", {
        getDealerProfile: "getDealerProfile"
      })
    },
    methods: {
      ...mapActions("dealer", {
        deleteGatewayFromUser: "deleteGatewayFromUser",
        deletePondMotherFromUser: "deletePondMotherFromUser",
        deleteShrimpTalkFromUser: "deleteShrimpTalkFromUser",
        deletePondGuardFromUser: "deletePondGuardFromUser",
        addGatewayFromUser: "addGateWayToUser",
        addPondMotherFromUser: "addPondMotherToUser",
        addShrimpTalkFromUser: "addShrimpTalkToUser",
        addPondGuardFromUser: "addPondGuardToUser"
      }),
      getStatus(input, device_type) {
        const mapDeviceTypeToStatus = {
          GATEWAY: input => [
            input.status === "ACTIVE" && Boolean(input.user_details),
            input.status === "INACTIVE" || Boolean(input.user_details),
            true
          ],
          POND_GUARD: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ],
          POND_MOTHER: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ],
          SHRIMP_TALK: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ]
        };
        const status = [
          this.ftm__capitalize(this.$t("dealer.active")),
          this.ftm__capitalize(this.$t("dealer.inactive")),
          this.ftm__capitalize(this.$t("dealer.not_assigned"))
        ];
        return status[mapDeviceTypeToStatus[device_type](input).indexOf(true)];
      },
      async handleDeviceDelete(input, deviceType) {
        try {
          const mapDeviceTypeToStatus = {
            GATEWAY: {
              action: this.deleteGatewayFromUser,
              payload_key: "gateway_ids"
            },
            POND_GUARD: {
              action: this.deletePondGuardFromUser,
              payload_key: "pond_guard_ids"
            },
            POND_MOTHER: {
              action: this.deletePondMotherFromUser,
              payload_key: "pond_mother_ids"
            },
            SHRIMP_TALK: {
              action: this.deleteShrimpTalkFromUser,
              payload_key: "shrimp_talk_ids"
            }
          };
          await this.nofm__deleteConfirmation(
            `${input.code}`,
            input.user_details.full_name
          );
          this.loading = true;
          const payload = {
            [mapDeviceTypeToStatus[deviceType].payload_key]: [input._id]
          };
          await mapDeviceTypeToStatus[deviceType].action(payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.ftm__capitalize(
              this.$t("dealer.device_unassigned_from_user")
            ),
            duration: 5000,
            type: "success"
          });
          await this.handleLoadData(this.queryParams);
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      },
      async handleAssignDevicesToUser(selectedDevices, deviceType) {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        const mapDeviceTypeToStatus = {
          GATEWAY: {
            action: this.addGatewayFromUser,
            payload_key: "gateway_ids"
          },
          POND_GUARD: {
            action: this.addPondGuardFromUser,
            payload_key: "pond_guard_ids"
          },
          POND_MOTHER: {
            action: this.addPondMotherFromUser,
            payload_key: "pond_mother_ids"
          },
          SHRIMP_TALK: {
            action: this.addShrimpTalkFromUser,
            payload_key: "shrimp_talk_ids"
          }
        };
        this.loading = true;
        const payload = {
          [mapDeviceTypeToStatus[deviceType].payload_key]: selectedDevices.map(
            x => x._id
          ),
          user_id: this.selectedUsers._id
        };
        try {
          await mapDeviceTypeToStatus[deviceType].action(payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.ftm__capitalize(this.$t("Comn_add_devices_user")),
            duration: 5000,
            type: "success"
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
          switch (component) {
            case "CONTAINER":
              this.dialogVisible = false;
              await this.handleLoadData(this.queryParams);
              break;
            case "DIALOG":
              this.$emit("refresh", true);
          }
        }
      },
      ehm__error422Handler(err) {
        if (
          this.$lodash.get(err, "response.data.error_code") ===
          "MASTER_PONDMOTHER_DELETION_NOT_ALLOWED"
        ) {
          this.ehm__errorFailToSave([
            {
              message: this.$t("dealer.main_pondmother_failed_deletion")
            }
          ]);
          return;
        }
        this.ehm__errorFailToSave({
          message: this.$t("Something_went_wrong")
        });
      }
    }
  };
}
