/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: urlParamsProvideMixin.js
Description: This mixin contains the common functionality for providing the configuration object from the parent component to the child component and add the parameter to the url
*/
import hashSum from "hash-sum";
const urlParamsProvideMixin = {
  provide() {
    return {
      uppm__routeParamsObj: { urlParams: this.uppm__urlParams }
    };
  },
  watch: {
    uppm__urlParams: {
      deep: true,
      handler: function(newValue, oldValue) {
        if (hashSum(this.$route.query) === hashSum(newValue)) return;
        this.$router.replace({
          path: this.$route.path,
          query: newValue
        });
      }
    }
  },
  computed: {
    uppm__getRouteQueryParams: function() {
      return this.$route.query;
    }
  },
  data: function() {
    return {
      uppm__urlParams: { ...this.$route.query }
    };
  },
  methods: {
    uppm__setUrlParams(property, value) {
      this.$set(this.uppm__urlParams, property, value);
    },
    uppm__deleteUrlParams(property) {
      this.$delete(this.uppm__urlParams, property);
    }
  }
};

export default urlParamsProvideMixin;
