<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgSolarVoltageGraph
Description:This file contains the pondguard solar voltage graphs built using highcharts
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Solar Voltage"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { solarVoltageGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: solarVoltageGraphConfig
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgSolarVoltageValues");
    this.acm__initParameterName("solar_voltage");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Solar Voltage", "line");
    }
  }
};
</script>

<style lang="scss"></style>
