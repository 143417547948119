<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgTemperatureGraph
Description:This file contains the pondguard temperature graphs built using highcharts
-->
<template>
  <div>
    <!-- {{$store.getters['superadmin/getPgTempValues']}}s -->
    <chart-in-card
      :chartOptions="chartOptions"
      chartTitle="Temperature"
      :downloadOption="true"
      :updateChart="acm__updateChart"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import { temperatureGraphConfig } from "./chartOptions";
import dateUtils from "@/utils/dateUtils";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import XLSX from "xlsx";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin, userPreferenceMixin],
  props: ["deviceDetails"],
  data: function () {
    return {
      chartOptions: temperatureGraphConfig,
      temperatureData: []
    };
  },
  components: {
    chartInCard
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgTempValues");
    this.acm__initParameterName("temperature");
    this.acm__initComponent();
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    getLocaleObj: function () {
      return { locale: this.$i18n.locale };
    }
  },
  methods: {
    acm__initComponent() {
      // console.log(this.getStoreData);
      this.chartOptions.title.text =
        this.$store.getters["superadmin/getSensorData"].pond_guard_code;
      this.acm__initChartSeries("Temperature", "line");
    },
    formatDate: function (date) {
      try {
        return dateUtils.getFormatByTimeZoneOnUTC(
          date,
          "dd MMM,yy,H:m",
          "UTC",
          this.getLocaleObj
        );
      } catch (err) {
        return "NA";
      }
    },
    handlePgTempDownload() {
      this.temperatureData =
        this.$store.getters["superadmin/getSensorData"].temperature;

      const payload = [];
      for (const {
        pond_guard_code,
        data: { temperature, date }
      } of this.temperatureData) {
        payload.push({
          "Device Code": pond_guard_code,
          Temperature: temperature,
          Date: this.formatDate(date)
        });
      }
      const xlsxFilterByDate = payload.sort((a, b) =>
        dateUtils.dateComparator(a.Date, b.Date, true)
      );

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlsxFilterByDate);
      XLSX.utils.book_append_sheet(wb, ws);

      const exportFileName =
        "Oxygen" + `(${this.temperatureData[0].pond_guard_code}).csv`;

      XLSX.writeFile(wb, exportFileName, {
        type: "binary",
        cellDates: true
      });
    }
  }
};
</script>

<style lang="scss"></style>
