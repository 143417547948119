<template>
  <el-card>
    <div slot="header">
      <el-row type="flex">
        <el-col>
          <h3>ShrimpTalk - Signature</h3>
        </el-col>
      </el-row>
    </div>
    <high-charts
      :key="acm__updateChart"
      :options="chartOptions"
      constructor-type="stockChart"
    ></high-charts>
  </el-card>
</template>

  <script>
  import adminChartsMixin from "@/mixins/adminChartsMixin";
  import { rawDataSignatureGraphConfig } from "./chartOptions";
  export default {
    mixins: [adminChartsMixin],
    data: function () {
      return {
        chartOptions: rawDataSignatureGraphConfig,
      };
    },
    mounted() {
      this.acm__initStoreDataGetterPath("superadmin/getSTRawDataSignature");
      this.acm__initParameterName("st_signature_data");
      this.acm__initComponent();
    },
    methods: {
      acm__initComponent() {
        this.acm__initChartSeries("Signature", "line");
      },
    },
  };
  </script>
