<template>
  <div class="dealer-subscriptions-tab" v-loading="loading">
    <layout-dealer-card
      toolbar-layout="year_filter,download_btn"
      search-property="year"
      @loadData="handleSearch"
      @onDownloadData="downloadTableData"
      v-show="!loading"
    >
      <template v-slot:heading>
        <span class="material-icons-round" style="color: #33a02c">
          card_membership
        </span>
        <p style="margin-left: 10px">
          {{ ftm__capitalize($t("dealer.subscriptions")) }}
        </p>
      </template>

      <template slot="table">
        <el-table
          :data="tableData.data"
          :summary-method="getSummaries"
          show-summary
          style="width: 100%; height: calc(100vh - 240px)"
          ref="yearSummaryTable"
          :cell-class-name="getCellClass"
        >
          <el-table-column
            v-for="(col, index) of columns"
            :prop="col.prop"
            :label="col.label"
            :min-width="col.width"
            :key="index"
          >
            <template slot-scope="scope">
              <span v-if="col.prop == 'customer'" class="customer-name">
                <router-link
                  v-if="col.action && col.action.type == 'route-change'"
                  :to="{
                    path: col.action.toRoute,
                    query: {
                      ...$route.query,
                      customer: scope.row[col.prop]._id
                    }
                  }"
                >
                  <span class="name"> {{ scope.row[col.prop].name }}</span
                  ><i class="el-icon-info icon"></i>
                  <span class="email"> {{ scope.row[col.prop].email }}</span>
                </router-link>
              </span>

              <span v-else>
                {{ scope.row[col.prop] || "-" }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="table-legends">
        <div class="table-legends">
          <div class="label">Legends:</div>
          <div class="actuals">
            <span class="icon"></span>
            <span>Actuals </span>
          </div>
          <div class="projections">
            <span class="icon"></span>
            <span>Projections </span>
          </div>
        </div>
      </template>
    </layout-dealer-card>
  </div>
</template>

<script>
import layoutDealerCard from "@/components/dealer/layoutDealerCard";
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import moment from "moment";

export default {
  mixins: [errorHandlerMixin, filtersMixin],
  components: {
    layoutDealerCard
  },
  data: function() {
    return {
      loading: false,
      columns: [
        {
          label: "Customer",
          prop: "customer",
          sort: true,
          width: "180",
          fixed: "left",
          action: {
            type: "route-change",
            toRoute: "/dealer/subscriptions/customer-wise"
          }
        },
        {
          label: "Jan",
          prop: "jan",
          data_type: "price",
          currency: "$",
          sort: false
        },
        {
          label: "Feb",
          prop: "feb",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Mar",
          prop: "mar",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Apr",
          prop: "april",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "May",
          prop: "may",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Jun",
          prop: "june",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Jul",
          prop: "july",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Aug",
          prop: "agust",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Sep",
          prop: "sep",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Nov",
          prop: "november",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Dec",
          prop: "december",
          data_type: "price",
          currency: "$",
          sort: true
        },
        {
          label: "Total",
          prop: "total",
          data_type: "price",
          currency: "$",
          sort: true
        }
      ],
      tableData: {
        year: 2022,
        data: []
      },
      searchString: "",
      allCustomers: [],
      start_date: "2021-01-01",
      end_date: "2021-12-31",
      sums: []
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerSubscriptionReceivables: "getDealerSubscriptionReceivables",
      getDealerUsersDevices: "getDealerUsersDevices"
    }),

    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.title.toLowerCase().includes(this.searchString);
      });
    }
  },
  async mounted() {
    this.tableData.year = new Date().getFullYear();
    await this.handleLoadData();
  },
  methods: {
    ...mapActions("dealer", {
      fetchAllSubscriptionReceivables: "fetchAllSubscriptionReceivables",
      fetchDealerUsersAndDevices: "fetchDealerUsersAndDevices"
    }),
    async handleLoadData() {
      try {
        // we have to set coloumns status

        this.loading = true;
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        for (let i = 1; i < 12; i++) {
          const monthIndex = i - 1;
          this.columns[i].is_month = true;
          if (this.tableData.year > currentYear) {
            this.columns[i].value_type = "projections";
          } else if (
            this.tableData.year === currentYear &&
            monthIndex > currentMonth
          ) {
            this.columns[i].value_type = "projections";
          } else {
            this.columns[i].value_type = "actuals";
          }
        }

        await this.fetchDealerUsersAndDevices({
          params: { get_all: true },
          infinite_scroll: false
        });
        this.allCustomers = this.getDealerUsersDevices;

        await this.fetchAllSubscriptionReceivables({
          order_by: "first_name",
          order_type: "asc",
          start_date: this.start_date,
          end_date: this.end_date,
          limit: 30,
          page: 1,
          include_device_counts: true
        });
        this.tableData.data = this.updateTableData(
          this.getDealerSubscriptionReceivables
        );
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleSearch(queryObj) {
      if (queryObj.year) {
        this.tableData.year = new Date(queryObj.year).getFullYear();
        this.start_date = moment(queryObj.year)
          .startOf("year")
          .format("YYYY-MM-DD");
        this.end_date = moment(queryObj.year)
          .endOf("year")
          .format("YYYY-MM-DD");
        await this.handleLoadData();
      }
    },
    getSummaries(param) {
      const { columns } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total Receivables";
          return;
        }
        const values = this.tableData.data.map((item) => {
          const value = item[column.property].replace("$", "");
          return Number(value);
        });
        if (!values.every((value) => isNaN(value))) {
          sums[index] =
            "$ " +
            values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
        } else {
          sums[index] = "-";
        }
      });

      this.sums = sums;
      return sums;
    },

    downloadTableData(data) {
      let csv = this.columns.map((e) => e.label).join(",") + "\n";

      // merge the data with CSV

      this.tableData.data.forEach((row) => {
        let rowdata = "";
        this.columns.forEach((e) => {
          if (row[e.prop]) {
            rowdata =
              rowdata +
              (e.prop === "customer" ? row[e.prop].name : row[e.prop]) +
              ",";
          } else {
            rowdata = rowdata + "" + ",";
          }
        });
        rowdata = rowdata + "\n";

        csv += rowdata;
      });

      csv += this.sums.join(",") + "\n";

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      // provide the name for the CSV file to be downloaded
      hiddenElement.download =
        "ST_Total_Receivables_" +
        this.start_date +
        "_" +
        this.end_date +
        ".csv";
      hiddenElement.click();
    },

    getCellClass({ row, column, rowIndex, columnIndex }) {
      const selectedColumn = this.columns[columnIndex];
      console.log({ selectedColumn });
      return selectedColumn.value_type || "";
    },

    updateTableData(data) {
      const tableData = [];
      const monthMap = {
        "01": "jan",
        "02": "feb",
        "03": "mar",
        "04": "april",
        "05": "may",
        "06": "june",
        "07": "july",
        "08": "agust",
        "09": "sep",
        10: "october",
        11: "november",
        12: "december"
      };
      data.forEach((customerData) => {
        const user = this.allCustomers.find(
          (c) => c._id === customerData.user_id
        );
        const obj = {
          customer: {
            name: user.first_name + " " + user.last_name,
            email: user.email,
            _id: customerData.user_id
          },
          total: "$" + customerData.total_amount
        };
        customerData.months.forEach((e) => {
          obj[monthMap[e.month]] = "$" + e.amount;
        });

        tableData.push(obj);
      });

      return tableData;
    }
  }
};
</script>

<style lang="scss">
// This is updated file
.dealer-subscriptions-tab {
  height: 100%;
  .el-table--enable-row-hover {
    .el-table__body {
      tr {
        td.actuals {
          background-color: #ebffe2;
        }
        td.projections {
          background-color: #ebebff;
        }
        &:hover > td.el-table__cell {
          background-color: #fafafa;
        }
        &:hover > td.actuals {
          background-color: #dffad2;
        }
        &:hover > td.projections {
          background-color: #dfdffd;
        }
      }
    }
  }
  .el-table__body-wrapper {
    height: inherit;
    overflow: auto;
  }
  .customer-name {
    text-decoration: none;
    .name {
      display: inline-block;
      color: #333333;
      margin-right: 10px;
    }
    .icon {
      color: #333333;
    }
    .email {
      text-decoration: none;
      color: #233a73;
      font-size: 12px;
      display: none;
    }
  }
  .text-navyblue {
    color: #233a73;
  }
  .el-date-editor {
    .el-input__icon {
      line-height: 32px;
    }
    &.el-input {
      max-width: 100%;
    }
  }
}
.table-legends {
  display: flex;
  gap: 10px;
  padding: 8px 10px;
  justify-content: flex-end;
  .icon {
    display: inline-block;
    height: 10px;
    width: 30px;
  }
  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  .projections {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #e0e0fb;
      border: 1px solid #e0e0fb;
      border-radius: 2px;
    }
  }
  .actuals {
    background-color: #ffffff;
    display: flex;
    gap: 5px;
    padding: 2px 4px 2px 2px;
    align-items: center;
    border: 1px solid #efefef;
    .icon {
      background: #d8fdc8;
      border: 1px solid #d8fdc8;
      border-radius: 2px;
    }
  }
}
</style>
