/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: gateway.js
Description: This file has the model,functions  of gateway object used in the pondlogs customer site
*/
class Gateway {
  constructor() {
    this._id = "";
    this.gateway_id = "";
    this.title = "";
    this.code = "";
    this.pond_id = "";
    this.status = "";
    this.user_id = "";
    this.created_at = "";
    this.updated_at = "";
    this.locations = [];
    this.settings = {
      settings_interval: "number",
      lora_channel: "",
      wifi_networks: [],
      wifi_passwords: [],
      wifi_security: [],
      country: "",
      mac_address: "",
      os_version: "",
      software_version: "",
      hardware_version: "",
      ip_interface: ""
    };
  }
}
export default Gateway;
