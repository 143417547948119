<template>
  <el-card>
    <div slot="header">
      <el-row type="flex">
        <el-col>
          <h3>ShrimpTalk - Energy</h3>
        </el-col>
      </el-row>
    </div>
    <high-charts
      :key="acm__updateChart"
      :options="chartOptions"
      constructor-type="stockChart"
    ></high-charts>
  </el-card>
</template>

<script>
import adminChartsMixin from "@/mixins/adminChartsMixin";
import { rawDataGraphConfig } from "./chartOptions";
export default {
  mixins: [adminChartsMixin],
  data: function () {
    return {
      chartOptions: rawDataGraphConfig,
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getSTRawData");
    this.acm__initParameterName("sound_intensity");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Sound Intensity", "line");
    },
  },
};
</script>
