<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkFeed.vue
Description: This file contains UI components of Shrimp Talk Feed used in super admin page and includes shrimp talk details component
-->
<template>
  <el-container class="shrimp-talk-logs" v-loading="loading">
    <el-aside>
      <portal-target name="outsideAsideScroll"></portal-target>
      <item-list-side-bar
        :item-list="getUsers"
        :init-item-id="getInitUserId"
        item-type="users"
        class="side-list-menu"
        :showProperty="['first_name', 'last_name']"
        :search="true"
        search-property="user_name"
        :totalItemsCount="totalItemsCount"
        :dataFetchMethod="UserData"
        @loadData="UserData"
        @cleared="UserData"
        @itemClicked="handleUserSelection"
      ></item-list-side-bar>
      <!-- :dataFetchMethod="loadShrimpTalkData" -->
    </el-aside>
    <el-main>
      <shrimpTalkDetails :selectedUser="particularUser" />
    </el-main>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shrimpTalkDetails from "@/components/superadmin/shrimp-talk/shrimpTalkFeedDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import urlParamsProvideMixin from "@/mixins/urlParamsProvideMixin";

export default {
  name: "shrimpTalkFeed",
  mixins: [errorHandlerMixin, urlParamsProvideMixin],
  props: ["shrimp_talk_id"],
  components: {
    itemListSideBar,
    shrimpTalkDetails
  },
  data: function () {
    return {
      loading: false,
      totalItemsCount: 100,
      emptyStr: "--",
      particularUser: {}
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getShrimpTalks: "getShrimpTalks",
      getUsersObj: "getUsersObj",
      getUsers: "getUsers"
    }),
    getListOfUsers() {
      return this.getUsers;
    },
    getInitUser() {
      const user = this.getListOfUsers[0];
      return user || {};
    },
    getInitUserId() {
      return this.uppm__setUrlParams.user_id || this.getInitUser._id;
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllUsers: "fetchAllUsers",
      fetchAUserDetails: "fetchAUserDetails"
    }),
    async UserData({ query, infinite_scroll = false }) {
      try {
        this.loading = true;
        const response = await this.fetchAllUsers({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;

        // const firstUser = this.getUsers[0]._id;
        // this.urlParams.user_id = firstUser;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleUserSelection(user_id) {
      try {
        this.loading = true;
        const response = await this.fetchAUserDetails({
          user_id: user_id
        });
        this.particularUser = response;
        this.uppm__setUrlParams("user_id", this.particularUser._id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
        const userresponse = await this.fetchAUserDetails({
          user_id: this.getInitUserId
        });
        this.particularUser = userresponse;
        if (!this.getUsersObj[this.urlParams.user_id]) {
          this.uppm__setUrlParams("user_id", this.particularUser._id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss">
.shrimp-talk-logs {
  .item-list-menu {
    height: 82vh;
  }
  .side-list-menu {
    width: 200px;
  }
}
</style>
