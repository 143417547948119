<template>
  <el-row class="release-notes-item-main-container">
    <el-collapse v-model="activeReleaseItem" accordion>
      <el-collapse-item
        v-for="feature in release.features"
        :key="feature._id"
        :name="feature._id"
      >
        <template slot="title" class="custom-title">
          <div class="list-heading" :key="feature.id">
            <div>
              <img
                src="@/assets/help/New Feature.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
            <div>
              <span class="count"> {{ feature.title }}</span>
            </div>
          </div>
        </template>
        <template>
          <li class="content-item" :key="feature.id">
            <div
              v-for="(content, index) in feature.content"
              :name="index"
              :key="index"
            >
              <div class="title" v-if="content.type === 'text'">
                {{ content.value }}
              </div>
              <div class="link" v-if="content.type === 'link'">
                <el-link :href="content.link" target="_blank"
                  >View More</el-link
                >
              </div>
              <div class="image" v-if="content.type === 'image'">
                <el-image
                  style="width: auto; height: 400px"
                  :src="content.link"
                  fit="fill"
                ></el-image>
              </div>
              <div class="video" v-if="content.type === 'video'">
                <video-embed
                  style="width: 100%; height: 400px;"
                  :src="content.link"
                ></video-embed>
              </div>
            </div>
          </li>
        </template>
      </el-collapse-item>
      <el-collapse-item
        v-for="enhancement in release.enhancements"
        :name="enhancement._id"
        :key="enhancement._id"
      >
        <template slot="title" class="custom-title">
          <div class="list-heading" :key="enhancement._id">
            <div>
              <img
                src="@/assets/help/Enhancement.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
            <div>
              <span class="count">{{ enhancement.title }}</span>
            </div>
          </div>
        </template>
        <template>
          <li class="content-item" :key="enhancement._id">
            <div
              v-for="(content, index) in enhancement.content"
              :name="index"
              :key="index"
            >
              <div class="title" v-if="content.type === 'text'">
                {{ content.value }}
              </div>
              <div class="link" v-if="content.type === 'link'">
                <el-link :href="content.link" target="_blank"
                  >View More</el-link
                >
              </div>
              <div class="image" v-if="content.type === 'image'">
                <el-image
                  style="width: auto; height: 400px"
                  :src="content.link"
                  fit="fill"
                ></el-image>
              </div>
              <div class="video" v-if="content.type === 'video'">
                <video-embed
                  :src="content.link"
                  style="width: 100%; height: 400px;"
                ></video-embed>
              </div>
            </div>
          </li>
        </template>
      </el-collapse-item>
      <el-collapse-item
        v-for="bug in release.bugs"
        :key="bug._id"
        :name="bug._id"
      >
        <template slot="title" class="custom-title">
          <div class="list-heading" :key="bug._id">
            <div>
              <img
                src="@/assets/help/Bug.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
            <div>
              <span class="count">{{ bug.title }}</span>
            </div>
          </div>
        </template>
        <template>
          <li class="content-item" :key="bug._id">
            <div
              v-for="(content, index) in bug.content"
              :name="index"
              :key="index"
            >
              <div class="title" v-if="content.type === 'text'">
                {{ content.value }}
              </div>
              <div class="link" v-if="content.type === 'link'">
                <el-link :href="content.link" target="_blank"
                  >View More</el-link
                >
              </div>
              <div class="image" v-if="content.type === 'image'">
                <el-image
                  style="width: auto; height: 400px"
                  :src="content.link"
                  fit="fill"
                ></el-image>
              </div>
              <div class="video" v-if="content.type === 'video'">
                <video-embed
                  :src="content.link"
                  style="width: 100%; height: 400px;"
                ></video-embed>
              </div>
            </div>
          </li>
        </template>
      </el-collapse-item>
    </el-collapse>
  </el-row>
</template>

<script>
import eventBus from "@/components/shared/eventBus";
export default {
  props: ["release"],
  data: function() {
    return {
      activeReleaseItem: ""
    };
  },
  created() {
    eventBus.$on("adminCollapseItemClicked", () => {
      this.activeReleaseItem = "";
    });
  }
};
</script>

<style lang="scss">
.release-notes-item-main-container {
  $text-color-with-dark-bg: #0a2463;
  .el-collapse-item__header {
    width: 120px;
    border-bottom: none !important;
  }
  .el-collapse-item__header .is-active {
    border-bottom: none;
  }
  .el-collapse-item__arrow {
    margin: 0 0 4px 0 !important;
  }
  .list-heading {
    color: $text-color-with-dark-bg;
    font-weight: 800;
    display: flex;
  }
  .content-item {
    color: rgb(51, 51, 51);
    list-style: none;
    font-weight: 400;
    margin-left: 32px;
    // img {
    //   width: 90%;
    //   height: 550px;
    // }

    .image {
      margin-top: 24px;
    }
    .video {
      margin-top: 16px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .link {
      margin-top: 12px;
      a {
        width: max-content;
        text-decoration: none;
        color: #409eff;
        border-bottom: 1px solid #409eff;
      }
      a::after {
        text-decoration: none;
        border: none;
      }
    }
  }
}
</style>
