<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: singleDeviceSettingsTable.vue
Description: This table component is used in dealer site for displaying the devices allocated to the table, and their customers
-->
<template>
  <div class="single-device-settings-table">
    <!-- this.settings.battery_voltage -->
    <center v-if="settings.battery_voltage">
      <el-table
        :data="[settings]"
        size="mini"
        v-loading="saveSettingsLoading"
        element-loading-background="white"
      >
        <el-table-column
          v-for="(key, index) in keys
            ? Object.keys(keys)
            : Object.keys(settings || {})"
          :key="getKey(key, index)"
          :prop="key"
          min-width="130px"
          :label="
            labelKeys && labelKeys[key]
              ? labelKeys[key]
              : key.replace(/_|\./g, ' ')
          "
        >
        </el-table-column>
      </el-table>
    </center>
    <center v-else>{{ $t("Comn_no_data") }}</center>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
// import dateUtils from '../../../utils/dateUtils';
// import dateUtils from "@/utils/dateUtils";

export default {
  props: [
    "settingsDetails",
    "keys",
    "editableKeys",
    "settingsType",
    "editable",
    "device_code",
    "labelKeys",
    "displayUnits",
    "customerDetails"
  ],
  data: function () {
    return {
      // settings: this.$lodash.cloneDeep(this.settingsDetails)
      settings: {},
      isDisabled: true,
      loading: false,
      saveSettingsLoading: false
    };
  },
   mixins: [adminDatesHandlerMixin, filtersMixin],
  mounted () {
    this.loading = true;
    this.settings = this.$lodash.cloneDeep(this.settingsDetails) || {};
    const IstTimeZone = 'Asia/Kolkata';
    if (!this.settings.utc_date) {
      return;
    }
        this.settings.date_IST = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      this.settings.utc_date,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );
                  // console.log(dateUtils.parseISO(this.settings.utc_date, ''));
    // this.settings.date = this.ftm__formatEmpty(
    //                 this.adm__formatByTZOnDate(
    //                   new Date(this.settings.date),
    //                   "dd-MMM-yy HH:mm",
    //                  this.customerDetails.timezone.name
    //                 )
    //               );
    this.settings.date = this.ftm__formatEmpty(this.adm__filterToFormatDate(this.adm__formatUTCISOToUTCDateObj(this.settings.date), "dd-MMM-yy HH:mm"));
    this.settings.UTC_date = this.ftm__formatEmpty(this.adm__filterToFormatDate(this.adm__formatUTCISOToUTCDateObj(this.settings.utc_date), "dd-MMM-yy HH:mm"));
    console.log(this.settings);
    this.loading = false;
  },
  watch: {
    settingsDetails () {
      this.settings = this.$lodash.cloneDeep(this.settingsDetails) || {};
    }
  },
  computed: {
    getKey: function () {
      return (key, index) => {
        return `${key}_${index}_${this.settingsType || "SENSOR"}`;
      };
    },
    getKeyNew: function () {
      return (key, index) => {
        return `key_${key}_${index}_${this.settingsType || "SENSOR"}`;
      };
    }
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    validate (key) {
      return this.keys[key];
    },
    getValue (key) {
      return typeof this.$lodash.result(this.settings, key) === "undefined"
        ? "--"
        : this.$lodash.result(this.settings, key);
    },
    getType (value) {
      switch (typeof value) {
        case "number":
          return "number";
        case "string":
          return "string";
        case "boolean":
          return "boolean";
        case "object":
          return Array.isArray(value)
            ? "array"
            : value != null
            ? "object"
            : "null";
        default:
          return "undefined";
      }
    },
    async saveSettings () {
      this.loading = true;
      this.$confirm("Are you sure to save settings?", "Confirmation", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(async () => {
        await this.saveSettingsInDb();
      });
      this.loading = false;
    },
    async saveSettingsInDb () {
      this.loading = true;
      const payload = {};
      const keys = Object.keys(this.keys);
      keys.forEach((key) => {
        if (this.editableKeys[key]) {
          payload[key] = this.settings[key];
        }
      });
      const response = await this.saveSingleDeviceSettingsData({
        device_code: this.device_code,
        userId: this.userId,
        payload,
        settingsType: this.settingsType
      });
      this.loading = false;
      if (response.status <= 300) {
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
      } else {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
      }
    }
  }
};
</script>

<style lang="scss">
.single-device-settings-table {
  width: 100%;
  .el-form {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px 200px;
  }
  .el-table th .cell {
    word-break: break-word;
    text-transform: capitalize;
  }
}
</style>
