<template>
  <el-form inline size="mini" class="filter-form" :show-message="false">
    <!-- customer -->
    <el-form-item>
      <el-input
        size="mini"
        placeholder="Customer Name"
        class="text-input-customer-name"
        :value="getUserName"
      ></el-input>
    </el-form-item>
    <!-- Location -->
    <el-form-item>
      <er-select-with-options
        class="select-location"
        :value="getSelectedLocation"
        :options="getAllLocationsofUser"
        value-type="object"
        value-key="_id"
        label-key="name"
        size="mini"
        placeholder="Select Location"
        @input="handleLocationChange"
      >
      </er-select-with-options>
    </el-form-item>
    <!-- Pond -->
    <el-form-item>
      <er-select
        class="select-pond"
        v-model="selectedPondInDropdown"
        :filter-method="handlePondSearch"
        size="mini"
        :clearable="true"
        placeholder="Select Pond"
        value-key="title"
        filterable
        @input="handlePondChange"
      >
        <el-option
          v-for="pond in getAllPondsInSelectedLocation"
          :value="pond"
          :label="pond.title"
          :key="pond._id"
        ></el-option>
        <!-- <el-option-group
            :key="pond._id"
            :label="pond.title"
          >
            <el-option
              v-for="eachDevice in eachDeviceGroup.options"
              :key="eachDevice._id"
              :value="eachDevice._id"
              :label="eachDevice.code"
            >
            </el-option>
          </el-option-group> -->
        <el-option
          disabled
          v-if="this.getAllPondsInSelectedLocation.length === 0"
          :value="0"
        >
          No Data
        </el-option>
      </er-select>

      <!-- <er-select-with-options
        class="select-pond"
        :value="getSelectedPondComputed"
        :options="getAllPondsInSelectedLocationComputed"
        :showSelectAll="true"
        value-type="string"
        size="mini"
        placeholder="Select Pond"
        multiple
        reserve-keyword
        collapse-tags
        @input="handlePondChange"
      >
      </er-select-with-options> -->
      <!-- <er-select
        class="select-device-id"
        :value="getSelectedDevices"
        size="mini"
        multiple
        filterable
        collapse-tags
        :show-select-all="true"
        placeholder="Select Devices"
        :filter-method="handleDeviceSearch"
        @input="handleDevicesChanges"
        ><template v-for="eachDeviceGroup in getDevicesGroup">
          <el-option-group
            :key="eachDeviceGroup.group_label"
            :label="eachDeviceGroup.group_label"
            v-if="hasDeviceType(eachDeviceGroup.group_key)"
          >
            <el-option
              v-for="eachDevice in eachDeviceGroup.options"
              :key="eachDevice._id"
              :value="eachDevice._id"
              :label="eachDevice.code"
            >
            </el-option>
          </el-option-group>
        </template>
        <el-option
          disabled
          v-if="this.getSelectedDeviceTypes.length === 0"
          :value="0"
        >
          No Data
        </el-option>
      </er-select> -->
    </el-form-item>
    <!-- Device Type -->
    <el-form-item>
      <er-select-with-options
        class="select-device-type"
        :value="getSelectedDeviceTypes"
        :options="getDeviceTypes"
        :showSelectAll="true"
        value-type="string"
        size="mini"
        placeholder="Select Device Type"
        multiple
        reserve-keyword
        collapse-tags
        @input="handleDeviceTypeChange"
      >
      </er-select-with-options>
    </el-form-item>
    <!-- Device Code -->
    <el-form-item>
      <er-select
        class="select-device-id"
        :value="getSelectedDevices"
        size="mini"
        multiple
        filterable
        collapse-tags
        :show-select-all="true"
        placeholder="Select Devices"
        :filter-method="handleDeviceSearch"
        @visible-change="handleDevicesChangesBlur"
        @input="handleDevicesChanges"
        ><template v-for="eachDeviceGroup in getDevicesGroup">
          <el-option-group
            :key="eachDeviceGroup.group_label"
            :label="eachDeviceGroup.group_label"
            v-if="hasDeviceType(eachDeviceGroup.group_key)"
          >
            <el-option
              v-for="eachDevice in eachDeviceGroup.options"
              :key="eachDevice._id"
              :value="eachDevice._id"
              :label="eachDevice.code"
            >
            </el-option>
          </el-option-group>
        </template>
        <el-option
          disabled
          v-if="this.getSelectedDeviceTypes.length === 0"
          :value="0"
        >
          No Data
        </el-option>
      </er-select>
    </el-form-item>
    <!-- Log Type -->
    <el-form-item>
      <er-select-with-options
        class="select-log-type"
        :value="getSelectedLogTypes"
        :options="getLogTypes"
        :showSelectAll="false"
        value-type="object"
        value-key="value"
        label-key="label"
        size="mini"
        placeholder="Select Log type"
        @input="handleLogTypeChange"
      >
      </er-select-with-options>
    </el-form-item>
    <!-- Event Type -->
    <el-form-item>
      <er-select-with-options
        class="select-event-type"
        :value="getSelectedEventTypes"
        :options="getEventTypes"
        :showSelectAll="true"
        value-type="string"
        size="mini"
        placeholder="Select Event type"
        multiple
        reserve-keyword
        collapse-tags
        @input="handleEventTypeChange"
      >
      </er-select-with-options>
    </el-form-item>
    <!-- Date Range -->
    <el-form-item>
      <el-date-picker
        :value="selectedDateRange"
        :picker-options="pickerOptions"
        type="datetimerange"
        size="mini"
        format="dd-MMM-yy HH:mm"
        value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :clearable="false"
        @input="handleDateRangeChange"
      ></el-date-picker>
    </el-form-item>
    <!-- Submit -->
    <el-form-item>
      <el-button size="mini" @click="handleSubmit">Submit</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import urlParamsUpdateMixin from "@/mixins/urlParamsUpdateMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [urlParamsUpdateMixin, adminDatesHandlerMixin, errorHandlerMixin],
  data() {
    return {
      selectedPondInDropdown: ""
    };
  },
  computed: {
    ...mapGetters("superadmin/activityLog", {
      getTableVisibleStatus: "getTableVisibleStatus",
      selectedDateRange: "getSelectedDateRange",
      getAllLocationsofUser: "getAllLocationsofUser",
      getAllPondsInSelectedLocation: "getAllPondsInSelectedLocation",
      getSelectedLocation: "getSelectedLocation",
      getSelectedDeviceTypes: "getSelectedDeviceTypes",
      getSelectedLogTypes: "getSelectedLogTypes",
      getSelectedPond: "getSelectedPond",
      getSelectedUser: "getSelectedUser",
      getSelectedDevices: "getSelectedDevices",
      getSelectedEventTypes: "getSelectedEventTypes",
      getUserPondmothersBySelectedPond: "getUserPondmothersBySelectedPond",
      getUserShrimptalksBySelectedPond: "getUserShrimptalksBySelectedPond",
      getUserPondguardsBySelectedPond: "getUserPondguardsBySelectedPond",
      getUserGatewaysBySelectedPond: "getUserGatewaysBySelectedPond"
    }),
    getUserName() {
      return this.getSelectedUser.full_name;
    },
    getSelectedPondComputed() {
    console.log("this.getSelectedPond", this.getSelectedPond);
    return this.getSelectedPond;
    },
    getAllPondsInSelectedLocationComputed() {
      console.log(
        "this.getAllPondsInSelectedLocation",
        this.getAllPondsInSelectedLocation
      );
      return this.getAllPondsInSelectedLocation.reduce((acc, curObj) => {
        curObj.value = curObj._id;
        curObj.label = curObj.title;
        acc.push(curObj);
        return acc;
      }, []);
    },
    getDeviceTypes() {
      return [
        {
          value: "SHRIMP_TALK",
          label: "Shrimptalk",
          store_getter: "getUserShrimptalksBySelectedPond"
        },
        {
          value: "GATEWAY",
          label: "Gateway",
          store_getter: "getUserGatewaysBySelectedPond"
        },
        {
          value: "POND_GUARD",
          label: "PondGuard",
          store_getter: "getUserPondguardsBySelectedPond"
        },
        {
          value: "POND_MOTHER",
          label: "PondMother",
          store_getter: "getUserPondmothersBySelectedPond"
        }
      ];
    },
    getDevicesGroup() {
      return this.getDeviceTypes.reduce((acc, eachDeviceType) => {
        if (this[eachDeviceType.store_getter].length === 0) return acc;
        acc.push({
          group_label: eachDeviceType.label,
          group_key: eachDeviceType.value,
          options: this[eachDeviceType.store_getter]
        });
        return acc;
      }, []);
    },
    getLogTypes() {
      return [
        {
          value: {
            bk_key: ["SETTINGS_POND_GUARD"],
            ft_key: "PG_SETTINGS"
          },
          label: "Pg Settings",
          visible: this.isIntersect(["POND_GUARD"], this.getSelectedDeviceTypes)
        },
        {
          value: {
            bk_key: ["SETTINGS_GATEWAY"],
            ft_key: "GT_SETTINGS"
          },
          label: "GT Settings",
          visible: this.isIntersect(["GATEWAY"], this.getSelectedDeviceTypes)
        },
        {
          value: {
            bk_key: ["SETTINGS_POND_MOTHER"],
            ft_key: "PM_SETTINGS"
          },
          label: "Pm Settings",
          visible: this.isIntersect(
            ["POND_MOTHER"],
            this.getSelectedDeviceTypes
          )
        },
        {
          value: {
            bk_key: ["SETTINGS_SHRIMP_TALK"],
            ft_key: "ST_SETTINGS"
          },
          label: "ST Settings",
          visible: this.isIntersect(
            ["SHRIMP_TALK"],
            this.getSelectedDeviceTypes
          )
        },
        // {
        //   value: {
        //     bk_key: ["POND_AND_DEVICE_POND_MOTHER", "USER_AND_DEVICE_POND_MOTHER"],
        //     ft_key: "POND_PM_MAP"
        //   },
        //   label: "Pond~PondMother",
        //   visible: this.isIntersect(
        //     ["POND_MOTHER"],
        //     this.getSelectedDeviceTypes
        //   )
        // },
        // {
        //   value: {
        //     bk_key: ["POND_AND_DEVICE_POND_GUARD", "USER_AND_DEVICE_POND_GUARD"],
        //     ft_key: "POND_PG_MAP"
        //   },
        //   label: "Pond~PondGuard",
        //   visible: this.isIntersect(["POND_GUARD"], this.getSelectedDeviceTypes)
        // },
        {
          value: {
            bk_key: this.categoriesBasedOnDeviceType, // ["POND_AND_DEVICE_SHRIMP_TALK", "USER_AND_DEVICE_SHRIMP_TALK"],
            ft_key: "POND_DEVICES"
          },
          label: "Pond Devices",
          // visible: this.isIntersect(
          //   ["SHRIMP_TALK"],
          //   this.getSelectedDeviceTypes
          // )
          visible: () => true
        },
        {
          value: {
            bk_key: ["SCHEDULES"],
            ft_key: "SCHEDULES"
          },
          label: "Schedules",
          visible: this.isIntersect(
            ["POND_MOTHER"],
            this.getSelectedDeviceTypes
          )
        },
        {
          value: {
            bk_key: ["ABW"],
            ft_key: "ABW"
          },
          label: "ABW",
          visible: () => true
        },
        {
          value: {
            bk_key: ["USER_MANAGEMENT_REGISTRATION", "USER_MANAGEMENT_PROFILE"],
            ft_key: "USER_SETTINGS"
          },
          label: "User Settings",
          visible: () => true
        },
        {
          value: {
            bk_key: ["USER_PONDS"],
            ft_key: "POND_SETTINGS"
          },
          label: "Pond Settings",
          visible: () => this.selectedPondInDropdown !== ''
        },
        // {
        //   value: {
        //     bk_key: ["USER_MANAGEMENT_PROFILE"],
        //     ft_key: "PROFILE_SETTINGS"
        //   },
        //   label: "Profile Settings",
        //   visible: () => true
        // },
        {
          value: {
            bk_key: ["USER_PREFERENCES"],
            ft_key: "PREFERENCES_SETTINGS"
          },
          label: "Preferences Settings",
          visible: () => true
        },
        {
          value: {
            bk_key: ["POND_ALERTS"],
            ft_key: "POND_ALERT"
          },
          label: "Alerts History",
          visible: () => true
        },
        {
          value: {
            bk_key: ["USER_LOCATIONS"],
            ft_key: "USER_LOCATIONS"
          },
          label: "User Locations",
          visible: () => true
        },
        {
          value: {
            bk_key: ["MANUAL_POND_FEEDINGS"],
            ft_key: "MANUAL_POND_FEEDINGS"
          },
          label: "Manual Feeding",
          visible: () => this.selectedPondInDropdown !== ''
        },
        {
          value: {
            bk_key: ["PONDS_HARVEST"],
            ft_key: "PONDS_HARVEST"
          },
          label: "Yield",
          visible: () => this.selectedPondInDropdown !== ''
        },
        {
          value: {
            bk_key: ["PONDS_LAB_TEST"],
            ft_key: "PONDS_LAB_TEST"
          },
          label: "Lab Test",
          visible: () => this.selectedPondInDropdown !== ''
        },
        {
          value: {
            bk_key: ["PONDS_MEDICINES"],
            ft_key: "PONDS_MEDICINES"
          },
          label: "Medicines",
          visible: () => this.selectedPondInDropdown !== ''
        },
        {
          value: {
            bk_key: ["POND_SCHEDULE_SETS"],
            ft_key: "POND_SCHEDULE_SETS"
          },
          label: "Schedules Sets",
          visible: () => true
        },
        {
          value: {
            bk_key: ["USER_LABS"],
            ft_key: "USER_LABS"
          },
          label: "Resource Labs",
          visible: () => true
        },
        {
          value: {
            bk_key: ["RESOURCE_VENDORS"],
            ft_key: "RESOURCE_VENDORS"
          },
          label: "Resource Vendors",
          visible: () => true
        },
        {
          value: {
            bk_key: ["USER_RESOURCES"],
            ft_key: "USER_RESOURCES"
          },
          label: "Resources",
          visible: () => true
        },
        {
          value: {
            bk_key: ["STOCK_FEEDS"],
            ft_key: "STOCK_FEEDS"
          },
          label: "Stock Feed",
          visible: () => true
        },
        {
          value: {
            bk_key: ["STOCK_MEDICINES"],
            ft_key: "STOCK_MEDICINES"
          },
          label: "Stock Medicine",
          visible: () => true
        },
        {
          value: {
            bk_key: ["PONDS_AQUASIMS"],
            ft_key: "PONDS_AQUASIMS"
          },
          label: "Aquasim",
          visible: () => true
        }
      ].filter((x) => x.visible());
    },
    categoriesBasedOnDeviceType() {
      const categories = [];
      if (this.getSelectedDeviceTypes.includes("POND_MOTHER")) {
        categories.push("POND_AND_DEVICE_POND_MOTHER", "USER_AND_DEVICE_POND_MOTHER")
      }
      if (this.getSelectedDeviceTypes.includes("POND_GUARD")) {
         categories.push("POND_AND_DEVICE_POND_GUARD", "USER_AND_DEVICE_POND_GUARD")
      }
      if (this.getSelectedDeviceTypes.includes("SHRIMP_TALK")) {
         categories.push("POND_AND_DEVICE_SHRIMP_TALK", "USER_AND_DEVICE_SHRIMP_TALK")
      }
      if (this.getSelectedDeviceTypes.includes("GATEWAY")) {
         categories.push("USER_AND_DEVICE_GATEWAY")
      }
      if (this.getSelectedDeviceTypes.length === 0) {
          categories.push("POND_AND_DEVICE_POND_MOTHER", "USER_AND_DEVICE_POND_MOTHER",
                        "POND_AND_DEVICE_POND_GUARD", "USER_AND_DEVICE_POND_GUARD",
                        "POND_AND_DEVICE_SHRIMP_TALK", "USER_AND_DEVICE_SHRIMP_TALK",
                        "USER_AND_DEVICE_GATEWAY");
      }
      return categories;
    },
    getEventTypes() {
      return [
        {
          value: "CREATE",
          label: "Create"
        },
        {
          value: "UPDATE",
          label: "Update"
        },
        {
          value: "DELETE",
          label: "Delete"
        }
      ];
    },
    pickerOptions: function() {
      return {};
    }
  },
  methods: {
    ...mapActions("superadmin/activityLog", {
      changeDateRange: "changeDateRange",
      changeLocation: "changeLocation",
      changeLocationSearch: "changeLocationSearch",
      changeDeviceType: "changeDeviceType",
      changeDevices: "changeDevices",
      changeDevicesSearch: "changeDevicesSearch",
      changeEventType: "changeEventType",
      changeLogType: "changeLogType",
      changePond: "changePond",
      changePondSearch: "changePondSearch",
      fetchActivityLog: "fetchActivityLog"
    }),
    isIntersect(arr1 = [], arr2 = []) {
      return () => arr2.find((x) => new Set(arr1).has(x));
    },
    handleDeviceSearch(searchKey) {
      this.changeDevicesSearch(searchKey);
    },
    handlePondSearch(searchKey) {
      this.changePondSearch(searchKey);
    },
    handleLocationSearch(searchKey) {
      this.changeLocationSearch(searchKey);
    },
    hasDeviceType(deviceType) {
      return this.getSelectedDeviceTypes.includes(deviceType);
    },
    handleDateRangeChange(newDates) {
      try {
        const noOfDays = newDates
          .map((x) => {
            return new Date(x);
          })
          .reduce((acc, curr) =>
            this.adm__dateUtilsLib.differenceInDays(curr, acc)
          );
        if (noOfDays > 31) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: "Date range should be within 1 month"
              }
            ]
          };
        }
        this.changeDateRange(newDates);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleLocationChange(location) {
      this.changeLocation(location);
      this.selectedPondInDropdown = '';
    },
    handlePondChange(pond) {
      this.changePondSearch("");
      console.log("pond", pond);
      this.changePond(pond);
    },
    handleDeviceTypeChange(deviceType) {
      this.changeDeviceType(deviceType);
    },
    handleDevicesChanges(devices) {
      this.changeDevices(devices);
    },
    handleDevicesChangesBlur(value) {
      if (value === false) {
        this.changeDevicesSearch('');
      }
    },
    handleEventTypeChange(deviceType) {
      this.changeEventType(deviceType);
    },
    handleLogTypeChange(newLogType) {
      this.changeLogType(newLogType);
    },
    async handleSubmit() {
      try {
        if (this.getSelectedLogTypes.value.bk_key.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: "Log Type is required."
              }
            ]
          };
        }
        await this.fetchActivityLog({});
      } catch (err) {
        this.ehm__errorMessages(err, false, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-form {
  flex-wrap: wrap;
  padding: 10px 5px;
  .text-input-customer-name {
    width: 130px;
  }
  .er-select-with-options {
    width: 170px;
  }
}
</style>
