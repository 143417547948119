<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: PondGuardCalibrationValues.vue
Description:This file contains the calibration values of the pondguard Devices
-->
<template>
  <settings-table
    ref="erDataTables"
    :tableProps="elTableProps"
    v-loading="loading"
    element-loading-background="white"
    :tableData="tableData"
    :columns="CalibrationValues"
    :total="total"
    :addPadingToSearch="true"
    :button="false"
    :columnselector_require="true"
    :selectedColumnHeadersSortable="true"
    search-property="device_code"
    placeHolderForSearch="Search_device_code"
    @reload="handleLoadData"
    @loadData="handleLoadData"
    @cell-click="handleCellClick"
    @cleared="handleLoadData"
    class="is-filters-set-absolute"
  >
    <template v-slot:date_and_time="{ row }">
      {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.date)) }}
    </template>
    <template v-slot:temperature="{ row }">
      {{ row.data.sensor_values ? row.data.sensor_values.temperature : "--" }}
    </template>
    <template v-slot:dissolved_oxygen="{ row }">
      {{
        row.data.sensor_values ? row.data.sensor_values.dissolved_oxygen : "--"
      }}
    </template>
    <template v-slot:solar_voltage="{ row }">
      {{ row.data.sensor_values ? row.data.sensor_values.solar_voltage : "--" }}
    </template>
  </settings-table>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions } from "vuex";
export default {
  mixins: [errorHandlerMixin, filtersMixin, adminDatesHandlerMixin],
  components: { settingsTable },
  data: function() {
    return {
      tableData: [],
      total: 0,
      loading: false
    };
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      fetchAllSensorDeviceDetails: "fetchAllSensorDeviceDetails",
      fetchAllLessDoValues: "fetchAllLessDoValues"
    }),
    async handleLoadData(query) {
      try {
        this.loading = true;
        const response = await this.fetchAllSensorDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: false,
          params: query
        });
        this.tableData = response.pond_guards;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.erDataTables) {
            console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
            this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleCellClick() {}
  },
  computed: {
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    },

    CalibrationValues: function() {
      return [
        {
          prop: "code",
          label: this.$t("Comn_pond_guard_ID"),
          required: true
        },
        {
          prop: "sensor_values.temperature",
          label: this.$t("Comn_temperature"),
          required: true
        },
        {
          prop: "sensor_values.dissolved_oxygen",
          label: this.$t("PM_dissolved_oxygen"),
          required: true
        },

        {
          prop: "sensor_values.solar_voltage",
          label: this.$t("Comn_solar_voltage"),
          required: true
        },
        {
          prop: "sensor_values.date",
          label: this.$t("Comn_date_and_time"),
          required: true
        }
      ];
    }
  }
};
</script>

<style></style>
