/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: stStatisticsUtils.js
Description: Contains stateless helper functions used in ShrimpTalk Statistics
*/
import dateUtils from "@/utils/dateUtils";
import filterUtils from "@/utils/filterUtils";
import { padDateRanges as cPadDateRanges } from "./chartUtils";
export const IQRUpperBound = function(arrValues) {
  const IQRValue = IQR(arrValues);
  const q75Value = Quartile_75(arrValues);
  return Ubound_Quartile(q75Value, IQRValue);
};
export const IQR = function(arrValues) {
  return Quartile_75(arrValues) - Quartile_25(arrValues);
};
export const Ubound_Quartile = function(Quartile_75, IQR) {
  return Quartile_75 + 1.5 * IQR;
};
export const Quartile = function(arrValues, quartile) {
  arrValues.sort((a, b) => a - b);
  const pos = (arrValues.length - 1) * quartile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (arrValues[base + 1] !== undefined) {
    return arrValues[base] + rest * (arrValues[base + 1] - arrValues[base]);
  } else {
    return arrValues[base];
  }
};
export const Quartile_75 = function(arrValues) {
  return Quartile(arrValues, 0.75);
};
export const Quartile_50 = function(arrValues) {
  return Quartile(arrValues, 0.5);
};
export const Quartile_25 = function(arrValues) {
  return Quartile(arrValues, 0.25);
};
export const correlation_factor = function($x, $y) {
  const array_sum = arrValues => arrValues.reduce((acc, x) => acc + x, 0);
  const $length = $x.length;
  const $mean1 = array_sum($x) / $length;
  const $mean2 = array_sum($y) / $length;
  let $a = 0;
  let $b = 0;
  let $axb = 0;
  let $a2 = 0;
  let $b2 = 0;
  for (let $i = 0; $i < $length; $i++) {
    $a = $x[$i] - $mean1;
    $b = $y[$i] - $mean2;
    $axb = $axb + $a * $b;
    $a2 = $a2 + $a * $a;
    $b2 = $b2 + $b * $b;
  }
  const $corr = $a2 !== 0 && $b2 !== 0 ? $axb / Math.sqrt($a2 * $b2) : 0;
  return $corr;
};
/**
 * [getSTHourlyIntakeResponseData - calculates the hourly intake response based on the provide mode]
 *
 * @param   {[Array]}  stStatsData  [stStatsData - array of shrimptalk statistics data]
 * @param   {[string]}  mode         [mode - 'WITH_NOISE','WITH_OUT_NOISE','IQR_WITH_OUT_NOISE']
 */
export const getSTHourlyIntakeResponseData = function(
  stStatsData,
  mode = "WITH_NOISE",
  padRange = false,
  timeZone
) {
  const roundOff = value => +value.toFixed(1);
  const responseSelectionByMode = (
    utilization,
    IQRUpperBoundValue,
    IQRMedianValue,
    mode,
    eachIntakeResponse,
    eachAvgIntakeResponse,
    isNoise
  ) => {
    switch (mode) {
      case "WITH_NOISE":
        return eachIntakeResponse;
      case "WITH_OUT_NOISE":
        return isNoise ? 0 : eachIntakeResponse;
      case "IQR_REPLACE_NOISE":
        return utilization > IQRUpperBoundValue || isNoise
          ? eachAvgIntakeResponse
          : eachIntakeResponse;
      case "AVG_IQR_REPLACE_NOISE":
        return utilization > IQRUpperBoundValue || isNoise
          ? eachAvgIntakeResponse / IQRMedianValue
          : eachIntakeResponse / IQRMedianValue;
      case "AVG_IQR_WITH_OUT_NOISE":
        return isNoise
          ? 0
          : utilization > IQRUpperBoundValue
          ? eachAvgIntakeResponse / IQRMedianValue
          : eachIntakeResponse / IQRMedianValue;
    }
  };
  const arrValuesST = stStatsData.data;
  const arrUtilizationValues = arrValuesST.map(x => roundOff(x.utilization));
  const IQRUpperBoundValue = IQRUpperBound(arrUtilizationValues);
  const IQRMedianValue = Math.round(Quartile_50(arrUtilizationValues));
  const response = arrValuesST.reduce(
    (acc, { utilization, one_cycle_feed: ocf, start, result }) => {
      const correctDate = dateUtils.zonedTimeToUtc(
        dateUtils.startOfHour(dateUtils.utcToZonedTime(start, timeZone)),
        timeZone
      );
      const timeEpoch = correctDate.getTime();
      const isNoise = result === 3;
      const eachAvgIntakeResponse = Math.round(IQRMedianValue * ocf);
      const eachIntakeResponse = Math.round(roundOff(utilization) * ocf);
      if (acc.total[timeEpoch] === undefined) {
        acc.total[timeEpoch] = 0;
      }
      acc.total[timeEpoch] += responseSelectionByMode(
        utilization,
        IQRUpperBoundValue,
        IQRMedianValue,
        mode,
        eachIntakeResponse,
        eachAvgIntakeResponse,
        isNoise
      );
      return acc;
    },
    { total: {} }
  );
  const data = response.total;
  const arrHours = Object.keys(response.total).sort(
    (obj1, obj2) => obj1 - obj2
  );
  const lastDate = arrHours[arrHours.length - 1];
  if (!lastDate) {
    return [];
  }
  const padDateRanges = padRange ? cPadDateRanges(arrHours, 3600000) : arrHours;
  return padDateRanges.map(x => {
    if (data[x]) {
      return [+x, data[x]];
    }
    return [+x, 0];
  });
};
export const getStHourlyUtilization = function(
  stStatsData,
  padRange,
  timeZone
) {
  const arrValuesST = stStatsData.data;
  const response = arrValuesST.reduce(
    (acc, { utilization, one_cycle_feed: ocf, start, result }) => {
      const correctDate = dateUtils.zonedTimeToUtc(
        dateUtils.startOfHour(dateUtils.utcToZonedTime(start, timeZone)),
        timeZone
      );
      const timeEpoch = correctDate.valueOf();
      if (acc.total[timeEpoch] === undefined) {
        acc.total[timeEpoch] = 0;
      }
      acc.total[timeEpoch] += utilization;
      return acc;
    },
    { total: {} }
  );
  const data = response.total;
  const arrHours = Object.keys(response.total).sort(
    (obj1, obj2) => obj1 - obj2
  );
  const lastDate = arrHours[arrHours.length - 1];
  if (!lastDate) {
    return [];
  }
  const padDateRanges = padRange ? cPadDateRanges(arrHours, 3600000) : arrHours;
  return padDateRanges.map(x => {
    if (data[x]) {
      return [+x, data[x]];
    }
    return [+x, 0];
  });
};
export const getStUtilization = function(stStatsData, timeZone) {
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, utilization }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    return [timeEpoch, utilization];
  });
};
export const getStAvgUtilization = function(stStatsData, timeZone) {
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, avg_utilization }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    return [timeEpoch, avg_utilization];
  });
};
export const getStAreaAvgUtilization = function(stStatsData, timeZone) {
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, area, avg_utilization }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    const areaavgutils = Number(
      filterUtils.digitPrecision(area / avg_utilization, 2)
    );

    return [timeEpoch, areaavgutils];
  });
};
export const getHourlyAreaAvgUtils = function(stStatsData, padRange, timeZone) {
  const arrValuesST = stStatsData.data;
  const response = arrValuesST.reduce(
    (acc, { area, start, result, avg_utilization }) => {
      const correctDate = dateUtils.zonedTimeToUtc(
        dateUtils.startOfHour(dateUtils.utcToZonedTime(start, timeZone)),
        timeZone
      );
      const timeEpoch = correctDate.valueOf();
      if (acc.total[timeEpoch] === undefined) {
        acc.total[timeEpoch] = 0;
      }
      acc.total[timeEpoch] += Math.round(area / avg_utilization);
      console.log(acc);
      return acc;
    },
    { total: {} }
  );
  const data = response.total;
  const arrHours = Object.keys(response.total).sort(
    (obj1, obj2) => obj1 - obj2
  );
  const lastDate = arrHours[arrHours.length - 1];
  if (!lastDate) {
    return [];
  }
  const padDateRanges = padRange ? cPadDateRanges(arrHours, 3600000) : arrHours;
  return padDateRanges.map(x => {
    if (data[x]) {
      return [+x, data[x]];
    }
    return [+x, 0];
  });
};

export const getStOcf = function(stStatsData, timeZone) {
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, one_cycle_feed }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    return [timeEpoch, one_cycle_feed];
  });
};
export const getStFeedingRate = function(stStatsData, timeZone) {
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, feed_gap, one_cycle_feed }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    return [timeEpoch, one_cycle_feed / feed_gap];
  });
};
export const getIntakeResponse = function(stStatsData, timeZone) {
  const roundOff = value => +value.toFixed(1);
  const arrValuesST = stStatsData.data;
  return arrValuesST.map(({ start, result, utilization, one_cycle_feed }) => {
    const correctDate = new Date(start);
    const timeEpoch = correctDate.getTime();
    const eachIntakeResponse = Math.round(
      roundOff(utilization) * one_cycle_feed
    );
    return [timeEpoch, eachIntakeResponse, result];
  });
};
export const getUtilizationPercntError = function(stStatsData, timeZone) {
  const roundOff = value => +value.toFixed(2);
  const arrValuesST = stStatsData.data || [];
  if (arrValuesST.length === 0) return 0;
  const utilizationPercntError = arrValuesST.reduce(
    (acc, { utilization, avg_utilization }) => {
      if (utilization < 0) return acc;
      acc += 1 - avg_utilization / utilization;
      return acc;
    },
    0
  );
  return roundOff((utilizationPercntError / arrValuesST.length) * 100);
};
export const getHourlyStPmsFeed = function(
  pondHourlyData,
  shrimp_talk_id,
  timeZone
) {
  const roundOff = value => +value.toFixed(2);
  const date = pondHourlyData.date;
  const timeSlots = pondHourlyData.time_slots;
  const stHourlyFeed = timeSlots.reduce((acc, eachHour) => {
    const tempDate = dateUtils.parse(eachHour.s_time, "HH:mm", new Date());
    const hours = tempDate.getHours();
    const minutes = tempDate.getMinutes();
    const time = dateUtils.zonedTimeToUtc(
      dateUtils.set(dateUtils.utcToZonedTime(date, "UTC"), {
        hours,
        minutes
      }),
      "UTC"
    );
    const timeEpoch = time.getTime();
    const stTS = eachHour.pond_mothers.filter(
      pm => pm.shrimp_talk_id === shrimp_talk_id
    );
    if (stTS.length === 0) {
      return acc;
    }
    const totalStFeed = stTS.reduce(
      (totalFeed, pm) => totalFeed + pm.dispensed_feed,
      0
    );
    acc[timeEpoch] = roundOff(totalStFeed);
    return acc;
  }, {});
  const data = stHourlyFeed;
  const arrHours = Object.keys(data).sort((h1, h2) => h1 - h2);
  const lastDate = arrHours[arrHours.length - 1];
  if (!lastDate) {
    return [];
  }
  const padDateRanges = cPadDateRanges(arrHours, 3600000);
  return padDateRanges.map(x => {
    if (data[x]) {
      return [+x, data[x]];
    }
    return [+x, 0];
  });
};
export const getHourlyOcf = function(stStatsData, padRange, timeZone) {
  return groupValuesByHourlyOnProp(
    stStatsData.data,
    "start",
    "one_cycle_feed",
    padRange
  );
};
export const getHourlyUtilization = function(stStatsData, padRange, timeZone) {
  return groupValuesByHourlyOnProp(
    stStatsData.data,
    "start",
    "utilization",
    padRange
  );
};
export const groupValuesByHourlyOnProp = function(
  arrData,
  timeProp,
  prop,
  doPad,
  timeZone
) {
  const hourlyGroupedData = arrData.reduce(
    (acc, obj) => {
      const correctDate = dateUtils.zonedTimeToUtc(
        dateUtils.startOfHour(
          dateUtils.utcToZonedTime(obj[timeProp], timeZone)
        ),
        timeZone
      );
      // dateUtils.startOfHour(new Date(obj[timeProp]));
      const timeEpoch = correctDate.getTime();

      if (acc.total[timeEpoch] === undefined) {
        acc.total[timeEpoch] = 0;
      }
      acc.total[timeEpoch] += obj[prop];
      return acc;
    },
    { total: {} }
  );
  const data = hourlyGroupedData.total;
  const arrHours = Object.keys(data).sort((obj1, obj2) => obj1 - obj2);
  const lastDate = arrHours[arrHours.length - 1];
  if (!lastDate) {
    return [];
  }
  const padDateRanges = doPad ? cPadDateRanges(arrHours, 3600000) : arrHours;
  return padDateRanges.map(x => {
    if (data[x]) {
      return [+x, data[x]];
    }
    return [+x, 0];
  });
};
export const getHourlyResponseCorrelation = function(stStatsData, timeZone) {
  const roundOff = value => +value.toFixed(2);
  if (stStatsData.length === 0) return 0;
  const intakeAvgIQR = stStatsData
    .map(eachDay =>
      getSTHourlyIntakeResponseData(eachDay, "AVG_IQR_REPLACE_NOISE", false)
    )
    .reverse()
    .flat(1);
  const ocfData = stStatsData.map(eachDay => {
    return eachDay.data;
  });
  const hourlyOCF = getHourlyOcf({ data: ocfData.flat(1) }, false);
  if (hourlyOCF.length === 0 || intakeAvgIQR.length === 0) return 0;
  return roundOff(
    correlation_factor(
      intakeAvgIQR.map(x => x[1]),
      hourlyOCF.map(x => x[1])
    )
  );
};
export const getStHourlyFeeding = function(stStatsData, timeZone) {
  const roundOff = value => +value.toFixed(2);
  const ocfHourly = groupValuesByHourlyOnProp(
    stStatsData.data,
    "start",
    "one_cycle_feed",
    false,
    timeZone
  );
  return ocfHourly.map(x => [x[0], roundOff(x[1] / 1000)]);
};
export default {
  IQRUpperBound,
  Quartile_50,
  IQR,
  getSTHourlyIntakeResponseData,
  getStHourlyUtilization,
  getStUtilization,
  getStAvgUtilization,
  getStOcf,
  getStFeedingRate,
  getIntakeResponse,
  getUtilizationPercntError,
  getHourlyOcf,
  getHourlyStPmsFeed,
  getHourlyResponseCorrelation,
  getStHourlyFeeding,
  getHourlyUtilization,
  getHourlyAreaAvgUtils
};
