<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: smallTableServerSide.vue
Description:This file contains the small  where the data will be shown
-->
<template>
  <div
    class="small-table-server-side"
    :class="{ 'padding-10-0': addPadingToSearch }"
  >
    <el-row type="flex" :gutter="12">
      <el-col>
        <!-- <el-input v-model="filters[0].value" style="width:200px;" size="mini" placeholder="Search"></el-input> -->
        <h3 class="small-table-title">{{ tableName }}</h3>
      </el-col>
      <el-col :span="3">
        <er-button
          @click="handleReloadData"
          size="mini"
          style="padding: 6px 7px; font-weight: 700"
          icon="el-icon-refresh"
          type="primary"
          square
        ></er-button>
      </el-col>
    </el-row>
    <el-row class="padding-10-0">
      <data-tables-server
        :data="tableData"
        :tableProps="computedTableProps"
        :page-size="$constants.table['page-size-5']"
        :pagination-props="$constants.table['small-table-pagination-props']"
        :filters="filters"
        :total="total"
        @query-change="onQueryChange"
      >
        <template v-for="(column, index) in columns">
          <el-table-column
            :key="index"
            :label="column.label"
            :prop="column.prop"
            sortable="custom"
          >
            <template slot-scope="scope">
              <slot
                :name="
                  column.label
                    .toLowerCase()
                    .split(' ')
                    .join('_')
                "
                v-bind:row="{ data: scope.row, path: column.prop }"
              >
                <template
                  v-if="getRowValue(scope.row, column).type === 'array'"
                >
                  <el-tag
                    size="mini"
                    effect="dark"
                    v-for="(val, index) in getRowValue(scope.row, column).value"
                    :key="index"
                    >{{ val }}</el-tag
                  >
                </template>
                <template
                  v-else-if="column.prop === 'utc_last_communicated_at'"
                >
                  <!-- need to pass the column.type = "date" -->
                  <el-tag
                    size="mini"
                    type="success"
                    effect="dark"
                    v-if="
                      getStatusBasedOnDate(
                        scope.row.utc_last_communicated_at,
                        deviceType
                      )
                    "
                    >{{ getRowValue(scope.row, column).value }}</el-tag
                  >
                  <template v-else>
                    {{ getRowValue(scope.row, column).value }}
                  </template>
                </template>
                <template v-else>
                  {{ getRowValue(scope.row, column).value }}
                </template>
              </slot>
            </template>
            <slot
              :name="
                `${column.label
                  .toLowerCase()
                  .split(' ')
                  .join('_')}NestedColumn`
              "
            ></slot>
          </el-table-column>
        </template>
        <slot name="actionColumn"></slot>
      </data-tables-server>
    </el-row>
  </div>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
export default {
  props: {
    columns: {
      default: () => []
    },
    tableData: {
      default: () => []
    },
    searchOnProperty: {
      default: ""
    },
    addPadingToSearch: {
      default: false
    },
    deviceType: {
      default: ""
    },
    total: {
      default: 0
    },
    tableName: {
      default: ""
    },
    tableProps: {
      default: () => ({
        size: "mini",
        border: true,
        height: "210"
      })
    }
  },
  data: function() {
    return {
      query: {},
      searchStr: "",
      filters: [
        {
          value: "",
          filterFn: (row, filter) => {
            return Object.keys(row).some((prop) => {
              if (prop === this.searchOnProperty) {
                return (
                  row[prop].toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              }
            });
          }
        }
      ]
    };
  },
  computed: {
    computedTableProps: function() {
      return this.tableProps;
    }
  },
  watch: {
    total: function(val) {
      this.handlePaginationHideAndUnhide();
    }
  },
  mounted() {
    this.handlePaginationHideAndUnhide();
  },
  methods: {
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName("pagination-bar");
      if (this.total === 0) {
        ele.item(0).style.display = "none";
      } else {
        ele.item(0).style.display = "block";
      }
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;
      const device_type = this.deviceType;
      // console.log(device_type);
      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
            ? {
                type: "string",
                value: dateUtils.formatDate(
                  new Date(value),
                  this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
                )
              }
            : { type: "string", value: "--" };
        if (path === "utc_last_communicated_at") {
          this.getStatusBasedOnDate(value, device_type);
        }
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case "sort":
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case "size":
        case "page":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "init":
          break;
        default:
          throw new Error(
            "Not implemented onquerychange for " + queryInfo.type
          );
      }
      this.query.device_type = this.deviceType;
      this.$emit("loadData", this.query);
    },
    sort(property, orderType) {
      this.query.order_by = property;
      if (orderType === "descending") {
        this.query.order_type = "desc";
      } else if (orderType === "ascending") {
        this.query.order_type = "asc";
      }
    },
    pagination(page, pageSize) {
      this.query.limit = pageSize;
      this.query.page = page;
      this.page = page;
      this.limit = pageSize;
      // this.applyFilters({ value: pageSize, type: "limit" });
      // this.applyFilters({ value: page, type: "page" });
    },
    getStatusBasedOnDate(date, device_type) {
      if (!date) {
        return false;
      }

      try {
        const oldDate = new Date(date);
        const currentDate = new Date();

        return (
          (currentDate - oldDate) / 1000 <
          this.getInactiveTimeToCheck(device_type)
        );
      } catch (err) {
        return false;
      }
    },
    handleReloadData() {
      this.$emit("reloadData", {
        limit: this.query.limit || 5,
        page: this.query.page || 1,
        device_type: this.deviceType
      });
    },
    getInactiveTimeToCheck(device_type) {
      let time = 1800;
      switch (device_type) {
        case "POND_MOTHER":
          time = 1800;
          break;
        case "POND_GUARD":
          time = 1800;
          break;
        case "SHRIMP_TALK":
          time = 900;
          break;
        default:
          time = 1800;
          break;
      }
      return time;
    }
  }
};
</script>

<style lang="scss">
.small-table-server-side {
  width: 460px;
  height: 23rem;
  .sc-table {
    div.cell .el-link.el-link--default {
      font-size: 12px;
      letter-spacing: .9px;
    }
    .pagination-bar {
      .pagination-eruvaka {
        margin-top: 0.5rem;
        button {
          background-color: #efefef;
          border: none;
          cursor: pointer;
          border: 1px solid #eee5e5;
          border-radius: 2px;
          margin-right: 4px;
          height: 23px;
        }
        .el-pagination__jump {
          font-size: 13px;
          margin-left: 15px !important;
          .el-input__inner {
            padding: 0 15px !important;
            height: 25px;
          }
        }
        .el-pagination__editor {
          margin-left: 5px;
          font-size: 13px;
          .el-pagination__editor.el-input .el-input__inner {
            height: 26px !important;
          }
        }
        .el-pagination__sizes {
          margin-left: 5px;
          .el-select {
            width: 6rem;
            margin-left: 9rem;
            margin-top: 0.5rem;
          }
          .el-input--mini .el-input__inner {
            height: 25px !important;
          }
        }
        .el-pagination__total {
          font-size: 13px;
          margin-top: -1.4rem;
          position: absolute;
          margin-left: 2rem;
        }
        .el-pager li {
          min-width: 1.5rem !important;
          border: 1px solid #eee5e5;
          border-radius: 2px;
          margin-right: 4px;
          line-height: 21px !important;
          height: 23px !important;
        }
        .el-pager li.btn-quicknext {
          line-height: 18px;
          height: 23px !important;
          color: #303133;
        }
        .el-pager {
          line-height: 12px;
          margin-top: 1px;
        }
        .el-pager li.active {
          background-color: #409EFF;
          color: #ffffff;
          cursor: default;
        }
      }
    }
  }
}
</style>
