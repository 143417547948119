<template>
  <el-card class="layout-account-manager-card">
    <layout-toolbar slot="header">
      <slot name="heading"></slot>
      <div class="filler"></div>
      <el-col :span="4">
        <el-input
          v-if="hasElementInLayout('search')"
          :placeholder="searchTranslationKeyName"
          :debounce="300"
          v-model="searchString"
          size="small"
          clearable
          @change="handleChange"
          @clear="handleClear"
          class="search-input"
          autofocus="true"
          ref="mainSearch"
        ></el-input>
      </el-col>
      <el-col :span="3" v-if="showDownloadDropdown" class="download-dropdown">
        <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcelClick"
        ></er-download-drop-down>
      </el-col>
      <er-button
        v-if="hasElementInLayout('button')"
        btnType="add"
        size="mini"
        :showLabel="true"
        :showIcon="true"
        @click="handleAddButtonClick"
      >
        <slot name="add-buttton">
          {{ capitalize($t("dealer.assign_to_user")) }}
        </slot>
      </er-button>
      <slot name="table-toolbar-left-slot"> </slot>
    </layout-toolbar>
    <slot name="table"></slot>
  </el-card>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";

export default {
  props: {
    toolbarLayout: {
      default: "button,search",
      type: String
    },
    placeHolderForSearch: {
      default: "device",
      type: String
    },
    searchProperty: {
      default: "code",
      type: String
    },
    showDownloadDropdown: {
      default: false,
      type: Boolean
    }
  },
  data: function() {
    return {
      searchString: "",
      query: {}
    };
  },
  mixins: [filtersMixin, errorHandlerMixin],
  computed: {
    searchTranslationKeyName() {
      if (this.placeHolderForSearch && this.$t(this.placeHolderForSearch)) {
        return this.$t(this.placeHolderForSearch);
      }
      return this.$t("accountmanager.Search_username");
    }
  },
  methods: {
    hasElementInLayout(element) {
      return this.toolbarLayout.includes(element);
    },
    handleAddButtonClick() {
      this.$emit("add-item-click");
    },
    handleDownloadExcelClick() {
      this.$emit("download-excel");
    },
    async handleClear() {
      await this.$emit("cleared");
    },
    async handleChange(string) {
      if (string === "") {
        this.query.page = this.page;
        this.query.limit = this.limit;
        delete this.query[this.searchProperty];
      } else {
        this.query[this.searchProperty] = string;
      }
      await this.$emit("loadData", this.query);
    }
  }
};
</script>

<style lang="scss" scoped>
.layout-account-manager-card {
  border-radius: 7px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  &::v-deep .el-card__header {
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .card-icon {
      @include icon-size(svg);
      margin-right: 10px;
    }
  }
  &::v-deep .el-card__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex-grow: 1;
  }
  .download-dropdown {
    .el-dropdown {
      .el-button {
        padding: 5px 15px !important;
      }
    }
  }
  .el-table__header-wrapper {
    overflow: initial !important;
  }
}
</style>

<style lang="scss">
.layout-account-manager-card {
  .download-dropdown {
    .el-dropdown {
      .el-button {
        padding: 5px 15px !important;
      }
    }
  }
  .el-table__header-wrapper {
    overflow: initial !important;
  }
  .el-input--small .el-input__inner {
    height: 28px !important;
  }
}
</style>
