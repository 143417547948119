<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkDeviceNetworks.vue
Description:This file  contains the details of the  wifi details (wifi network name, wifi password)
-->
<template>
  <div>
    <!-- <basic-customer-details
      :device-details="deviceDetails"
      :user-details="customerDetails"
      :showCustomerDetails="true"
      :allowAddCustomer="false"
    ></basic-customer-details> -->
    <br />
    <small-table
      :tableData="wifi_table_data"
      :columns="wifi_table_columns"
      :addPadingToSearch="true"
      tableName="Available Networks"
      search-on-property="wifi_network"
      :height="300"
    ></small-table>
  </div>
</template>

<script>
import smallTable from "@/components/superadmin/shared/smallTable";
// import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
export default {
  props: ["deviceDetails", "customerDetails"],
  components: {
    smallTable
    // basicCustomerDetails
  },
  data: function () {
    return {
      loading: false,
      tableData: [],
      emptyStr: "--"
    };
  },
  computed: {
    wifi_table_data: function () {
      // const sensorDetails = this.deviceDetails.sensor_values || {};
      const settings = this.deviceDetails.settings || {};

      const wifi_networks = settings.wifi_networks || [];
      const wifi_password = settings.wifi_passwords;
      const wifi_security = settings.wifi_security;
      // const wifi_signal = Array.isArray(settings.signal_strength)
      //   ? settings.signal_strength
      //   : [];

      const tableData = [];
      for (let i = 0; i < wifi_networks.length; i++) {
        tableData.push({
          wifi_network: wifi_networks[i],
          wifi_password: wifi_password[i],
          wifi_security: wifi_security[i],
          wifi_signal: [i] || "--"
        });
      }

      return tableData;
    },
    wifi_table_columns: function () {
      return [
        {
          prop: "wifi_network",
          label: "Wifi Network",
          required: true
        },
        {
          prop: "wifi_password",
          label: "Wifi Password",
          required: true
        },
        {
          prop: "wifi_security",
          label: "Wifi Security",
          required: true
        },
        {
          prop: "wifi_signal",
          label: "Wifi Signal",
          required: true
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped></style>
