<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayDevices
Description:This file contains the  list of gateways,basic gateway Details and  components GatewayDeviceNetworks,pondMotherStatics Data
-->
<template>
  <el-container class="gateway-devices">
    <el-aside>
      <portal-target name="outsideAsideScroll"></portal-target>
      <item-list-side-bar
        :item-list="gatewayList"
        :init-item-id="deviceId"
        item-type="gateways"
        class="side-list-menu"
        showProperty="code"
        search-str-query-param="device_code"
        :search="true"
        :dataFetchMethod="loadGatewayData"
        @loadData="loadGatewayData"
        @cleared="loadGatewayData"
        @itemClicked="handleGatewaySelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </el-aside>
    <el-main>
      <gateway-device-networks
        v-loading="loading"
        element-loading-background="white"
        class="gateway-device-details"
        :deviceDetails="deviceDetails"
        :customer-details="customerDetails"
      ></gateway-device-networks>
    </el-main>
  </el-container>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import gatewayDeviceNetworks from "./gatewayDeviceNetworks";
import { mapActions, mapGetters } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";

export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  components: {
    itemListSideBar,
    gatewayDeviceNetworks,
  },
  data: function () {
    return {
      data: { gateway_code: "" },
      loading: false,
      gateway_data: "",
      customer_data: "",
      totalItemsCount: 100,
      emptyStr: "--",
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getGateways: "getGateways",
      getGatewaysObj: "getGatewaysObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj",
    }),

    deviceDetails() {
      // return this.getGatewaysObj[this.gateway_id];
      return this.gateway_data;
    },
    deviceId() {
      return (this.deviceDetails || { _id: "no gateway" })._id;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
        };
      }
    },
    gatewayList() {
      return this.getGateways;
    },
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchADeviceData: "fetchADeviceData",
      fetchAllUsers: "fetchAllUsers",
      fetchAUserDetails: "fetchAUserDetails",
      fetchAGatewayLatestSensorData: "fetchAGatewayLatestSensorData",
    }),
    async initComponent(params, parameter = null) {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        const gatewayResponse = await this.fetchAllDeviceDetails({
          device_type: "gateway",
          infinite_scroll: true,
          params: { page: 1, limit: 19 },
        });
        this.totalItemsCount = gatewayResponse.total;
        // await this.fetchAllUsers({ get_all: true });
        await this.callStoreOnGatewayData(
          (this.gatewayList[0] || { _id: "No Gateways" })._id
        );
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadGatewayData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllDeviceDetails({
          device_type: "gateway",
          infinite_scroll: infinite_scroll,
          params: query,
        });
        // this.tableData = this.$store.getters["superadmin/getPondGuards"];
        this.totalItemsCount = response.total;
        // console.log(response);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnGatewayData(gateway_id) {
      try {
        this.gateway_data = await this.fetchADeviceData({
          device_type: "gateway",
          device_id: gateway_id,
        });

        const startOfToday = this.adm__dateUtilsLib.startOfDay(
          this.adm__getTodayInUserTZ(this.deviceTimeZoneStr)
        );

        const params = {
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            "yyyy-MM-dd"
          ),
          to_date: this.adm__dateUtilsLib.formatDate(
            startOfToday,
            "yyyy-MM-dd"
          ),
        };

        await this.fetchAGatewayLatestSensorData({
          gateway_id,
          params,
        });

        console.log(this.gateway_data);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleGatewaySelected(gateway_id) {
      try {
        this.loading = true;
        await this.callStoreOnGatewayData(gateway_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.gateway-devices {
  .item-list-menu {
    height: 82vh;
  }
  .side-list-menu {
    width: 200px;
  }
}
</style>
