<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: chartInCard.vue
Description: This file is the common chart component used in admin for visualising the data of pondguards
-->
<template>
  <el-card :body-style="{ padding: '0px' }" class="chart-in-card">
    <div slot="header" class="clearfix">
      <el-row type="flex">
        <el-col>
          <h3>{{ chartTitle }}</h3>
        </el-col>

        <el-col :span="4" v-if="timeQuery">
          <el-select
            value-key="id"
            v-model="selectedTime"
            @change="handleChangeTime"
            size="mini"
          >
            <el-option
              v-for="timeValue in getTimeIntervals"
              :key="timeValue.value.id"
              :label="timeValue.text"
              :value="timeValue.value"
            ></el-option>
          </el-select>
        </el-col>
        <div v-if="dateQuery">
          <el-date-picker
            v-model="dateRangePicked"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="dd-MMM-yy"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="mini"
            @change="handleChangeDate"
          ></el-date-picker>
        </div>
      </el-row>
    </div>
    <high-charts
      :key="updateChart"
      :options="computedChartOption"
      constructor-type="stockChart"
    ></high-charts>
  </el-card>
</template>

<script>
import { objectIdGenerator } from "@/utils/commonUtils";
import eventBus from "@/components/shared/eventBus";
import dateUtils from "@/utils/dateUtils";
const timeIDs = [];
for (let i = 0; i < 10; i++) {
  timeIDs.push(objectIdGenerator());
}
export default {
  props: {
    chartOptions: { default: () => {} },
    chartTitle: { default: "No Title" },
    updateChart: { default: 0 },
    timeQuery: { default: true },

    dateQuery: { default: false }
  },
  created() {
    eventBus.$on("pm-item-clicked", () => {
      this.dateRangePicked = [
        dateUtils.formatDate(
          dateUtils.subtract(new Date(), { days: 2 }),
          "yyyy-MM-dd"
        ),
        dateUtils.formatDate(new Date(), "yyyy-MM-dd")
      ];
    });
  },
  computed: {
    computedChartOption() {
      const tempChartOptions = this.$lodash.cloneDeep(this.chartOptions);
      const componentsScope = this;
      this.$lodash.set(
        tempChartOptions,
        "plotOptions.series.point.events.mouseOver",
        this.$lodash.debounce(function(event) {
          console.log("event from chart", event);
          componentsScope.$emit("onSeriesClick", event, this);
        }, 1000)
      );
      // return;
      return tempChartOptions;
    },
    getTimeIntervals: function() {
      return [
        {
          text: "15 mins",
          value: { num: 15, units: "minutes", id: timeIDs[0] }
        },
        {
          text: "30 mins",
          value: { num: 30, units: "minutes", id: timeIDs[1] }
        },
        {
          text: "1 hours",
          value: { num: 1, units: "hours", id: timeIDs[2] }
        },
        {
          text: "3 hours",
          value: { num: 3, units: "hours", id: timeIDs[3] }
        },
        {
          text: "6 hours",
          value: { num: 6, units: "hours", id: timeIDs[4] }
        },
        {
          text: "12 hours",
          value: { num: 12, units: "hours", id: timeIDs[5] }
        },
        {
          text: "1 day",
          value: { num: 1, units: "days", id: timeIDs[6] }
        },
        {
          text: "1 week",
          value: { num: 1, units: "weeks", id: timeIDs[7] }
        },
        {
          text: "1 month",
          value: { num: 1, units: "months", id: timeIDs[8] }
        },
        {
          text: "3 month",
          value: { num: 3, units: "months", id: timeIDs[9] }
        }
      ];
    }
  },
  data: function() {
    return {
      selectedTime: { num: 1, units: "days", id: timeIDs[6] },
      dateRangePicked: ["", ""]
    };
  },
  mounted() {
    this.dateRangePicked = [
      dateUtils.formatDate(
        dateUtils.subtract(new Date(), { days: 2 }),
        "yyyy-MM-dd"
      ),
      dateUtils.formatDate(new Date(), "yyyy-MM-dd")
    ];
  },
  methods: {
    handleChangeTime(changedTimeValue) {
      console.log({ changedTimeValue });
      this.$emit("timeChanged", changedTimeValue);
    },
    handleChangeDate(changedDateValue) {
      this.$emit("dateChanged", changedDateValue);
    }
  }
};
</script>

<style lang="scss">
.el-card__body:nth-child(2) {
  margin-top: 20px;
}
.chart-in-card {
  margin: 10px 0;
  h3 {
    font-size: 16px !important;
  }
  .el-range-editor--mini.el-input__inner {
    width: auto;
  }
}
</style>
