/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for the charts in the shrimptalk tab of  superadmin site
*/
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { baseChartConfig, xAxisConfig } from "@/config/baseChartConfig";
import { stIntakeGraphColors } from "@/utils/componentColors.js";
const chartOptions = {
  ...baseChartConfig,
  legend: {
    enabled: true,
    itemDistance: 5,
    margin: 5
  },
  xAxis: xAxisConfig({
    ordinal: false,
    title: {
      text: "Date and Time"
    },
    minRange: 24 * 3600 * 1000,
    type: "datetime"
  }),
  yAxis: [
    {
      labels: {
        format: "{value}"
      },
      title: {
        text: "Feeding Rate"
      },
      opposite: false
    },
    {
      title: {
        text: "Intake Response"
      },
      labels: {
        format: "{value}"
      },
      opposite: true
    }
  ],
  tooltip: {
    pointFormatter: function() {
      var response_type = this.series.name;
      if (
        [
          "SIGN_INTAKE_RESPONSE_WITH_RESULT",
          "STATS_INTAKE_RESPONSE_WITH_RESULT"
        ].includes(this.series.userOptions.id)
      ) {
        const colorToSegment = stIntakeGraphColors;
        const value = colorToSegment[this.result];
        const intake_color =
          value.color === "#f5f559" ? "#e0e01a" : value.color;
        response_type =
          "" +
          this.series.name +
          '(<span style="color:' +
          intake_color +
          ';font-weight:700;">' +
          value.msg +
          "</span>)";
      }
      return response_type + ": <b>" + this.y + "</b><br/>";
    },
    shadow: false,
    borderRadius: 3,
    shared: true,
    valueDecimals: 2,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  },
  plotOptions: {
    series: {
      turboThreshold: 10000000
    }
  },
  series: [
    {
      id: "PM_HOURLY",
      name: "PM Hourly Feeding",
      type: "column",
      data: [],
      color: "#f1b727",
      pointWidth: 10,
      yAxis: 0
    },
    {
      id: "ST_HOURLY",
      name: "ST Hourly Feeding",
      type: "column",
      data: [],
      color: "#ee8b97",
      pointWidth: 10,
      yAxis: 0
    },
    {
      id: "FEEDING_RATE",
      name: "Feeding Rate",
      type: "column",
      data: [],
      color: "#0000ff",
      yAxis: 0
    },
    {
      id: "STATS_OCF",
      name: "OCF",
      type: "column",
      color: "#00bcd4",
      pointWidth: 10,
      yAxis: 0,
      data: []
    },
    {
      id: "STATS_INTAKE_RESPONSE_WITH_RESULT",
      name: "Intake Response",
      type: "coloredline",
      lineWidth: 1,
      yAxis: 1,
      zIndex: 100,
      data: []
    },
    {
      id: "STATS_INTAKE_WITH_NOISE",
      name: "Hourly Intake Response",
      type: "spline",
      color: "#742796",
      yAxis: 1,
      data: []
    },
    {
      id: "STATS_INTAKE_WITH_OUT_NOISE",
      name: "Hourly Intake Response Noise Removal",
      type: "spline",
      color: "#C774DD",
      yAxis: 1,
      data: []
    },
    // {
    //   id: "STATS_INTAKE_IQR_WITH_OUT_NOISE",
    //   name: "IQR-Hourly Intake Response Noise Removal",
    //   type: "spline",
    //   color: "#0f52ba",
    //   yAxis: 1,
    //   data: [],
    // },
    {
      id: "STATS_INTAKE_AVG_IQR_WITH_OUT_NOISE",
      name: "IQR-Hourly Intake Response Noise Removal",
      type: "spline",
      color: "#0f52ba",
      yAxis: 1,
      data: []
    },
    {
      id: "STATS_UTILIZATION",
      name: "Utilization",
      type: "spline",
      color: "#fdc8aa",
      yAxis: 1,
      data: []
    },
    {
      id: "STATS_AVG_FEED_UTILIZATION",
      name: "Average Feed Utilization",
      type: "spline",
      data: [],
      color: "#00a288",
      yAxis: 1,
      pointWidth: 10
    },
    {
      id: "SIGN_INTAKE_RESPONSE_WITH_RESULT",
      name: "Signature Intake Response",
      type: "coloredline",
      lineWidth: 1,
      yAxis: 1,
      zIndex: 100,
      data: []
    },
    // {
    //   id: "SIGN_INTAKE_WITH_NOISE",
    //   name: "Signature Hourly Intake Response",
    //   type: "spline",
    //   color: "#742796",
    //   yAxis: 1,
    //   data: [],
    // },
    // {
    //   id: "SIGN_INTAKE_WITH_OUT_NOISE",
    //   name: "Signature Hourly Intake Response Noise Removal",
    //   type: "spline",
    //   color: "#C774DD",
    //   yAxis: 1,
    //   data: [],
    // },
    // {
    //   id: "SIGN_INTAKE_IQR_WITH_OUT_NOISE",
    //   name: "Sign-IQR-Hourly Intake Response Noise Removal",
    //   type: "spline",
    //   color: "#C2F888",
    //   yAxis: 1,
    //   data: [],
    // },
    {
      id: "SIGN_INTAKE_AVG_IQR_WITH_OUT_NOISE",
      name: "Signature IQR-Hourly Intake Response Noise Removal",
      type: "spline",
      color: "#C2F888",
      yAxis: 1,
      data: []
    },
    {
      id: "SIGN_UTILIZATION",
      name: "Signature Utilization",
      type: "spline",
      color: "#cee830",
      yAxis: 1,
      data: []
    },
    {
      id: "SIGN_AVG_FEED_UTILIZATION",
      name: "Signature Average Feed Utilization",
      type: "spline",
      data: [],
      zIndex: 100,
      color: "#32ab53",
      yAxis: 1
    },
    {
      id: "SIGN_AREA_AVERAGE_UTILS",
      name: "Normalized Area",
      type: "spline",
      data: [],
      zIndex: 100,
      color: "green",
      yAxis: 1
    },
    {
      id: "HOURLY_AREA_AVG_UTILS",
      name: "Hourly Area/Avg Utils",
      type: "spline",
      data: [],
      zIndex: 100,
      color: "red",
      yAxis: 1
    }
  ]
};
export default chartOptions;
