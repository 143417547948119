/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: urlParamsUpdateMixin.js
Description: This mixin contains the common functionality  for editing the url params configuration object provided by the parent component in the child component.
*/
const urlParamsUpdateMixin = {
  inject: ["uppm__routeParamsObj"],
  computed: {
    upum__urlParams: function() {
      return this.$route.query;
    }
  },
  methods: {
    upum__setUrlParams(property, value) {
      this.$set(this.uppm__routeParamsObj.urlParams, property, value);
    },
    upum__deleteUrlParams(property) {
      this.$delete(this.uppm__routeParamsObj.urlParams, property);
    }
  }
};

export default urlParamsUpdateMixin;
