<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: CusomerDetailsTable.vue
Description: This file contains the table component that display customer details who are allotted to the accout manager in account manager site
-->

<template>
  <div
    class="customer-details-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <layout-accountmanager-card
      toolbar-layout="search"
      search-property="user_name"
      placeHolderForSearch="Search_username"
      @loadData="handleLoadData"
      v-show="!loading"
    >
      <template v-slot:heading>
        <div style="display: flex; align-items: center">
          <img src="@/assets/dealer/peoplegroup.svg" atl="down arrow" />
          <p style="margin-left: 20px">
            {{ `${$t("Comn_all")} ${$t("DL_customers")}` }}
          </p>
        </div>
      </template>

      <template slot="table">
        <defaultTable
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          :tableProps="elTableProps"
          placeHolderForSearch="Search_username"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:first_name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:email_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:country_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:phone_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:uiedit_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:ui_edit="{ row }">
          <el-switch
            :value="(row.data.device_config || {}).device_ui_edit"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @input="handleEnableFeature($event, row.data, 'device_ui_edit')"
          ></el-switch>
        </template>

          <template v-slot:first_name="{ row }">
            {{ row.data.first_name }}
            {{ row.data.last_name }}
          </template>
          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button
                size="mini"
                btnType="download"
                :title="$t('Harvest_log_in')"
                :showIcon="false"
                :disabled="isReadOnly"
                :showLabel="true"
                @click="handleLoginAsUser($event, row.data._id)"
              >
                {{ $t("login_as_user") }}
              </er-button>
            </el-button-group>
          </template>
        </defaultTable>
      </template>
    </layout-accountmanager-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "./defaultTable";
import layoutAccountmanagerCard from "./layoutAccountmanagerCard";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],

  data: function () {
    return {
      tableData: [],
      selectedUsers: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
    };
  },
  components: {
    defaultTable,
    layoutAccountmanagerCard
  },

  methods: {
    ...mapActions("accountManager", {
      fetchAllAssingedAccountUsers: "fetchAllAssingedAccountUsers",
      LoginAsUser: "LoginAsUser",
      enableFeature: "enableFeature"
    }),
      async handleEnableFeature(value, userDetails, key) {
      try {
        const confirmMessage = {
          device_ui_edit: this.$t("accountmanager.confirm_device_ui_edit", { action: value ? this.$t("accountmanager.enable") : this.$t("accountmanager.disable") })
        };
        await this.$confirm(confirmMessage[key], this.$t('accountmanager.Comn_warning'), {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        await this.enableFeature({
          user_id: userDetails._id,
          payload: { [key]: value }
        });
        this.$notify({
          type: "success",
          title: "Success",
          message: value ? this.$t("accountmanager.Feature_enable") : this.$t("accountmanager.Feature_disable")
        });
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        const response = await this.fetchAllAssingedAccountUsers({
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.getAccountManagerUsers;
        console.log('this.tableData', this.tableData);
        this.total = response.total;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    async handleLoginAsUser(event, userId) {
      const loading = this.$loading({ fullscreen: true });
      try {
        await this.LoginAsUser(userId);
        this.$router.push(
          "/user/",
          () => {
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Welcome_to_pondlogs"),
              duration: 5000,
              type: "success"
            });
            loading.close();
          },
          () => {
            this.$notify({
              title: this.$t("failed"),
              message: this.$t("Something_went_wrong_try_again"),
              duration: 5000,
              type: "error"
            });
            loading.close();
          }
        );
      } catch (err) {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong"),
          duration: 5000,
          type: "error"
        });
        loading.close();
      }
    }
  },

  computed: {
    ...mapGetters("accountManager", {
      getAccountManagerUsers: "getAccountManagerUsers"
    }),
    elTableProps() {
      return {
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      };
    },

    columnsObject: function () {
      return [
        {
          label: "accountmanager.Comn_name",
          prop: "first_name",
          name: "first_name",
          required: true,
          sortable: true
        },
        {
          label: "accountmanager.Comn_email",
          prop: "email",
          name: "email",
          required: true,
          sortable: true
        },

        {
          prop: "country.name",
          label: "accountmanager.Comn_country",
          name: "country",
          required: true,
          sortable: true
        },

        {
          prop: "phone",
          label: "accountmanager.Usrs_phone",
          name: "phone",
          required: true,
          sortable: true
        },
        {
          prop: "ui_edit",
          label: "accountmanager.Comn_edit_label",
          name: "uiedit",
          required: true,
          minWidth: 100
        },
        {
          label: "accountmanager.Comn_actions",
          name: "Actions",
          prop: "actions",
          required: true
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.customer-details-table {
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
}
</style>
