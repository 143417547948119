<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerIndividualViewSA
Description:This file contains the itemListSideBar(Acccount managers), list of the customers associated with Account manager and add user to the Account manager by admin
-->
<template>
  <div class="individula-view-main-container">
    <el-row>
      <el-row class="main-row">
        <!-- <el-col :span="4">
        <item-list-side-bar
          :item-list="getAllSkrettingTechnicians"
          :init-item-id="subuser_id"
          item-type="skrettingTechnicians"
          class="side-list-menu"
          :showProperty="['first_name', 'last_name']"
          :search="true"
          :totalItemsCount="totalItemsCount"
          search-property="user_name"
          :dataFetchMethod="skrettingTechnicianData"
          @loadData="skrettingTechnicianData"
          @cleared="skrettingTechnicianData"
          @itemClicked="handlePmSelected"
        ></item-list-side-bar>
      </el-col> -->
        <el-col :span="4" class="search-bar">
          <el-form inline size="mini" class="filter-form" :show-message="false">
            <el-form-item>
              <el-select
                v-model="value"
                @change="handleInput"
                filterable
                placeholder="Select"
              >
                <el-option
                  v-for="item in getAllSkrettingTechnicians"
                  :key="item._id"
                  :label="item.full_name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col>
          <AddAccountCustomers
            v-bind:subuser_id="subuser_id"
          ></AddAccountCustomers>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import AddAccountCustomers from "./AddAccountCustomers";
export default {
  props: ["subuser_id"],
  components: {
    // itemListSideBar,

    AddAccountCustomers
  },
  watch: {
    subuser_id(val) {
      console.log(val);
      const { full_name } =
        this.getAllSkrettingTechnicians.length > 0 &&
        this.getAllSkrettingTechnicians.find(
          (item) => item._id === this.subuser_id
        );
      this.value = full_name;
    }
  },
  data: function() {
    return {
      value: "",
      activeName: "first",
      dialogVisible: false,
      loading: false,
      state2: "",
      tableData: [],
      totalItemsCount: 100,
      total: 0,
      searchDealer: "",
      selectedDealer: {},
      actionToPerform: "",
      newDealer: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("accountManager", {
      getAllSkrettingTechnicians: "getSkrettingTechnicians"
    })
  },
  methods: {
    ...mapActions("accountManager", {
      fetchAllSkrettingTechnicians: "fetchAllSkrettingTechnicians"
    }),

    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleInput(id) {
      this.$router.replace(id);
    },
    async skrettingTechnicianData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllSkrettingTechnicians();
        this.totalItemsCount = response.data?.sub_users.length;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handlePmSelected(subuser_id) {
      this.$router.replace(subuser_id);
    },

    handleSelect(item) {
      console.log(item);
    },

    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllSkrettingTechnicians();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      const { full_name } =
        this.getAllSkrettingTechnicians.length > 0 &&
        this.getAllSkrettingTechnicians.find(
          (item) => item._id === this.subuser_id
        );
      this.value = full_name;
    }
  },
  async mounted() {
    document.getElementsByClassName('dealer-menu').item(0).style.zIndex = 1;
    await this.initComponent();
  }
};
</script>

<style lang="scss" scoped>
.account-header {
    .dealer-menu {
    z-index: 100;
    }
}
.el-input__inner {
  height: 32px !important;
}
.individula-view-main-container {
  margin-left: 6px;
  margin-top: -60px;
}
.main-row {
  position: relative;

  .search-bar {
    position: relative;
    z-index: 100;
    top: 60px;
  }
}
.search-code-input {
  width: 340px;
  padding: 5px 0px;
  background-color: #ecf3f9;
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
  margin-bottom: 3px;
}
</style>
