<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Doser Current"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { doserCurrentGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard,
  },
  data: function () {
    return {
      chartOptions: doserCurrentGraphConfig,
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPMFeedDoserCurrentData");
    this.acm__initParameterName("doser_current");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Doser Current", "line");
    },
  },
};
</script>

<style lang="scss"></style>
