<template>
  <el-scrollbar
    class="user-table-scroll user-list-table"
    viewClass="user-table-scroll-view"
    wrapClass="user-table-scroll-wrap"
  >
    <er-data-tables
      v-show="!loading"
      :tableData="getOtherUserTableData"
      :columns="tableColumns"
      :el-table-props="tableProps"
      :action-column="false"
      :key="$i18n.locale + '1'"
      type="white-header-table"
      layout="table"
      unique-id="user-access-managment__table"
      :searchOnTableData="userSearch"
      @row-click="handleRowClick"
    >
    <!-- :sortMethodForColumns="sortMethodForColumns" -->
      <template v-slot:first_name="{ row }">
        <div>
          <p class="table-header-text captalize">
            {{ `${row.data.first_name} ${row.data.last_name}` }}
          </p>
          <p class="table-main-text">{{ row.data.email }}</p>
        </div>
      </template>
      <template v-slot:status="{ row }">
        <div
          :class="[
            'user-status-tag',
            row.data.status === 'ACTIVE' && 'is-active'
          ]"
        >
          <p>{{ getStatus(row.data) }}</p>
        </div>
      </template>
      <template v-slot:department="{ row }">
        <p class="table-main-text">
          {{ departmentNameHandler(row.data.department) }}
        </p>
      </template>
    </er-data-tables>
  </el-scrollbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { UAM_UI_STATES } from "@/constants/uiStates";
import filtersMixin from "@/mixins/filtersMixin";
import { ADMIN_USER_TYPES } from "@/constants/filterTypes";
export default {
  // inject: ["parentConfig"],
  mixins: [filtersMixin],
  props: ["userSearch"],
  data() {
    return {
    //   userSearch: "",
      loading: false
    };
  },
mounted() {
  this.fetchSuperAdminUsersList();
},
  computed: {
    ...mapGetters("superadmin", {
      getFilteredSuperUserList: "getFilteredSuperUserList",
      getSidePanelState: "getSidePanelState"
    }),
    tableProps: function() {
      return {
        size: "mini",
        rowClassName: this.rowClassName,
      };
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getOtherUserTableData() {
      return this.getFilteredSuperUserList
    },
    getSidePanelUserId() {
      return this.getUserData._id;
    },
    tableColumns() {
      return {
        first_name: {
          prop: "name",
          label: "Name",
          minWidth: 120,
          sortable: true,
          searchProperty: "email",
          enableSearch: true
        },
        status: {
          prop: "status",
          label: "Status",
          searchProperty: "ui_type",
          enableSearch: true
        },
        department: {
          prop: "department",
          label: "Department",
          sortable: "custom",
          searchProperty: "department",
          enableSearch: true
        }
      };
    },
  },
  methods: {
    ...mapActions("superadmin", {
      fetchSuperAdminUsersList: "fetchSuperAdminUsersList",
      changeAdminUserSidePanelState: "changeAdminUserSidePanelState"
    }),
    getStatus(data) {
      return data.status === "ACTIVE" ? 'Active' : 'Inactive';
    },
    departmentNameHandler(data) {
      return ADMIN_USER_TYPES.filter(obj => obj.key === data)[0]?.label
    },
    handleRowClick: async function(row, column, event) {
      this.changeAdminUserSidePanelState({
        ui_state: "Display",
        user: row
      });
    },
    rowClassName({ row, rowIndex }) {
      if (row && this.getSidePanelUserId === row._id) {
        return "selected-row";
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.skretting-group {
  height: 40pxs;
}
.el-table__append-wrapper {
  font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
  // height: 20px;
  background: #f2f0f9;
  color: #0a2463;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 6px 7px;
}
.user-list-table {
  &.user-table-scroll {
    position: relative;
    @include responsiveProperty(height, 74vh, 78vh, 82vh);
    .user-table-scroll-wrap {
      height: 100%;
      overflow-y: auto;
      overflow-x: auto;
    }
    .user-table-scroll-view {
      padding-right: 10px;
    }
  }
  .user-status-tag {
    background: #f2f0f9;
    border-radius: 10px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0a2463;
    &:before {
      content: "\2B24";
      color: #0a2463;
      font-size: 13px;
      margin-right: 5px;
      font-size: 8px;
    }
    &.is-active {
      background: #33a02c;
      color: white;
      &:before {
        color: white;
      }
    }
    p {
    margin-bottom: 0px;
  }
  }
}
</style>
