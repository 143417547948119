/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: adminDatesHandlerMixin.js
Description: This Mixin is useful for handling the common functionality related to the dates in superadmin site.
*/
import dateUtils from "@/utils/dateUtils";
import { getType } from "@/utils/commonUtils";
const datesHandlerMixin = {
  data: function() {
    return {
      adm__dateUtilsLib: dateUtils
    };
  },
  computed: {
    adm__getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    }
  },
  methods: {
    adm__filterToFormatDate: function(date, format = "dd-MMM-yy HH:mm") {
      if (!date) {
        return undefined;
      }
      if (getType(date) === "string") {
        return dateUtils.formatDate(new Date(date), format);
      }
      return dateUtils.formatDate(date, format);
    },
    adm__toISOString(dateObj) {
      return this.adm__dateUtilsLib.toISOString(dateObj);
    },
    adm__getTodayInUserTZ(getUserTimeZoneString) {
      return this.adm__dateUtilsLib.getCurrDateInGivenTZ(getUserTimeZoneString);
    },
    adm__castUserUTCToUTCISO: function(dateISO, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.formatUserUTCISOToUTCISO(
        dateISO,
        getUserTimeZoneString
      );
    },
    adm__formatUTCISOToUTCDateObj(dateISO) {
      return this.adm__dateUtilsLib.parse(
        dateISO,
        this.adm__dateUtilsLib.isoFormatString,
        new Date()
      );
    },
    adm__castUserUTCToUTCDateObj: function(dateISO, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.zonedTimeToUtc(
        this.adm__castUserUTCToUTCISO(dateISO),
        getUserTimeZoneString
      );
    },
    adm__formatByTZOnUTCWithLocale(
      userUTCdateISO,
      format,
      getUserTimeZoneString
    ) {
      return this.adm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        this.adm__castUserUTCToUTCISO(userUTCdateISO),
        format,
        getUserTimeZoneString,
        this.adm__getLocaleObj
      );
    },
    adm__formatByTZOnUTCISOWithLocale(dateISO, format, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        dateISO,
        format,
        getUserTimeZoneString,
        this.adm__getLocaleObj
      );
    },
    adm__formatOnDateObjWithLocale(date, format, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.formatDate(date, format, {
        locale: this.adm__dateUtilsLib.localesMap[this.$i18n.locale]
      });
    },
    adm__formatByTZOnDate(date, format, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        this.adm__dateUtilsLib.toISOString(date),
        format,
        getUserTimeZoneString,
        this.adm__getLocaleObj
      );
    },
    adm__formatTZ(date, format, getUserTimeZoneString) {
      return this.adm__dateUtilsLib.formatTZ(date, format, {
        timeZone: getUserTimeZoneString,
        locale: this.adm__dateUtilsLib.localesMap[this.$i18n.locale]
      });
    }
  }
};

export default datesHandlerMixin;
