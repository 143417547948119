// admin pg charts
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { baseChartConfig, xAxisConfig } from "@/config/baseChartConfig";
import dateUtils from "@/utils/dateUtils";
const legend = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled
  };
};

const exporting = function(enabled) {
  return {
    enabled: enabled
  };
};

const title = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled,
    text: undefined
  };
};
const labelFormatter = {
  formatter: function() {
    const startOfDay = dateUtils.startOfDay(
      dateUtils.utcToZonedTime(this.value, "UTC")
    );
    const value = dateUtils.utcToZonedTime(this.value, "UTC");
    switch (this.tickPositionInfo.unitName) {
      case "hour":
        if (dateUtils.isEqual(value, startOfDay)) {
          return dateUtils.formatTZ(
            dateUtils.utcToZonedTime(this.value, "UTC"),
            "d. MMM",
            {
              timeZone: "UTC",
              locale: dateUtils.localesMap.en
            }
          );
        }
    }
    return this.axis.defaultLabelFormatter.call(this);
  }
};

const tooltip = {
  default: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  numericXAxisToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small><strong>DOC:{point.x}</strong></small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
  },
  pondScheduleToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name.tooltip}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  hourlyToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  },
  pmScheduleToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">Total Dispenced Feed (kgs) : </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  }
};
const batteryVoltageGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const dispenserCurrentGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const errorCodeGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const motorStatusGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const standardDeviationGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const dispenserImpedanceGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};

const doserHallStatusGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};

const doserCurrentGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};

const feedLevelSensorStatusGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};

const hopperEmptyHightGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const pmScheduleGraph = {
  ...baseChartConfig,
  legend: legend(true),
  exporting: exporting(true),
  title: title(false),
  tooltip: { ...tooltip.pmScheduleToolTip },
  xAxis: xAxisConfig({
    type: "datetime",
    minRange: 24 * 3600 * 1000
  })
};

const pondHourlyGraphConfig = {
  ...baseChartConfig,
  tooltip: {
    ...tooltip.hourlyToolTip
  },
  xAxis: {
    events: {
      afterSetExtremes: () => {}
    },
    labels: labelFormatter,
    ordinal: false,
    type: "datetime",
    showEmpty: false,
    title: {
      text: "",
      align: "middle",
      style: {
        color: "#000000"
      }
    }
  },
  legend: legend(true),
  exporting: exporting(true),
  title: title(false)
};
export {
  pmScheduleGraph,
  batteryVoltageGraphConfig,
  dispenserCurrentGraphConfig,
  errorCodeGraphConfig,
  motorStatusGraphConfig,
  standardDeviationGraphConfig,
  dispenserImpedanceGraphConfig,
  doserHallStatusGraphConfig,
  doserCurrentGraphConfig,
  feedLevelSensorStatusGraphConfig,
  hopperEmptyHightGraphConfig,
  pondHourlyGraphConfig
};
