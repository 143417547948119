<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgListIndividualDetailsSA.vue
Description:this file contains the ItemListSideMenu(list of pondguards),basic details of pondguard(ex:username,locations), pg graphs ph ,temperature,air_pressure,air_temperature,oxygen,solar_voltage, lithium voltage-->
<template>
  <!-- {{pg_id}} -->
  <layout
    v-loading="loading"
    element-loading-background="white"
    class="pg-individual-details-sa"
  >
    <template slot="deviceListMenu">
      <item-list-side-bar
        :item-list="pgList"
        :init-item-id="pg_id"
        :showProperty="'code'"
        item-type="pondguards"
        :dataFetchMethod="loadPgData"
        :totalItemsCount="totalItemsCount"
        search-str-query-param="device_code"
        @cleared="loadPgData"
        @loadData="loadPgData"
        @itemClicked="handlePgSelected"
      ></item-list-side-bar>
    </template>
    <portal to="outsideMainScroll">
      <basic-customer-details
        :device-details="deviceDetails"
        :user-details="userDetails"
        :showCustomerDetails="true"
        @change-edit="handleEditChange"
      >
        <template slot="moreActions">
          <i
            class="material-icons-round"
            :title="syncStatus.message"
            :style="`color:${syncStatus.color};`"
          >
            sync
          </i>
          <er-button
            v-if="userDevicePermissionCheck"
            size="mini"
            @click="handleRemoveCustomer"
            :disabled="!userDetails"
          >
            <span class="material-icons-round"> person_remove </span>
          </er-button>
        </template>
      </basic-customer-details>
    </portal>
    <el-scrollbar
      class="pg-details pg-details-scroll"
      viewClass="pg-details-scroll-view"
      wrapClass="pg-details-scroll-wrap"
    >
      <div v-show="componentName === 'pond-guard-graphs'">
        <pg-graphs
          :key="pg_id"
          :device-details="deviceDetails"
          :loadingGraphName="loadingGraphName"
          :loadingGraphValue="loadingGraphValue"
          @changedDateQuery="handleChangedDateQuery"
        ></pg-graphs>
      </div>
      <div v-show="componentName === 'pond-guard-details'">
        <pg-details
          :device-details="deviceDetails"
          :pond-guard="pond_guard_data"
          :user-details="userDetails"
          :isDisabled="isDisabled"
          @refresh="handlePgSelected"
        ></pg-details>
      </div>
    </el-scrollbar>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import pgGraphs from "@/components/superadmin/pond-guard/pgGraphs";
import pgDetails from "@/components/superadmin/pond-guard/pgDetails";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  props: ["pg_id"],
  components: {
    layout,
    pgDetails,
    pgGraphs,
    basicCustomerDetails,
    itemListSideBar,
  },
  data: function () {
    return {
      data: { pond_guard_code: "" },
      loading: false,
      pond_guard_data: {},
      componentName: "pond-guard-details",
      totalItemsCount: 100,
      loadingGraphName: "ph",
      loadingGraphValue: false,
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondGuards: "getPondGuards",
      getPgsObj: "getPgsObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj",
    }),
    syncStatus() {
      return this.deviceDetails.settings_changed
        ? { message: "Settings not updated to device", color: "#E31A1C" }
        : { message: "Settings updated to device", color: "#33A02C" };
    },
    deviceDetails() {
      // return this.getPgsObj[this.pg_id];
      const pgData = { ...this.pond_guard_data };
      pgData.device_type = "pond_guard";
      return this.pond_guard_data;
    },
    userDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id]
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
        };
      }
    },
    pgList() {
      return this.getPondGuards;
    },
    initFromTimeToTime() {
      return {
        from_date: this.adm__dateUtilsLib.formatDate(
          this.adm__dateUtilsLib.subtract(new Date(), { days: 1 }),
          this.adm__dateUtilsLib.isoFormatString
        ),
        to_date: this.adm__dateUtilsLib.formatDate(
          new Date(),
          this.adm__dateUtilsLib.isoFormatString
        ),
      };
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userDevicePermissionCheck() {
      return this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER");
    },
  },
  async mounted() {
    await this.initComponent(this.initFromTimeToTime);
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAPondGuardLatestSensorData: "fetchAPondGuardLatestSensorData",
      fetchAPondGuardLatestSalinityData: "fetchAPondGuardLatestSalinityData",
      fetchAllUsers: "fetchAllUsers",
      fetchAllPonds: "fetchAllPonds",
      fetchADeviceData: "fetchADeviceData",
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      deleteDevicesToUser: "deleteDevicesToUser",
    }),
    handleEditChange(changedEditStatus) {
      this.isDisabled = changedEditStatus;
    },
    async handleRemoveCustomer() {
      try {
        await this.$confirm(
          "Your are going to unassign the user from the device, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning",
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.userDetails._id,
          payload: [this.pg_id],
          device_type: "POND_GUARD",
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success",
        });
        await this.initComponent(this.initFromTimeToTime);
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed",
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async initComponent(params, parameter = null) {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        params.page = 1;
        params.limit = 20;
        const pgResponse = await this.fetchAllDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: true,
          params,
        });
        this.totalItemsCount = pgResponse.total;
        await this.fetchAllUsers({ get_all: true });
        // await this.fetchAllPonds({ get_all: true });
        await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadPgData({ query, infinite_scroll }) {
      if (
        query.device_code &&
        query.device_code.toUpperCase().startsWith("ERUVAKA")
      ) {
        query.device_code = query.device_code.substr(7).trim();
      }

      const response = await this.$store.dispatch(
        "superadmin/fetchAllDeviceDetails",
        {
          device_type: "pond_guard",
          infinite_scroll: infinite_scroll,
          params: query,
        }
      );
      this.totalItemsCount = response.total;
    },
    async handleChangedDateQuery(params, parameter = null) {
      try {
        this.loadingGraphName = parameter;
        this.loadingGraphValue = true;
        await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loadingGraphValue = false;
      }
    },

    async callStoreOnPgLatestSensorData(
      pond_guard_id,
      params,
      parameter = null
    ) {
      try {
        this.pond_guard_data = await this.fetchADeviceData({
          device_type: "pond_guard",
          device_id: pond_guard_id,
        });
        await this.fetchAPondGuardLatestSensorData({
          pond_guard_id,
          params,
          parameter,
        });
        await this.fetchAPondGuardLatestSalinityData({
          pond_guard_id,
          params
          });
        this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handlePgSelected(pond_guard_id) {
      try {
        this.loading = true;
        this.$router.replace(pond_guard_id);
        this.callStoreOnPgLatestSensorData(
          pond_guard_id,
          this.initFromTimeToTime
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.pg-individual-details-sa {
  .pg-details {
    padding-left: 10px;
    &.pg-details-scroll {
      position: relative;
      height: 81vh;
    }
    .pg-details-scroll-view {
      padding-right: 10px;
    }
    .pg-details-scroll-wrap {
      height: 100%;
      overflow-x: auto;
    }
  }
}
</style>
