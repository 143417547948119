<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayTableSA.vue
Description:This file contains the details of the gateway devices
-->
<template>
  <layout class="gateway-table-sa" :no-aside="true">
    <!-- <portal to="outsideMainScroll"> -->
    <!-- <router-link :to="{query: baseUrlFormatter({tab: activeTabUrl})}">
    <tab-layout
      :componentList="componentList"
      :showComponent="componentName"
      :tab-click-handler="handleTabClick"
      @selectedComponent="handleSelectedComponent"
    >
      <template slot="gatewayDevices">
          <gateway-devices></gateway-devices>
      </template>
      <template slot="gatewayFilters">
        <gateway-filters></gateway-filters>
      </template>
    </tab-layout>
    </router-link> -->
    <er-top-route-nav type="tabs" :items="computedTabItems"></er-top-route-nav>
    <!-- </simplebar> -->
    <component
      :is="computedComponentName"
      @change-tab="handleChangeTabTo"
      :tabData="componentList[computedComponentName]"
    ></component>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import notificationMixin from "@/mixins/notificationMixin";
import gatewayDevices from "@/components/superadmin/gateway/gatewayDevices";
import gatewayFilters from "@/components/superadmin/gateway/gatewayFilters";
import gatewayDetails from "@/components/superadmin/gateway/gatewayDetailsTab";
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import { mapActions } from "vuex";
import dialogForm from "@/components/superadmin/shared/dialogForm";
const tabComponents = [
  { name: "gatewayDetails", label: "Gateway Details" },
  { name: "gatewayDevices", label: "Gateway Data" },
  { name: "gatewayFilters", label: "Gateway Filters" }
];
export default {
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    filtersMixin,
    adminDatesHandlerMixin
  ],
  data: function() {
    return {
      queryParams: {},
      loading: false,
      componentName: "gatewayDetails",
      activeTabUrl: "gateway-details",
      componentList: {
        gatewayDetails: {
          name: "gatewayDetails",
          label: "Gateway Details"
        },
        gatewayDevices: {
          name: "gatewayDevices",
          label: "Gateway Data"
        },
        gatewayFilters: {
          name: "gatewayFilters",
          label: "Gateway Filters"
        }
      }
    };
  },
  components: {
    settingsTable,
    layout,
    gatewayDevices,
    gatewayFilters,
    tabLayout,
    dialogForm,
    gatewayDetails
  },
  computed: {
    computedComponentName: function() {
      return this.$route.query.tab;
    },
    computedTabItems: function() {
      return tabComponents;
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    }
  },

  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey"
    }),
    handleChangeTabTo: function(tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    },
    baseUrlFormatter(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      };
    },
    handleTabClick(tab) {
      this.clearDeviceDetails({
        device_type: "gateway"
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
      this.mapTabToUrl();
    }
    // mapTabToUrl() {
    //   switch (this.componentName) {
    //     case 'gatewayDetails':
    //       this.activeTabUrl = 'gateway-details';
    //       break;
    //     case 'gatewayDevices':
    //       this.activeTabUrl = 'gateway-devices';
    //       break;
    //     case 'gatewayFilters':
    //       this.activeTabUrl = 'gateway-filters';
    //       break;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
// .el-menu--horizontal {
//   position: absolute;
//   z-index: 1;
// }
.er-column-selector {
  float: right;
}
.gateway-table-sa {
  .el-tabs__item {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
  .el-tag {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
  .el-table__body-wrapper {
    @include responsiveProperty(height, 360px, 500px, 600px);
    overflow-y: scroll;
  }
}
</style>
