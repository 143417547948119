<template>
  <div slot="header" class="clearfix chart-in-card">
    <el-row type="flex" :gutter="20" align="middle">
      <el-col v-if="dateQuery" class="flex-end-date-range">
        <label v-if="!logsTab">Select date range to download Feeder Log ZIP: </label>
        <el-date-picker
          v-model="dateRangePicked"
          type="daterange"
          format="dd-MMM-yy"
          value-format="yyyy-MM-dd"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="mini"
          @change="acm__handleDateQueryChange"
        ></el-date-picker>
      </el-col>
      <el-col :span="4" v-if="downloadOption" style="cursor: pointer">
        <div @click="handledownload">
          <el-button type="success" size="medium">Download</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { objectIdGenerator } from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
const timeIDs = [];
for (let i = 0; i < 10; i++) {
  timeIDs.push(objectIdGenerator());
}
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  props: {
    title: { default: "" },
    timeQuery: { default: true },
    downloadOption: { default: false },
    dateQuery: { default: false },
    logsTab: { default: false }
  },
  computed: {
    getTimeIntervals: function() {
      return [
        {
          text: "15 mins",
          value: { num: 15, units: "minutes", id: timeIDs[0] }
        },
        {
          text: "30 mins",
          value: { num: 30, units: "minutes", id: timeIDs[1] }
        },
        {
          text: "1 hours",
          value: { num: 1, units: "hours", id: timeIDs[2] }
        },
        {
          text: "3 hours",
          value: { num: 3, units: "hours", id: timeIDs[3] }
        },
        {
          text: "6 hours",
          value: { num: 6, units: "hours", id: timeIDs[4] }
        },
        {
          text: "12 hours",
          value: { num: 12, units: "hours", id: timeIDs[5] }
        },
        {
          text: "1 day",
          value: { num: 1, units: "days", id: timeIDs[6] }
        },
        {
          text: "1 week",
          value: { num: 1, units: "weeks", id: timeIDs[7] }
        },
        {
          text: "1 month",
          value: { num: 1, units: "months", id: timeIDs[8] }
        },
        {
          text: "3 month",
          value: { num: 3, units: "months", id: timeIDs[9] }
        }
      ];
    }
  },
  data: function() {
    return {
      selectedTime: { num: 1, units: "days", id: timeIDs[6] },
      dateRangePicked: ["", ""]
    };
  },
  mounted() {
    this.dateRangePicked = [
      dateUtils.formatDate(
        dateUtils.subtract(new Date(), { days: 2 }),
        "yyyy-MM-dd"
      ),
      dateUtils.formatDate(new Date(), "yyyy-MM-dd")
    ];
    this.acm__initParameterName("sensor_logs");
     this.acm__initParameterName("logs");
    // this.$emit("timeChanged", this.dateRangePicked);
  },
  methods: {
    handleChangeTime(changedTimeValue) {
      console.log(changedTimeValue);
      this.$emit("timeChanged", changedTimeValue);
    },
    handleChangeDate(changedDateValue) {
      this.$emit("dateChanged", changedDateValue);
    },
    async handledownload() {
      this.acm__handleDownloadQuery(this.dateRangePicked);
    },
    acm__handleDateQueryChange(changedDateValue) {
      const from_date = changedDateValue[0];
      const to_date = changedDateValue[1];
      this.$emit(
        "changedDateQuery",
        { to_date, from_date },
        this.acm__parameterName
      );
    }
  }
};
</script>

<style lang="scss">
.chart-in-card {
  margin: 10px 0;
  .flex-end-date-range {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
