<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: Observer.vue
Description:This file contains the functionality where the data changes  with respective to  api calls.                 UsedComponents :ItemListSideMenu etc
-->
<template>
  <div class="observer" />
</template>

<script>
export default {
  props: ["options"],
  data: () => ({
    observer: null
  }),
  mounted () {
    const options = this.options || {};
    this.observer = new IntersectionObserver(async ([entry]) => {
      if (entry && entry.isIntersecting) {
        await this.$emit("intersect");
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed () {
    this.observer.disconnect();
  }
};
</script>
<style lang="scss">
.observer {
  height: 12px;
}
</style>
