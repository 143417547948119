<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedBlowerTableSA.vue
Description:This file contains the details of the feedblowers and add the feed blower too by admin
-->
<template>
  <layout class="gateway-table-sa" :no-aside="true">
    <tab-layout
      :componentList="componentList"
      :showComponent="urlParams.tab"
      :tab-click-handler="handleTabClick"
      @selectedComponent="handleSelectedComponent"
    >
      <template slot="feedBlowerDetails">
        <dialog-form
          :model="getNewFeedBlower()"
          :schema="gatewayFields"
          :showDialog="dialogVisible"
          :loading="dialogBoxLoading"
          :backendValidations="createFeedBlowerFormErrors"
          title="Add Feed Blower"
          @close-dialog="closeDialog"
          @save-details="handleSaveNewFeedBlower"
          @clear-validations="handleClearValidations"
        ></dialog-form>

        <settings-table
          ref="erDataTables"
          :tableProps="elTableProps"
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :addPadingToSearch="true"
          search-property="device_code"
          placeHolderForSearch="Search_device_code"
          :total="total"
          :button="true"
          :columnselector_require="true"
          :selectedColumnHeadersSortable="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
          @add-item-click="handleAddClick"
        >
          <template slot="add-buttton">Add Feed Blower</template>

          <template v-slot:feed_blower_code="{ row }">
            {{ row.data.code }}
            <!-- <el-link :href="`/admin/feedblowers/${row.data._id}`">

            </el-link> -->
          </template>

          <template v-slot:customer="{ row }">
            {{ row.data.user_details ? row.data.user_details.full_name : "--" }}
          </template>
          <template v-slot:dealer_details="{ row }">
            {{
              row.data.dealer_details ? row.data.dealer_details.full_name : "--"
            }}
          </template>
          <template v-slot:location_details="{ row }">
            {{
              row.data.location_details ? row.data.location_details.name : "--"
            }}
          </template>
          <template v-slot:installed_date="{ row }">
            {{
              ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at))
            }}
          </template>
        </settings-table>
      </template>
    </tab-layout>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import notificationMixin from "@/mixins/notificationMixin";
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import { mapActions } from "vuex";
import dialogForm from "@/components/superadmin/shared/dialogForm";
export default {
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    adminDatesHandlerMixin,
    filtersMixin
  ],
  data: function() {
    return {
      tableData: [],
      total: 0,
      queryParams: {},
      loading: false,
      dialogBoxLoading: false,
      componentName: "feedBlowerDetails",
      createFeedBlowerFormErrors: {},
      dialogVisible: false,
      urlParams: {
        tab: "feedBlowerDetails",
        ...this.$route.query
      },
      componentList: [
        {
          name: "feedBlowerDetails",
          label: "Feed Blower Details"
        }
      ]
    };
  },
  components: {
    settingsTable,
    layout,
    tabLayout,
    dialogForm
  },
  watch: {
    urlParams: {
      deep: true,
      handler: function(newValue, oldValue) {
        this.$router.push({ path: "/admin/feedblowers", query: newValue });
      }
    }
  },
  computed: {
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 170px)";
    },

    gatewayFields: function() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        }
      ];
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          label: "Feed Blower Code",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },
        {
          prop: "_id",
          label: "Feed Blower Id",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },

        {
          prop: "user_details.full_name",
          label: "Customer Name",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200,
          fixed: "fixed"
        },
        {
          prop: "location_details.name",
          label: "Location",
          required: true,
          minWidth: 200,
          fixed: "fixed"
        },
        {
          prop: "v1_code",
          label: "v2 code",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },

        {
          prop: "on_boarded_at",
          label: "Installed Date",
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        }
      ];
    }
  },

  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey",
      saveFeedBlower: "saveFeedBlower"
    }),
    getNewFeedBlower() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        )
      };
    },
    closeDialog() {
      this.createFeedBlowerFormErrors = {};
      this.dialogVisible = false;
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createFeedBlowerFormErrors = {};
          break;
        case "SINGLE":
          delete this.createFeedBlowerFormErrors[indexPosition];
          break;
      }
    },
    handleAddClick() {
      this.dialogVisible = true;
    },
    async handleSaveNewFeedBlower(model, arrGatewayNames) {
      try {
        this.dialogBoxLoading = true;
        const payload = arrGatewayNames.map((gatewayName) => {
          return {
            v1_code: gatewayName,
            on_boarded_at: model.on_boarded_at
          };
        });
        console.log(payload);

        await this.saveFeedBlower(payload);
        this.$notify({
          title: "Success",
          message: "Created Feed Blower Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        this.$notify({
          title: "Failed",
          message: "Created Gateway UnSuccessful",
          type: "error"
        });
        if (err.response && err.response.data) {
          this.createFeedBlowerFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
        await this.handleLoadData(this.queryParams);
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllDeviceDetails({
          device_type: "feed_blower",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getFeedBlowers"];

        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.erDataTables) {
            console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
            this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleTabClick(tab) {
      this.clearDeviceDetails({
        device_type: "feed_blower"
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
      this.urlParams = { tab: component };
    }
  }
};
</script>

<style lang="scss">
.gateway-table-sa {
  .el-tabs__item {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
  .el-tag {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
  }
  .main-without-aside {
    overflow: hidden !important;
  }
}
</style>
