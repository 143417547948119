<template>
  <div class="release-logs-main-container">
    <new-release-admin-view />
  </div>
</template>

<script>
import newReleaseAdminView from './newRelease';
export default {
  components: { newReleaseAdminView }
};
</script>

<style lang="scss">
.release-logs-main-container {
}
</style>
