<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AddPondGuard.vue
Description:This file contains the details  of the pondguards associated with the dealer and add the new pondguards by superadmin
-->
<template>
  <div class="add-pondguard">
    <er-dialog
      width="30%"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      @open="handleOpenDialog"
      :title="$t('add-pond-guard')"
      class="action-on-dealer"
      custom-class="add_pondguard_dialog"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form size="large">
          <ValidationProvider
            name="Pond Guard Device"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item label="" :error="errors[0]">
              <er-select
                :placeholder="$t('Comn_search')"
                :loading="userListLoading"
                v-model="selectedPGs"
                :showSelectAll="true"
                class="select-devices"
                value-key="code"
                collapse-tags
                multiple
                filterable
                remote
                reserve-keyword
                :remote-method="fetchDevicesToAssign"
              >
                <el-option
                  v-for="item in unassignedPGs"
                  :key="item._id"
                  :label="item.code.concat('(' + item.v1_code + ')')"
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>

      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="saveLoading"
          @click="submitUserDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :disabled="saveLoading"
          @click="handleCloseDialog"
        ></er-button>
      </template>
    </er-dialog>

    <settings-table
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :total="total"
      :button="true"
      :key="dealer_id"
      :columnselector_require="false"
      placeHolderForSearch="Search Device"
      search-property="device_code"
      :addPadingToSearch="true"
      @loadData="handleLoadData"
      @reload="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddDealerClick"
    >
      <template slot="add-buttton">Add PondGuard</template>

      <template v-slot:actions="{ row }">
        <el-button-group>
          <er-button size="mini" @click="handleUserDelete(row.data)">
            <span class="material-icons-round"> delete </span>
          </er-button>
        </el-button-group>
      </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
  props: ["dealer_id"],
  data: function() {
    return {
      tableData: [],
      userSearchQuery: {},
      selectedUsers: [],
      total: 0,
      selectedPGs: [],
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      newClient: {},
      unassignedPGs: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
    };
  },
  components: {
    settingsTable
  },
  computed: {
    ...mapGetters("superadmin", {
      getUnassignedPondGuards: "getUnassignedPondGuards",
      getParticularDealer: "getParticularDealer",
      getDealerAssignedPondMothers: "getDealerAssignedPondMothers"
    }),
    columnsObject: function() {
      return [
        {
          label: "Device Id",
          prop: "code",
          required: true,
          sortable: true
        },

        {
          prop: "status",
          label: "Status",
          required: true,
          sortable: true
        },

        {
          label: "Actions",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      addPondGuardToDealer: "addPondGuardToDealer",
      fetchAllDealerDeviceDetails: "fetchAllDealerDeviceDetails",
      fetchDealer_PondMothers: "fetchDealer_PondMothers",
      fetchAllUnassignedDeviceDetails: "fetchAllUnassignedDeviceDetails",
      removeAssignedDevicesFromDealer: "removeAssignedDevicesFromDealer"
    }),
    async fetchDevicesToAssign(queryString) {
      if (queryString === "") {
        this.unassignedPGs = [];
        return this.unassignedPGs;
      }
      try {
        this.userListLoading = true;
        this.userSearchQuery.device_code = queryString;
        await this.fetchAllUnassignedDeviceDetails({
          infinite_scroll: false,
          device_type: "pond_guard",
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        this.unassignedPGs = this.getUnassignedPondGuards;
      }
    },

    async handleUserDelete(removePG) {
      try {
        const confirm_message = removePG.code;
        await this.nofm__deleteConfirmation(confirm_message, "table");
        this.loading = true;
        await this.removeAssignedDevicesFromDealer({
          payload: [removePG._id],
          dealer_id: this.dealer_id,
          device_type: "POND_GUARD"
        });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Device deleted Successfully",
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        const errors = [];
        if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });

          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
        await this.handleLoadData(this.queryParams);
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllDealerDeviceDetails({
          infinite_scroll: false,
          dealer_id: this.dealer_id,
          device_type: "pond_guards",
          params: query
        });
        this.tableData = response.pond_guards;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },

    handleAddDealerClick() {
      this.actionToPerform = "add";
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleOpenDialog(event) {
      this.unassignedPGs = [];
      this.selectedPGs = [];
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    async submitUserDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      this.saveLoading = true;
      const payload = {
        dealer_id: this.dealer_id,
        pond_guard_ids: this.selectedPGs.map(x => x._id)
      };
      try {
        await this.addPondGuardToDealer(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Device Added Successfully",
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.saveLoading = false;
        this.dialogVisible = false;
        await this.handleLoadData(this.queryParams);
      }
    }
  }
};
</script>

<style lang="scss">
.add-pondguard {
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table {
    .el-table {
      .el-table__body-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 0;
        height: calc(100vh - 286px) !important;
        overflow-y: auto;
      }
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    // @include responsiveProperty(height, 75vh, 79vh, 85vh);
    @include responsiveProperty(height, 64vh, 70vh, 76vh);
  }
  .el-row--flex.is-justify-end {
    padding-right: 10px;
  }
}
.add_pondguard_dialog {
  border-radius: 10px;
}
</style>
