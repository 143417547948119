<template>
  <div slot="header" class="clearfix chart-in-card">
    <el-row type="flex" :gutter="20" align="middle">
      <el-col v-if="dateQuery" class="flex-end-date-range">
        <label>Select date range to download Feeder Log ZIP: </label>
        <er-date-picker
          v-if="StRawData"
          :value="dateTimeRangePicked"
          :timeZoneString="getUserTimeZoneString"
          type="datetimerange"
          size="mini"
          format="dd-MMM-yy HH:mm"
          value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :clearable="false"
          :picker-options="pickerOptions"
          @input="handleDateRangeChange"
      ></er-date-picker>
        <!-- <el-date-picker
          v-if="StRawData"
          v-model="dateTimeRangePicked"
          type="datetimerange"
          format="dd MMM yy h:mm"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="mini"
          @change="acm__handleDateTimeQueryChange"
        ></el-date-picker> -->
        <el-date-picker
          v-if="!StRawData"
          v-model="dateRangePicked"
          type="daterange"
          format="dd-MMM-yy"
          value-format="yyyy-MM-dd"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="mini"
          @change="acm__handleDateQueryChange"
        ></el-date-picker>
      </el-col>
      <el-col :span="4" v-if="downloadOption" style="cursor: pointer">
        <div v-if="!StRawData">
          <el-button @click="handledownload" type="success" :loading="loadingData" size="medium">Download</el-button>
        </div>
        <div v-if="StRawData">
        <er-button type="success" size="medium" @click="handleDownloadData" :disabled="loadingData" :loading="loadingData">Download</er-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { objectIdGenerator } from "@/utils/commonUtils";
import { mapGetters } from "vuex";
import dateUtils from "@/utils/dateUtils";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import urlParamsProvideMixin from "@/mixins/urlParamsProvideMixin";
import moment from "moment";
const timeIDs = [];
for (let i = 0; i < 10; i++) {
  timeIDs.push(objectIdGenerator());
}
export default {
  mixins: [errorHandlerMixin, adminChartsMixin, urlParamsProvideMixin, datesHandlerMixin],
  props: {
    title: { default: "" },
    timeQuery: { default: true },
    downloadOption: { default: false },
    dateQuery: { default: false },
    StRawData: { default: false }
  },
  computed: {
    ...mapGetters("user", {
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    getDateTimeValue() {
      return this.dateTimeRangePicked
    },
    getTimeIntervals: function() {
      return [
        {
          text: "15 mins",
          value: { num: 15, units: "minutes", id: timeIDs[0] }
        },
        {
          text: "30 mins",
          value: { num: 30, units: "minutes", id: timeIDs[1] }
        },
        {
          text: "1 hours",
          value: { num: 1, units: "hours", id: timeIDs[2] }
        },
        {
          text: "3 hours",
          value: { num: 3, units: "hours", id: timeIDs[3] }
        },
        {
          text: "6 hours",
          value: { num: 6, units: "hours", id: timeIDs[4] }
        },
        {
          text: "12 hours",
          value: { num: 12, units: "hours", id: timeIDs[5] }
        },
        {
          text: "1 day",
          value: { num: 1, units: "days", id: timeIDs[6] }
        },
        {
          text: "1 week",
          value: { num: 1, units: "weeks", id: timeIDs[7] }
        },
        {
          text: "1 month",
          value: { num: 1, units: "months", id: timeIDs[8] }
        },
        {
          text: "3 month",
          value: { num: 3, units: "months", id: timeIDs[9] }
        }
      ];
    },
    pickerOptions() {
      return {
        shortcuts: [
          {
            no: 30,
            unit: "minutes",
            text: "Last 30 Mins",
            type: "datetime",
            includeToday: true
          },
          {
            no: 1,
            unit: "hours",
            text: "Last 1 Hour",
            type: "datetime",
            includeToday: true
          },
          {
            no: 3,
            unit: "hours",
            text: "Last 3 Hours",
            type: "datetime",
            includeToday: true
          },
          {
            no: 6,
            unit: "hours",
            text: "Last 6 Hours",
            type: "datetime",
            includeToday: true
          },
          {
            no: 12,
            unit: "hours",
            text: "Last 12 Hours",
            type: "datetime",
            includeToday: true
          },
          {
            no: 24,
            unit: "hours",
            text: "Last 1 Day",
            type: "datetime",
            includeToday: true
          },
          {
            no: 47,
            unit: "hours",
            text: "Last 2 Day",
            type: "datetime",
            includeToday: true
          },
          {
            no: 1,
            unit: "days",
            text: "Today",
            type: "datetime",
            includeToday: true
          },
        ].map((shortCut) => {
          return {
            text: shortCut.text,
            onClick: (picker) => {
              let end = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              );
              let start = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              );
                start = this.dhm__dateUtilsLib.subtract(end, {
                  [shortCut.unit]: shortCut.no
                });
                if (!shortCut.includeToday) {
                  end = this.dhm__dateUtilsLib.subtract(end, { days: 1 });
                }
                if (shortCut.text === "Today") {
                  start = moment(end).startOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
                  end = moment(end).endOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
                }
                picker.$emit("pick", [start, end]);
            }
          };
        })
      };
    },
  },
  data: function() {
    return {
      selectedTime: { num: 1, units: "days", id: timeIDs[6] },
      dateRangePicked: ["", ""],
      dateTimeRangePicked: ["", ""],
      loadingData: false
    };
  },
  mounted() {
    this.dateRangePicked = [
      dateUtils.formatDate(
        dateUtils.subtract(new Date(), { days: 2 }),
        "yyyy-MM-dd"
      ),
      dateUtils.formatDate(new Date(), "yyyy-MM-dd")
    ];
    // this.dateTimeRangePicked = [
    //   dateUtils.formatDate(
    //     dateUtils.subtract(new Date(), { minutes: 30 }),
    //     "yyyy-MM-dd HH:mm"
    //   ),
    //   dateUtils.formatDate(new Date(), "yyyy-MM-dd HH:mm")
    // ];
    this.uppm__setUrlParams(
        "from_date",
        this.adm__dateUtilsLib.formatDate(
          this.adm__dateUtilsLib.subtract(new Date(), { minutes: 30 }),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      // }
      // if (!this.uppm__urlParams.to_date) {
      this.uppm__setUrlParams(
        "to_date",
        this.adm__dateUtilsLib.formatDate(
          new Date(),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      // }
      this.$set(this.dateTimeRangePicked, 0, this.uppm__urlParams.from_date);
      this.$set(this.dateTimeRangePicked, 1, this.uppm__urlParams.to_date);
    // const userTimeZoneString = this.$store.getters["user/getUserTimeZoneString"];
    // console.log("dhm__toISOString", dateUtils.castUserUTCToBrowserTZDate(new Date(this.dateTimeRangePicked[0]), {
    //         timeZone: userTimeZoneString
    //       })
    //       .toISOString());
    this.acm__initParameterName("feed_logs");
    // this.$emit("timeChanged", this.dateRangePicked);
  },
  methods: {
    handleChangeTime(changedTimeValue) {
      console.log(changedTimeValue);
      this.$emit("timeChanged", changedTimeValue);
    },
    handleChangeDate(changedDateValue) {
      this.$emit("dateChanged", changedDateValue);
    },
    async handledownload() {
      this.loadingData = true;
      setTimeout(() => {
        this.acm__handleDownloadQuery(this.dateRangePicked);
        this.loadingData = false;
      }, 500)
    },
    handleDownloadData() {
      this.loadingData = true;
      setTimeout(() => {
        this.acm__handleDownloadQuery(this.dateTimeRangePicked)
        this.loadingData = false;
      }, 500)
    },
    acm__handleDateQueryChange(changedDateValue) {
      const from_date = changedDateValue[0];
      const to_date = changedDateValue[1];
      this.$emit(
        "changedDateQuery",
        { to_date, from_date },
        this.acm__parameterName
      );
    },
    handleDateRangeChange(newDates) {
      try {
        const noOfDays = newDates
          .map((x) => {
            return new Date(x);
          })
          .reduce((acc, curr) =>
            this.adm__dateUtilsLib.differenceInDays(curr, acc)
          );
        if (noOfDays > 1) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: "Date range should be within 2 Days"
              }
            ]
          };
        }
        this.dateTimeRangePicked = newDates;
        const from_date = this.dateTimeRangePicked[0];
        const to_date = this.dateTimeRangePicked[1];
        this.$emit(
        "changedDateQuery",
        { to_date, from_date },
        this.acm__parameterName
      );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
  }
};
</script>

<style lang="scss">
.chart-in-card {
  margin: 10px 0;
  .flex-end-date-range {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
.el-button--success.is-disabled, .el-button--success.is-disabled:hover {
    background-color: #0a2463;
    border-color: #0a2463;
  }
</style>
