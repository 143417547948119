<template>
  <div class="main-container">
    <div class="history-container" v-loading="loading || dropdownLoading">
      <el-card class="box-card">
        <settings-table
          ref="erDataTables"
          v-loading="loading"
          element-loading-background="white"
          :tableData="filterTableData"
          :tableProps="elTableProps"
          key="new_release"
          :shouldRefreshVisible="false"
          :total="total"
          :button="true"
          :columns="columnsObject"
          :columnselector_require="false"
          :selectedColumnHeadersSortable="true"
          placeHolderForSearch="Search_version_number"
          search-property="version_number"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
          @add-item-click="handleAddVersion('releaseForm')"
        >
          <template slot="add-buttton">Add Version</template>

          <template v-slot:version_number="{ row }">
            {{ `Version` + row.data.version_number }}
          </template>
          <template v-slot:date_of_release="{ row }">
            {{ getReleaseDate(row.data.created_at) }}
          </template>
          <template v-slot:actions="{ row }">
            <el-button-group>
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="handleEditVersion(row.data)"
              ></el-button>
              <el-button
                :disabled="true"
                size="mini"
                icon="el-icon-delete"
                @click="handleDeleteVersion(row.data)"
              ></el-button>
            </el-button-group>
          </template>
        </settings-table>
      </el-card>
    </div>
    <div class="dialog-container">
      <er-dialog
        width="70%"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        @open="handleOpenDialog"
        :title="getDialogTitle"
        class="action-on-update-version"
        custom-class="Update_Version"
        v-loading="loading"
        :before-close="handleClose"
      >
        <div class="form-container" v-show="dialogVisible">
          <el-form
            ref="releaseForm"
            :model="releaseForm"
            :rules="rules"
            label-width="120px"
          >
            <el-form-item label="Version" prop="version" class="version">
              <el-input
                :disabled="isUpdateVersion"
                placeholder="Please select the version number"
                v-model="releaseForm.version"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Status"
              prop="status"
              style="margin-bottom: 25px;"
            >
              <el-select
                v-model="releaseForm.status"
                placeholder="Please select the status"
              >
                <el-option
                  v-for="item in versionStatusContainer"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-for="(item, index) in releaseForm.featureItems"
              :key="'feature' + item._id"
              style="margin-left: 0 !important;"
            >
              <div
                class="feature-container"
                style="margin-left: -120px !important;"
              >
                <div class="input-container" style="width: 100%;">
                  <div class="feature-title-container" style="display: flex;">
                    <div
                      class="feature-title-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Feature' + ' ' + index"
                        :prop="'featureItems[' + index + '].title'"
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="text"
                          v-model="releaseForm.featureItems[index].title"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="feature-title-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="
                            handleAddFEB('featureItems', 'feature', index)
                          "
                          v-if="!index"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="
                            handleRemoveFEB('featureItems', 'feature', index)
                          "
                          v-else
                        >
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                  <div
                    class="feature-content-container"
                    v-for="(contentItem, contentIndex) in item.content"
                    :key="'feature_content' + contentItem._id + contentIndex"
                    style="display: flex;"
                  >
                    <div
                      class="feature-content-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Content' + ' ' + contentIndex"
                        :prop="
                          'featureItems[' +
                            index +
                            '].content[' +
                            contentIndex +
                            '].text'
                        "
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          placeholder=""
                          v-model="
                            releaseForm.featureItems[index].content[
                              contentIndex
                            ].text
                          "
                        >
                        </el-input>
                      </el-form-item>
                      <div
                        class="feature-content-container__input__image-container"
                        v-for="(contentImage,
                        contentImageIndex) in contentItem.image"
                        :key="
                          'feature_content_image' +
                            contentImage._id +
                            contentImageIndex
                        "
                      >
                        <div
                          class="feature-content-container__input__image-container__input"
                        >
                          <el-form-item
                            :label="'Image' + ' ' + contentImageIndex"
                            :prop="
                              'featureItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].image[' +
                                contentImageIndex +
                                '].link'
                            "
                            style="margin-left: 0 !important;"
                          >
                            <input
                              type="file"
                              autosize
                              accept="image/*"
                              :name="uploadFeatutreFieldName"
                              @change="
                                handleFEBImageAdded(
                                  'featureItems',
                                  $event,
                                  index,
                                  contentIndex,
                                  contentImageIndex
                                )
                              "
                              placeholder=""
                            />
                            <div
                              class="uploaded-image"
                              v-if="contentImage.link"
                              style="margin-top: 10px;"
                            >
                              <!-- {{contentImage.link = "https://pondlogs-dev-test.s3.ap-south-1.amazonaws.com/ui-versions/599d7e18-3cc1-4a1d-9ba4-28f0289aa067_2.svg"}} -->
                              <img
                                v-if="contentImage.link"
                                :src="contentImage.link"
                                alt=""
                                width="100px"
                                height="50px"
                              />
                            </div>
                          </el-form-item>
                        </div>
                        <div
                          class="feature-content-container__input_image-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBImage(
                                  'featureItems',
                                  'feature',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentImageIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBImage(
                                  'featureItems',
                                  'feature',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                      <div
                        class="feature-content-container__input__link-container"
                        v-for="(contentLink,
                        contentLinkIndex) in contentItem.link"
                        :key="
                          'feature_content_link' +
                            contentLink._id +
                            contentLinkIndex
                        "
                      >
                        <div
                          class="feature-content-container__input__link-container__input"
                        >
                          <el-form-item
                            :label="'Link' + ' ' + contentLinkIndex"
                            :prop="
                              'featureItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].link[' +
                                contentLinkIndex +
                                '].link'
                            "
                            :rules="{
                              validator: validateLink,
                              message: 'Link is not valid',
                              trigger: 'blur'
                            }"
                            style="margin-left: 0 !important;"
                          >
                            <el-input
                              placeholder=""
                              v-model="
                                releaseForm.featureItems[index].content[
                                  contentIndex
                                ].link[contentLinkIndex].link
                              "
                            >
                            </el-input>
                          </el-form-item>
                        </div>
                        <div
                          class="feature-content-container__input_link-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBLink(
                                  'featureItems',
                                  'feature',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentLinkIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBLink(
                                  'featureItems',
                                  'feature',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                    </div>
                    <div class="feature-content-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="
                            handleAddFEBContent(
                              'featureItems',
                              'feature',
                              index,
                              contentIndex
                            )
                          "
                          v-if="!contentIndex"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(
                              'featureItems',
                              'feature',
                              index,
                              contentIndex
                            )
                          "
                          v-else
                        >
                        </el-button>
                        <!-- <el-button
                          type="button"
                          icon="el-icon-picture-outline"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-link"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-video-camera"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button> -->
                      </el-button-group>
                    </div>
                  </div>
                  <!-- <el-form-item
                    :label="'File' + ' ' + index"
                    :prop="'featureItems.' + index + '.file'"
                    style="margin-left: 0 !important;"
                  >
                    <el-input
                      type="link"
                      autosize
                      placeholder=""
                      v-model="releaseForm.featureItems[index].file"
                    >
                    </el-input>
                  </el-form-item> -->
                </div>
                <!-- <div class="icon-container"></div> -->
              </div>
              <div class="custom-border-feature"></div>
            </el-form-item>
            <el-form-item
              v-for="(item, index) in releaseForm.enhancementItems"
              :key="'Enhancement' + item._id"
              style="margin-left: 0 !important;"
            >
              <div
                class="enhancement-container"
                style="margin-left: -120px !important;"
              >
                <div class="input-container" style="width: 100%;">
                  <div
                    class="enhancement-title-container"
                    style="display: flex;"
                  >
                    <div
                      class="enhancement-title-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Enhancement' + ' ' + index"
                        :prop="'enhancementItems[' + index + '].title'"
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="text"
                          v-model="releaseForm.enhancementItems[index].title"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="enhancement-title-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="
                            handleAddFEB(
                              'enhancementItems',
                              'enhancement',
                              index
                            )
                          "
                          v-if="!index"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="
                            handleRemoveFEB(
                              'enhancementItems',
                              'enhancement',
                              index
                            )
                          "
                          v-else
                        >
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                  <div
                    class="enhancement-content-container"
                    v-for="(contentItem, contentIndex) in item.content"
                    :key="
                      'Enhancement_content' + contentItem._id + contentIndex
                    "
                    style="display: flex;"
                  >
                    <div
                      class="enhancement-content-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Content' + ' ' + contentIndex"
                        :prop="
                          'enhancementItems[' +
                            index +
                            '].content[' +
                            contentIndex +
                            '].text'
                        "
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          placeholder=""
                          v-model="
                            releaseForm.enhancementItems[index].content[
                              contentIndex
                            ].text
                          "
                        >
                        </el-input>
                      </el-form-item>
                      <div
                        class="enhancement-content-container__input__image-container"
                        v-for="(contentImage,
                        contentImageIndex) in contentItem.image"
                        :key="
                          'Enhancement_content_image' +
                            contentImage._id +
                            contentImageIndex
                        "
                      >
                        <div
                          class="enhancement-content-container__input__image-container__input"
                        >
                          <el-form-item
                            :label="'Image' + ' ' + contentImageIndex"
                            :prop="
                              'enhancementItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].image[' +
                                contentImageIndex +
                                '].link'
                            "
                            style="margin-left: 0 !important;"
                          >
                            <input
                              type="file"
                              autosize
                              accept="image/*"
                              :name="uploadFeatutreFieldName"
                              @change="
                                handleFEBImageAdded(
                                  'enhancementItems',
                                  $event,
                                  index,
                                  contentIndex,
                                  contentImageIndex
                                )
                              "
                              placeholder=""
                            />
                            <div
                              class="uploaded-image"
                              v-if="contentImage.link"
                              style="margin-top: 10px;"
                            >
                              <img
                                :src="contentImage.link"
                                alt=""
                                width="100px"
                                height="50px"
                              />
                            </div>
                          </el-form-item>
                        </div>
                        <div
                          class="enhancement-content-container__input_image-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBImage(
                                  'enhancementItems',
                                  'enhancement',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentImageIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBImage(
                                  'enhancementItems',
                                  'enhancement',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                      <div
                        class="enhancement-content-container__input__link-container"
                        v-for="(contentLink,
                        contentLinkIndex) in contentItem.link"
                        :key="
                          'Enhancement_content_link' +
                            contentLink._id +
                            contentLinkIndex
                        "
                      >
                        <div
                          class="enhancement-content-container__input__link-container__input"
                        >
                          <el-form-item
                            :label="'Link' + ' ' + contentLinkIndex"
                            :prop="
                              'enhancementItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].link[' +
                                contentLinkIndex +
                                '].link'
                            "
                            :rules="{
                              validator: validateLink,
                              message: 'Link is not valid',
                              trigger: 'blur'
                            }"
                            style="margin-left: 0 !important;"
                          >
                            <el-input
                              placeholder=""
                              v-model="
                                releaseForm.enhancementItems[index].content[
                                  contentIndex
                                ].link[contentLinkIndex].link
                              "
                            >
                            </el-input>
                          </el-form-item>
                        </div>
                        <div
                          class="enhancement-content-container__input_link-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBLink(
                                  'enhancementItems',
                                  'enhancement',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentLinkIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBLink(
                                  'enhancementItems',
                                  'enhancement',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                    </div>
                    <div class="enhancement-content-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="
                            handleAddFEBContent(
                              'enhancementItems',
                              'enhancement',
                              index,
                              contentIndex
                            )
                          "
                          v-if="!contentIndex"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(
                              'enhancementItems',
                              'enhancement',
                              index,
                              contentIndex
                            )
                          "
                          v-else
                        >
                        </el-button>
                        <!-- <el-button
                          type="button"
                          icon="el-icon-picture-outline"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-link"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-video-camera"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button> -->
                      </el-button-group>
                    </div>
                  </div>
                  <!-- <el-form-item
                    :label="'File' + ' ' + index"
                    :prop="'featureItems.' + index + '.file'"
                    style="margin-left: 0 !important;"
                  >
                    <el-input
                      type="link"
                      autosize
                      placeholder=""
                      v-model="releaseForm.featureItems[index].file"
                    >
                    </el-input>
                  </el-form-item> -->
                </div>
                <!-- <div class="icon-container"></div> -->
              </div>
              <div class="custom-border-enhancement"></div>
            </el-form-item>
            <el-form-item
              v-for="(item, index) in releaseForm.bugItems"
              :key="'Bug' + item._id"
              style="margin-left: 0 !important;"
            >
              <div
                class="bug-container"
                style="margin-left: -120px !important;"
              >
                <div class="input-container" style="width: 100%;">
                  <div class="bug-title-container" style="display: flex;">
                    <div
                      class="bug-title-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Bug' + ' ' + index"
                        :prop="'bugItems[' + index + '].title'"
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="text"
                          v-model="releaseForm.bugItems[index].title"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="bug-title-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="handleAddFEB('bugItems', 'bug', index)"
                          v-if="!index"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="handleRemoveFEB('bugItems', 'bug', index)"
                          v-else
                        >
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                  <div
                    class="bug-content-container"
                    v-for="(contentItem, contentIndex) in item.content"
                    :key="'Bug_content' + contentItem._id + contentIndex"
                    style="display: flex;"
                  >
                    <div
                      class="bug-content-container__input"
                      style="width: 100%;"
                    >
                      <el-form-item
                        :label="'Content' + ' ' + contentIndex"
                        :prop="
                          'bugItems[' +
                            index +
                            '].content[' +
                            contentIndex +
                            '].text'
                        "
                        style="margin-left: 0 !important;"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          placeholder=""
                          v-model="
                            releaseForm.bugItems[index].content[contentIndex]
                              .text
                          "
                        >
                        </el-input>
                      </el-form-item>
                      <div
                        class="bug-content-container__input__image-container"
                        v-for="(contentImage,
                        contentImageIndex) in contentItem.image"
                        :key="
                          'Bug_content_image' +
                            contentImage._id +
                            contentImageIndex
                        "
                      >
                        <div
                          class="bug-content-container__input__image-container__input"
                        >
                          <el-form-item
                            :label="'Image' + ' ' + contentImageIndex"
                            :prop="
                              'bugItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].image[' +
                                contentImageIndex +
                                '].link'
                            "
                            style="margin-left: 0 !important;"
                          >
                            <input
                              type="file"
                              autosize
                              accept="image/*"
                              :name="uploadFeatutreFieldName"
                              @change="
                                handleFEBImageAdded(
                                  'bugItems',
                                  $event,
                                  index,
                                  contentIndex,
                                  contentImageIndex
                                )
                              "
                              placeholder=""
                            />
                            <div
                              class="uploaded-image"
                              v-if="contentImage.link"
                              style="margin-top: 10px;"
                            >
                              <img
                                :src="contentImage.link"
                                alt=""
                                width="100px"
                                height="50px"
                              />
                            </div>
                          </el-form-item>
                        </div>
                        <div
                          class="bug-content-container__input_image-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBImage(
                                  'bugItems',
                                  'bug',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentImageIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBImage(
                                  'bugItems',
                                  'bug',
                                  contentImageIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                      <div
                        class="bug-content-container__input__link-container"
                        v-for="(contentLink,
                        contentLinkIndex) in contentItem.link"
                        :key="
                          'Bug_content_link' +
                            contentLink._id +
                            contentLinkIndex
                        "
                      >
                        <div
                          class="bug-content-container__input__link-container__input"
                        >
                          <el-form-item
                            :label="'Link' + ' ' + contentLinkIndex"
                            :prop="
                              'bugItems[' +
                                index +
                                '].content[' +
                                contentIndex +
                                '].link[' +
                                contentLinkIndex +
                                '].link'
                            "
                            :rules="{
                              validator: validateLink,
                              message: 'Link is not valid',
                              trigger: 'blur'
                            }"
                            style="margin-left: 0 !important;"
                          >
                            <el-input
                              placeholder=""
                              v-model="
                                releaseForm.bugItems[index].content[
                                  contentIndex
                                ].link[contentLinkIndex].link
                              "
                            >
                            </el-input>
                          </el-form-item>
                        </div>
                        <div
                          class="bug-content-container__input_link-container_icon"
                        >
                          <!-- <el-button-group>
                            <el-button
                              type="button"
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                handleAddFEBLink(
                                  'bugItems',
                                  'bug',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-if="!contentLinkIndex"
                            >
                            </el-button>
                            <el-button
                              type="button"
                              icon="el-icon-delete"
                              size="mini"
                              @click="
                                handleRemoveFEBLink(
                                  'bugItems',
                                  'bug',
                                  contentLinkIndex,
                                  contentIndex,
                                  index
                                )
                              "
                              v-else
                            >
                            </el-button>
                          </el-button-group> -->
                        </div>
                      </div>
                    </div>
                    <div class="bug-content-container__icon">
                      <el-button-group>
                        <el-button
                          type="button"
                          size="mini"
                          icon="el-icon-plus"
                          @click="
                            handleAddFEBContent(
                              'bugItems',
                              'bug',
                              index,
                              contentIndex
                            )
                          "
                          v-if="!contentIndex"
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-delete"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(
                              'bugItems',
                              'bug',
                              index,
                              contentIndex
                            )
                          "
                          v-else
                        >
                        </el-button>
                        <!-- <el-button
                          type="button"
                          icon="el-icon-picture-outline"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-link"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button>
                        <el-button
                          type="button"
                          icon="el-icon-video-camera"
                          size="mini"
                          @click="
                            handleRemoveFEBContent(index, contentIndex)
                          "
                        >
                        </el-button> -->
                      </el-button-group>
                    </div>
                  </div>
                  <!-- <el-form-item
                    :label="'File' + ' ' + index"
                    :prop="'bugItems.' + index + '.file'"
                    style="margin-left: 0 !important;"
                  >
                    <el-input
                      type="link"
                      autosize
                      placeholder=""
                      v-model="releaseForm.bugItems[index].file"
                    >
                    </el-input>
                  </el-form-item> -->
                </div>
                <!-- <div class="icon-container"></div> -->
              </div>
              <div class="custom-border-bug"></div>
            </el-form-item>
            <div v-if="mainDataInvalid" class="error-text">
              *Please enter atleast one feature or enhancement or bug.
            </div>
            <!-- </div> -->
          </el-form>
        </div>
        <template slot="footer">
          <er-button
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            :loading="saveLoading"
            @click="loadPreview('releaseForm')"
          ></er-button>
          <er-button
            btnType="cancel"
            :showLabel="true"
            :loading="saveLoading"
            @click="handleCloseDialog"
          ></er-button>
        </template>
      </er-dialog>
    </div>
    <div class="preview-release-container">
      <er-dialog
        width="50%"
        v-if="previewDialogVisible"
        :visible.sync="previewDialogVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        @open="handlePreviewOpenDialog"
        :title="getPreviewDialogTitle"
        class="action-on-update-version"
        custom-class="Preview_Version"
        v-loading="loading"
        :before-close="handlePreviewCancel"
      >
        <!-- <template slot="title">

        </template> -->
        <div class="preview-release-container" v-show="previewDialogVisible">
          <preview-release :previewData="previewData"></preview-release>
        </div>
        <template slot="footer">
          <er-button btnType="submit" @click="onSubmit('releaseForm')">{{
            "Confirm"
          }}</er-button>
          <er-button
            btnType="cancel"
            :showLabel="true"
            :loading="saveLoading"
            @click="handleClosePreviewDialog"
          ></er-button>
        </template>
      </er-dialog>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapActions, mapGetters } from "vuex";
import settingsTable from "@/components/base/settingsTable";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
// import { VueEditor } from 'vue2-editor';
import PreviewRelease from "./previewRelease";
import eventBus from "@/components/shared/eventBus";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    datesHandlerMixin,
    filtersMixin,
    userPreferenceMixin
  ],
  components: {
    settingsTable,
    PreviewRelease
  },
  data() {
    return {
      total: 0,
      tableData: [],
      searchString: "",
      content: "<p>Some initial content</p>",
      uploadFeatutreFieldName: "photos",
      versionStatusContainer: [
        {
          label: "Draft",
          value: "draft"
        },
        {
          label: "Released",
          value: "released"
        },
        {
          label: "Reverted",
          value: "reverted"
        }
        // {
        //   label: "Discarded",
        //   value: "discarded"
        // }
      ],
      releaseForm: {
        version: "2.1.43",
        status: "",
        featureItems: [
          {
            _id: "feature",
            title: "",
            content: [
              {
                _id: "feature_content",
                text: "",
                image: [{ _id: "feature_content_image", link: "" }],
                link: [{ _id: "feature_content_link", link: "" }],
                video: [{ _id: "feature_content_video", link: "" }]
              }
            ]
          }
        ],
        enhancementItems: [
          {
            _id: "enhancement",
            title: "",
            content: [
              {
                _id: "enhancement_content",
                text: "",
                image: [{ _id: "enhancement_content_image", link: "" }],
                link: [{ _id: "enhancement_content_link", link: "" }],
                video: [{ _id: "enhancement_content_video", link: "" }]
              }
            ]
          }
        ],
        bugItems: [
          {
            _id: "bug",
            title: "",
            content: [
              {
                _id: "bug_content",
                text: "",
                image: [{ _id: "bug_content_image", link: "" }],
                link: [{ _id: "bug_content_link", link: "" }],
                video: [{ _id: "bug_content_video", link: "" }]
              }
            ]
          }
        ]
      },
      updateReleaseForm: {
        version: "2.1.43",
        status: "",
        featureItems: [
          {
            _id: "feature",
            title: "",
            content: [
              {
                _id: "feature_content",
                text: "",
                image: [{ _id: "feature_content_image", link: "" }],
                link: [{ _id: "feature_content_link", link: "" }],
                video: [{ _id: "feature_content_video", link: "" }]
              }
            ]
          }
        ],
        enhancementItems: [
          {
            _id: "enhancement",
            title: "",
            content: [
              {
                _id: "enhancement_content",
                text: "",
                image: [{ _id: "enhancement_content_image", link: "" }],
                link: [{ _id: "enhancement_content_link", link: "" }],
                video: [{ _id: "enhancement_content_video", link: "" }]
              }
            ]
          }
        ],
        bugItems: [
          {
            _id: "bug",
            title: "",
            content: [
              {
                _id: "bug_content",
                text: "",
                image: [{ _id: "bug_content_image", link: "" }],
                link: [{ _id: "bug_content_link", link: "" }],
                video: [{ _id: "bug_content_video", link: "" }]
              }
            ]
          }
        ]
      },
      rules: {
        version: [
          {
            required: true,
            message: "Please enter version number",
            trigger: "blur"
          }
        ],
        featureItems: [
          { required: true, message: "Please enter feature", trigger: "blur" }
        ],
        bugItems: [
          { required: true, message: "Please enter bug", trigger: "blur" }
        ],
        status: [
          {
            required: true,
            message: "Please select the status",
            trigger: "change"
          }
        ],
        enhancement: [
          {
            required: true,
            message: "Please enter enhancement",
            trigger: "blur"
          }
        ]
      },
      clonedForm: {},
      dialogVisible: false,
      previewDialogVisible: false,
      saveLoading: false,
      isAddVersion: false,
      isUpdateVersion: false,
      releaseList: [],
      loading: false,
      queryParams: {
        type: "WEB"
      },
      enhancementContent: [],
      bugContent: [],
      featureContent: [],
      mainDataInvalid: false,
      isFeatureImageAdding: false,
      previewData: {},
      dropdownLoading: false
    };
  },
  async mounted() {
    this.clonedForm = cloneDeep(this.releaseForm);
    eventBus.$on("viewChangedForLogs", (args) => {
      this.dropdownLoading = true;
      this.queryParams.type = args?.selectedView;
      try {
        console.log("getting called from new release");
        this.handleLoadData();
      } catch (err) {
        console.log("Something went wrong while fetching the release");
      } finally {
        this.dropdownLoading = false;
      }
    });
  },
  created() {},
  destroyed() {
    eventBus.$off("viewChangedForLogs");
  },
  computed: {
    ...mapGetters("superadmin/newRelease", {
      getReleaseList: "getReleaseList",
      getImageUrl: "getImageUrl"
    }),
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.version_number.includes(this.searchString);
      });
    },
    validateLink() {
      return (rule, value, callback) => {
        if (value === "") {
          callback();
        } else if (value !== "") {
          const expression = /[-a-zA-Z0-9@:%_+.~#?&=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\\+.~#?&=]*)?/gi;
          const regex = new RegExp(expression);
          if (value.match(regex)?.length > 0) {
            callback();
          } else {
            callback(new Error("Please enter valid link"));
          }
        }
      };
    },
    getReleaseDate() {
      const IstTimeZone = "Asia/Kolkata";
      return (data) => {
        if (data && this.adm__dateUtilsLib.isValid(new Date(data))) {
          return this.adm__formatByTZOnUTCWithLocale(
            data,
            "dd-MMM-yy",
            IstTimeZone
          );
        } else {
          return "--";
        }
      };
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      if (window.innerWidth < 2560 && window.innerWidth > 1537) {
        return "calc(100vh - 210px)";
      } else if (window.innerWidth > 1535 && window.innerWidth < 1920) {
        return "calc(100vh - 200px)";
      } else {
        return "calc(100vh - 189px)";
      }
    },
    getDialogTitle() {
      return this.isAddVersion
        ? this.$t("add-version")
        : this.$t("update-version");
    },
    getPreviewDialogTitle() {
      return "PondLogs Release " + this.previewData.version_number;
    },
    columnsObject: function() {
      return [
        {
          prop: "version_number",
          label: "Version",
          required: true,
          sortable: true
        },
        {
          prop: "created_at",
          label: "Date of Release",
          required: true,
          sortable: true
        },
        {
          prop: "status",
          label: "Status",
          required: true,
          sortable: true
        },
        {
          prop: "actions",
          label: "Actions",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin/newRelease", {
      fetchAllReleaseList: "fetchAllReleaseList",
      createReleaseVersion: "createReleaseVersion",
      updateReleaseVersion: "updateReleaseVersion",
      deleteReleaseVersion: "deleteReleaseVersion",
      uploadImage: "uploadImage",
      savePictureToTargetUrl: "savePictureToTargetUrl"
    }),
    featureValidator: function(rule, value, callback) {
      const val = rule.field;
      console.log(this.releaseForm[val]);
      const flag = this.releaseForm + "." + rule.field;
      if (flag) {
        callback(new Error("Please enter the name of the activity"));
      } else {
        callback();
      }
    },
    handleFEBImageAdded: async function(
      mainKey,
      event,
      index,
      contentIndex,
      contentImageIndex
    ) {
      if (!event.target.files.length) return;
      var files = event.target.files || event.dataTransfer.files;
      // let generateImageUrl = "";
      var formData = new FormData();
      console.log(" files[0]", files[0]);
      formData.append("image", files[0]);
      if (files[0].size > 512000) {
        this.$notify({
          type: "error",
          message: "Failed to upload image",
          title: "Failed"
        });
        event.target.value = "";
        return;
      }
      try {
        // this.loading = true;
        this.isFeatureImageAdding = true;
        const response = await this.uploadImage(files[0]);
        const { full_file_path, target_url } = response?.data;
        this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
        // setTimeout(() => {
        //   this.releaseForm[mainKey][index].content[contentIndex].image[
        //   contentImageIndex
        // ].link = "https://pondlogs-dev-test.s3.ap-south-1.amazonaws.com/ui-versions/fd959733-f6a4-457a-8243-abdfc526ec70_a5a6051a-b553-4875-bf74-315ace271a84_chart (2).png";
        // console.log(full_file_path);
        setTimeout(() => {
          this.releaseForm[mainKey][index].content[contentIndex].image[
            contentImageIndex
          ].link = full_file_path;
        }, 1000);
        const payload = {
          target_url,
          file: files[0]
        };
        await this.savePictureToTargetUrl(payload);
        // this.loading = false;
        console.log("response", response.data.full_file_path, payload);
      } catch (err) {
        console.log("error on uploading the image");
      } finally {
        // to do
        this.isFeatureImageAdding = false;
      }
    },
    comparePartials: function(a, b) {
      if (a === b) {
        return 0;
      }
      const splitA = a.split(".");
      const splitB = b.split(".");
      const length = Math.max(splitA.length, splitB.length);
      for (let i = 0; i < length; i++) {
        if (
          parseInt(splitA[i]) > parseInt(splitB[i]) ||
          (splitA[i] === splitB[i] && isNaN(splitB[i + 1]))
        ) {
          return 1;
        }
        if (
          parseInt(splitA[i]) < parseInt(splitB[i]) ||
          (splitA[i] === splitB[i] && isNaN(splitA[i + 1]))
        ) {
          return -1;
        }
      }
    },
    async handleLoadData(query = {}) {
      console.log("query", query);
      try {
        this.loading = true;
        this.queryParams = { ...this.queryParams, ...query };
        const response = await this.fetchAllReleaseList({
          infinite_scroll: false,
          params: this.queryParams
        });
        this.tableData = response?.data?.data;
        this.tableData.sort((a, b) =>
          this.comparePartials(a.version_number, b.version_number)
        );
        this.total = response.data.total;
        this.searchString = query?.version_number;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    resetForm(formName = "releaseForm") {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    handleAddVersion() {
      this.dialogVisible = true;
      this.isAddVersion = true;
      this.isUpdateVersion = false;
      this.resetInputFormItem();
      this.resetForm();
    },
    resetInputFormItem() {
      const {
        version,
        enhancementItems,
        featureItems,
        bugItems,
        status
      } = this.$lodash.cloneDeep(this.updateReleaseForm);
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm.version = version;
      this.releaseForm.enhancementItems = enhancementItems;
      this.releaseForm.featureItems = featureItems;
      this.releaseForm.bugItems = bugItems;
      this.releaseForm.status = status;
    },
    getContentText() {},
    formatContentDataForEditForm(data) {
      let finalData = [];
      let contentFormattedData = [];
      if (data && data.length > 0) {
        finalData = data.reduce((acc, currentObj, index) => {
          const { title, content } = currentObj;
          const filterIndexArr = [];
          content.filter((item, indexOne) => {
            if (item.type === "text") {
              filterIndexArr.push(indexOne);
              return true;
            }
          });
          if (filterIndexArr.length === 1) {
            const newObj = {};
            content.forEach((item, indexTwo) => {
              newObj._id = indexTwo;
              switch (item.type) {
                case "text":
                  newObj.text = item.value;
                  break;
                case "image":
                  newObj.image = [
                    { _id: `image-${indexTwo}`, link: item.link }
                  ];
                  break;
                case "link":
                  newObj.link = [{ _id: `link-${indexTwo}`, link: item.link }];
                  break;
                case "video":
                  newObj.video = [
                    { _id: `video-${indexTwo}`, link: item.link }
                  ];
                  break;
              }
            });
            contentFormattedData = [newObj];
          } else {
            contentFormattedData = filterIndexArr.reduce(
              (childAcc, rootItem, rootIndex) => {
                const clonedContent = [...content];
                const nextItemFlag =
                  filterIndexArr[rootIndex + 1] !== undefined
                    ? filterIndexArr[rootIndex + 1] - filterIndexArr[rootIndex]
                    : clonedContent.length - filterIndexArr[rootIndex];
                const semiData = clonedContent
                  .splice(rootItem, nextItemFlag)
                  .reduce((newObj, item, indexTwo) => {
                    switch (item.type) {
                      case "text":
                        newObj.text = item.value;
                        break;
                      case "image":
                        newObj.image = [
                          { _id: `image-${rootIndex}`, link: item.link }
                        ];
                        break;
                      case "link":
                        newObj.link = [
                          { _id: `link-${rootIndex}`, link: item.link }
                        ];
                        break;
                      case "video":
                        newObj.video = [
                          { _id: `video-${rootIndex}`, link: item.link }
                        ];
                        break;
                    }
                    return newObj;
                  }, {});
                childAcc.push({ _id: rootIndex, ...semiData });
                return childAcc;
              },
              []
            );
          }
          acc.push({
            _id: `release_${index}`,
            title: title,
            content: contentFormattedData
          });
          return acc;
        }, []);
      }
      console.log("finalData", finalData);
      return finalData;
    },
    handleEditVersion(data) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      const {
        version_number,
        enhancements,
        features,
        bugs,
        status,
        _id
      } = data;
      this.releaseForm.version = version_number;
      this.releaseForm.status = status;
      this.releaseForm._id = _id;
      this.dialogVisible = true;
      this.releaseForm.enhancementItems = this.formatContentDataForEditForm(
        enhancements
      );
      this.releaseForm.featureItems = this.formatContentDataForEditForm(
        features
      );
      this.releaseForm.bugItems = this.formatContentDataForEditForm(bugs);
      console.log(" this.releaseForm", this.releaseForm);
      this.isUpdateVersion = true;
      this.isAddVersion = false;
    },
    async handleDeleteVersion(data) {
      const { _id } = data;
      try {
        await this.$confirm(
          "Your are going to delete the version, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        this.loading = true;
        const paylaod = { _id, type: this.queryParams.type };
        await this.deleteReleaseVersion({ paylaod });
        this.$notify({
          type: "success",
          message: "Version deleted successfully",
          title: "Success"
        });
        this.handleLoadData();
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to delete version",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    handleOpenDialog(event) {
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    handlePreviewOpenDialog(event) {
      this.$nextTick(() => {
        // to do
      });
    },
    handleCloseDialog() {
      this.resetForm();

      this.resetInputFormItem();
      this.dialogVisible = false;
      this.previewDialogVisible = false;
    },
    handleClosePreviewDialog() {
      this.dialogVisible = true;
      this.previewDialogVisible = false;
    },
    handleClose() {
      this.resetForm("releaseForm");
      this.resetInputFormItem();
      this.dialogVisible = false;
    },
    handlePreviewCancel() {
      this.previewDialogVisible = false;
    },
    isDataValid(form) {
      const isDataValid = [
        "enhancementItems",
        "featureItems",
        "bugItems"
      ].find((item) =>
        form[item].find((item) =>
          ["title"].every((val) => item[val]?.length > 0)
        )
      );
      if (isDataValid && isDataValid.length > 0) {
        this.mainDataInvalid = false;
        return true;
      }
      this.mainDataInvalid = true;
      return false;
    },
    formatFormDataForPayload(formObj) {
      return ["featureItems", "enhancementItems", "bugItems"].map((item, i) => {
        return formObj[item].filter((subItem) =>
          ["title"].every((val) => {
            delete subItem._id;
            return subItem[val]?.length > 0;
          })
        );
      });
    },
    formatContentData(data) {
      // data [{title, '', content: [{image: '', link: '', text: '', _id: ''}]}]
      const contentFinalData = data.reduce((acc, currentObj) => {
        if (currentObj.text !== "") {
          acc.push({
            type: "text",
            value: currentObj.text
          });
        }
        if (currentObj?.link?.length > 0) {
          const linkFormatted = currentObj.link.reduce(
            (accOne, currentObjOne) => {
              accOne.push({
                type: "link",
                link: currentObjOne.link
              });
              return accOne;
              // }
            },
            []
          );
          linkFormatted.map((item, i) => {
            if (item.link === "") {
              linkFormatted.splice(i, 1);
            }
          });
          acc.push(...linkFormatted);
        }
        if (currentObj?.image?.length > 0) {
          const linkFormatted = currentObj.image.reduce(
            (accOne, currentObjOne) => {
              accOne.push({
                type: "image",
                link: currentObjOne.link
              });
              return accOne;
            },
            []
          );
          linkFormatted.map((item, i) => {
            if (item.link === "") {
              linkFormatted.splice(i, 1);
            }
          });
          acc.push(...linkFormatted);
        }
        return acc;
      }, []);
      return contentFinalData;
    },
    formatFinalData(data) {
      if (data.length > 0) {
        return data.map((item, i) => {
          const finalData = {
            title: item.title,
            content: this.formatContentData(item.content)
          };
          return finalData;
        });
      }
    },
    async onSubmit(formName = "releaseForm") {
      const payloadData = this.formatFormDataForPayload(this.releaseForm);
      this.loading = true;
      const { status, version, _id } = this.releaseForm;
      if (this.isAddVersion) {
        try {
          const payload = {
            status: status.toUpperCase(),
            version_number: version,
            features: this.formatFinalData(payloadData[0]),
            enhancements: this.formatFinalData(payloadData[1]),
            bugs: this.formatFinalData(payloadData[2]),
            type: this.queryParams.type
          };
          const response = await this.createReleaseVersion(payload);
          if (response?.data.success) {
            this.$notify({
              type: "success",
              message: "Version created successfully",
              title: "Success"
            });
          } else {
            this.$notify({
              type: "error",
              message: "Failed to create version",
              title: "Failed"
            });
          }
        } catch (err) {
          if (err.response && err.response.data.message) {
            this.$notify({
              type: "error",
              message: err.response.data.message,
              title: "Failed"
            });
          }
        } finally {
          this.loading = false;
        }
      } else if (this.isUpdateVersion) {
        try {
          const payload = {
            status: status.toUpperCase(),
            version_number: version,
            features: this.formatFinalData(payloadData[0]),
            enhancements: this.formatFinalData(payloadData[1]),
            bugs: this.formatFinalData(payloadData[2]),
            _id,
            type: this.queryParams.type
          };
          console.log("payload", payload);
          const response = await this.updateReleaseVersion(payload);
          if (response.data.success) {
            this.$notify({
              type: "success",
              message: "Version updated successfully",
              title: "Success"
            });
          } else {
            this.$notify({
              type: "error",
              message: "Failed to update version",
              title: "Failed"
            });
          }
        } catch (err) {
          if (err.response && err.response.data.message) {
            this.$notify({
              type: "error",
              message: err.response.data.message,
              title: "Failed"
            });
          }
        } finally {
          this.loading = false;
        }
        // call update version api
      }
      this.resetForm();
      this.resetInputFormItem();
      this.handleLoadData();
      this.previewDialogVisible = false;
    },
    loadPreview(formName = "releaseForm") {
      this.$refs[formName].validate(async (valid) => {
        if (valid && this.isDataValid(this.releaseForm)) {
          const { status, version } = this.releaseForm;
          const arrayData = this.formatFormDataForPayload(this.releaseForm);
          this.previewData = {
            status: status.toUpperCase(),
            version_number: version,
            features: arrayData[0],
            enhancements: arrayData[1],
            bugs: arrayData[2]
          };
          this.dialogVisible = false;
          this.previewDialogVisible = true;
        }
      });
    },
    handleAddFEB(mainKey, label, index) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey].push({
        _id: `${label}_${index + 1}_${this.releaseForm[mainKey].length}`,
        title: "",
        content: [
          {
            _id: `${label}_content_default${index + 1}`,
            text: "",
            image: [
              { _id: `${label}_content_image_default${index + 1}`, link: "" }
            ],
            link: [
              {
                _id: `${label}_content_link_default_default${index + 1}${index +
                  1}`,
                link: ""
              }
            ],
            video: [
              { _id: `${label}_content_video_default${index + 1}`, link: "" }
            ]
          }
        ]
      });
    },
    handleRemoveFEB(mainKey, label, index) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey].splice(index, 1);
    },
    handleAddFEBContent(mainKey, label, index, key) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][index].content.push({
        _id: `${label}_content-${key + 1}_${
          this.releaseForm[mainKey][index].content.length
        }`,
        text: "",
        image: [
          {
            _id: `${label}_content_image-${key + 1}_${
              this.releaseForm[mainKey][index].content.length
            }`,
            link: ""
          }
        ],
        link: [
          {
            _id: `${label}_content_link-${key + 1}_${
              this.releaseForm[mainKey][index].content.length
            }`,
            link: ""
          }
        ],
        video: [
          {
            _id: `${label}_content_video-${key + 1}_${
              this.releaseForm[mainKey][index].content.length
            }`,
            link: ""
          }
        ]
      });
    },
    handleRemoveFEBContent(mainKey, label, index, key) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][index].content.splice(key, 1);
    },
    handleAddFEBImage(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].image.push({
        _id: `${label}_content_image_${index + 1}_${
          this.releaseForm[mainKey][key2].content[key1].image.length
        }`,
        link: ""
      });
    },
    handleRemoveFEBImage(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].image.splice(index, 1);
    },
    handleAddFEBLink(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].link.push({
        _id: `${label}_content_link_${index + 1}_${
          this.releaseForm[mainKey][key2].content[key1].link.length
        }`,
        link: ""
      });
    },
    handleRemoveFEBLink(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].link.splice(index, 1);
    },
    handleAddFEBVideo(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].video.push({
        _id: `${label}_content_link_${index + 1}_${
          this.releaseForm[mainKey][key2].content[key1].video.length
        }`,
        link: ""
      });
    },
    handleRemoveFEBVideo(mainKey, label, index, key1, key2) {
      this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
      this.releaseForm[mainKey][key2].content[key1].video.splice(index, 1);
    }
    // handleAddEnhancement(formName) {
    //   this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
    //   this[formName].enhancementItems.push({
    //     _id: `enhancement_` + this[formName].enhancementItems.length + 1,
    //     title: '',
    //     content: ''
    //   });
    // },
    // handleRemoveFEB(formName) {
    //   this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
    //   this[formName].enhancementItems.pop();
    // },
    // handleAddBug(formName) {
    //   this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
    //   this[formName].bugItems.push({
    //     _id: `bug_${this[formName].bugItems.length + 1}`,
    //     title: '',
    //     content: ''
    //   });
    // },
    // handleRemoveFEB(formName) {
    //   this.releaseForm = this.$lodash.cloneDeep(this.releaseForm);
    //   this[formName].bugItems.pop();
    // }
  }
};
</script>

<style lang="scss">
.version {
  margin-bottom: 20px !important;
}
.main-container {
  .history-container {
    .padding-10-0 {
      padding: 8px 20px !important;
    }
    .settings-table {
      .pagination-wrap {
        margin-bottom: 11px !important;
      }
      .el-select {
        width: 100%;
      }
    }
  }
}
.input-container {
  flex-basis: 100%;
  width: 100%;
  .el-input {
    margin-bottom: 10px;
  }
}
.Update_Version {
  border-radius: 10px;
  .el-form-item {
    margin-bottom: 12px;
  }
  .feature-container {
    .input-container {
      .feature-title-container {
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
        }
        &__icon {
        }
      }
      .feature-content-container {
        // align-items: center;
        margin-bottom: 12px;
        justify-content: flex-start;
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
          width: 74% !important;
          align-items: center;
          line-height: 24px !important;
          &__image-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__link-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__video-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
        }
        &__icon {
          // margin-top: -8px;
          line-height: 24px !important;
        }
      }
    }
  }
  .enhancement-container {
    .input-container {
      .enhancement-title-container {
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
        }
        &__icon {
        }
      }
      .enhancement-content-container {
        // align-items: center;
        margin-bottom: 12px;
        justify-content: flex-start;
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
          width: 74% !important;
          align-items: center;
          line-height: 24px !important;
          &__image-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__link-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__video-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
        }
        &__icon {
          // margin-top: -8px;
          line-height: 24px !important;
        }
      }
    }
  }
  .bug-container {
    .input-container {
      .bug-title-container {
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
        }
        &__icon {
        }
      }
      .bug-content-container {
        // align-items: center;
        margin-bottom: 12px;
        justify-content: flex-start;
        .el-form-item__content {
          line-height: 24px !important;
        }
        &__input {
          width: 74% !important;
          align-items: center;
          line-height: 24px !important;
          &__image-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__link-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
          &__video-container {
            display: flex;
            &__input {
              width: 100%;
            }
            &__icon {
            }
          }
        }
        &__icon {
          // margin-top: -8px;
          line-height: 24px !important;
        }
      }
    }
  }
  .custom-border-feature {
    height: 5px;
    background: #1ed688;
  }
  .custom-border-enhancement {
    height: 5px;
    background: #0e65e5;
  }
  .custom-border-bug {
    height: 5px;
    background: #fbb03b;
  }
}
.error-text {
  margin-left: 118px;
  color: black;
}
</style>
